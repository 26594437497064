import React, { useEffect } from 'react'
import Grid from "@material-ui/core/Grid";
import { useStyle } from "./Style";
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import Tooltip from '@mui/material/Tooltip';
import find from 'lodash/find';
import { getPartner } from '../../../../../lib/access.es6';
import { formatDateLocale } from '../../../../../lib/utils.es6';

const ClaimDetails = (props) => {

  useEffect(() => {
    props.getPreauthDetails({
      guid: props.claim.preauthorization_guid
    });

  }, []);

  const preauthDeatils = props.preauthDeatils

  const diagnosis_name = preauthDeatils ? preauthDeatils.hospital_details.diagnosis_name : 'N/A'
  const discharge_date = preauthDeatils ? preauthDeatils.hospital_details.hospital_discharch_date : 'N/A'
  const admission_date = preauthDeatils ? preauthDeatils.hospital_details.hospital_admission_date : 'N/A'
  const totaldays = preauthDeatils ? preauthDeatils.hospital_details.days_in_hospital : 'N/A'
  const dependant_fullname = preauthDeatils ? preauthDeatils.hospital_details.dependant_fullname : "N/A"
  const hospitalName = preauthDeatils ? preauthDeatils.hospital_details.hospital : "N/A"
  const preauth_number = preauthDeatils ? preauthDeatils.preauth_number : "N/A"
  const created_at = preauthDeatils ? preauthDeatils.created_at : "N/A"
  const web_agent_name = preauthDeatils ? preauthDeatils.web_agent_name : "N/A"
  const claim_number = preauthDeatils ? preauthDeatils.claim_number : "N/A"
  const hospitalBenefit = preauthDeatils ? find(preauthDeatils.benefits, { "benefit_type": "DAILY HOSPITAL BENEFIT" }) : null
  const surgeryBenefit = preauthDeatils ? find(preauthDeatils.benefits, { "benefit_type": "ADDITIONAL SURGERY BENEFIT" }) : null

  const hospitalAmount = hospitalBenefit ? hospitalBenefit.authorized_amount_in_cents / 100 : 0
  const surgeryAmount = surgeryBenefit ? surgeryBenefit.authorized_amount_in_cents / 100 : 0

  const totalBenefit = hospitalAmount + surgeryAmount

  //Reimbursement details
  const claimant_full_name = props.claim.claim_details.additional_details
    ? props.claim.claim_details.additional_details.claimant_full_name
    : 'N/A';

  const claimant_phone_number = props.claim.claim_details.additional_details
    ? props.claim.claim_details.additional_details.claimant_phone_number
    : 'N/A';

  const claimant_id_number = props.claim.claim_details.additional_details
    ? props.claim.claim_details.additional_details.claimant_id_number
    : 'N/A';

  const claimant_relationship_to_patient = props.claim.claim_details.additional_details
    ? props.claim.claim_details.additional_details.claimant_relationship_to_patient
    : 'N/A';

  const patientAge = props.claim.claim_details.additional_details
    ? props.claim.claim_details.additional_details.age
    : 'N/A';

  const hospital_name = props.claim.claim_details.additional_details
    ? props.claim.claim_details.additional_details.hospital_name
    : 'N/A';

  const admission_date_reimbursement = props.claim.claim_details.additional_details
    ? props.claim.claim_details.additional_details.admission_date
    : 'N/A';

  const discharge_date_reimbursement = props.claim.claim_details.additional_details
    ? props.claim.claim_details.additional_details.discharge_date
    : 'N/A';

  const diagnosis_name_reimbursement = props.claim.claim_details.additional_details
    ? props.claim.claim_details.additional_details.diagnosis_name
    : 'N/A';

  const hospital_in_panel = props.claim.claim_details.additional_details
    ? props.claim.claim_details.additional_details.hospital_in_panel
    : 'N/A';


  //death details 
  const claimant_full_name_death = props.claim.claim_details.additional_details
    ? props.claim.claim_details.additional_details.claimant_full_name
    : 'N/A';

  const claimant_id_number_death = props.claim.claim_details.additional_details
    ? props.claim.claim_details.additional_details.claimant_id_number
    : 'N/A';

  const claimant_phone_number_death = props.claim.claim_details.additional_details
    ? props.claim.claim_details.additional_details.claimant_phone_number
    : 'N/A';

  const claimant_relationship_to_patient_death = props.claim.claim_details.additional_details
    ? props.claim.claim_details.additional_details.claimant_relationship_to_patient
    : 'N/A';


  const agent_branch = props.claim.claim_details.additional_details
  ? props.claim.claim_details.additional_details.agent_branch
  : 'N/A';

  const agent_email = props.claim.claim_details.additional_details
  ? props.claim.claim_details.additional_details.agent_email
  : 'N/A';

  const currencyCode = getPartner().country.currency_code



  const classes = useStyle();

  if (!props.claim.claim_details) {
    return <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} className={classes.claimwrapper}>
        <Grid container spacing={2} className={classes.claimdetailswrapper}>
          <Grid item xs={12} sm={12} md={12} className={classes.ClaimDetailscontent}>
            <p style={{ textAlign: 'center' }}>No details found</p>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  }




  return (
    <>
      {
        props.isReimbusermentClaim(props.claim) ? (<>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} className={classes.claimwrapper}>
              <Grid container spacing={2} className={classes.claimdetailswrapper}>
                <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                  <h4>Policy Number</h4>
                  <p>{props.claim.claim_details.policy_number || "N/A"}</p>
                </Grid>
                <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent} >
                  <h4>Policy Holder Full Names</h4>
                  <p>{props.claim.claim_details.policy_holder_fullname || "N/A"}</p>
                </Grid>
                <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                  <h4>Policy Holder Phone Number</h4>
                  <p>{props.claim.claim_details.policy_holder_msisdn || "N/A"}</p>
                </Grid>
                <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontentlast}>
                  <h4>Agent Name</h4>
                  <p>{props.claim.web_agent_name || "N/A"}</p>
                </Grid>
                <div className={classes.borderbottom}></div>
                <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                  <h4>Claimant Full Name</h4>
                  <p>{claimant_full_name}</p>
                </Grid>
                <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                  <h4>Claimant Phone Number</h4>
                  <p>{claimant_phone_number}</p>
                </Grid>
                <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                  <h4>Claimant ID Number</h4>
                  <p>{claimant_id_number}</p>
                </Grid>
                <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontentlast}>
                  <h4>claimant Relationship To Patient</h4>
                  <p>{claimant_relationship_to_patient}</p>
                </Grid>

                <div className={classes.borderbottom}></div>

                <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                  <h4> Patient Full Name</h4>
                  <p>{props.claim.claim_details.dependant_fullname || "N/A"}</p>
                </Grid>
                <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                  <h4>Patient D.O.B</h4>
                  <p>{formatDateLocale(props.claim.claim_details.dependant_dob) || "N/A"}</p>
                </Grid>
                <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                  <h4>Patient National ID</h4>
                  <p>{props.claim.claim_details.dependant_national_id || "N/A"}</p>
                </Grid>
                <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                  <h4>Patient Age</h4>
                  <p>{patientAge}</p>
                </Grid>
                {/* <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontentlast}>
                  <h4>Gender</h4>
                  <p>{props.claim.claim_details.gender || "N/A"}</p>
                </Grid> */}
                <div className={classes.borderbottom}></div>
                <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                  <h4>Relationship To Policyholder</h4>
                  <p>{props.claim.claim_details.relationship_to_policyholder || "N/A"}</p>
                </Grid>
                <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                  <h4>Hospital</h4>
                  <p>{hospital_name}</p>
                </Grid>
                <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                  <h4>Admission Date</h4>
                  <p>{formatDateLocale(admission_date_reimbursement)}</p>
                </Grid>
                <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                  <h4>Discharge Date</h4>
                  <p>{formatDateLocale(discharge_date_reimbursement)}</p>
                </Grid>
                <div className={classes.borderbottom}></div>
                <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                  <h4>Diagnosis</h4>
                  <p>{diagnosis_name_reimbursement}</p>
                </Grid>
                <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                  <h4>Hospital In Panel</h4>
                  <p>{hospital_in_panel === true ? "Yes" : "No"}</p>
                </Grid>
                <div className={classes.borderbottom}></div>
              </Grid>

              <Grid container spacing={2} className={classes.notesheader}>
                <Grid item xs={12} md={12} sm={12}>
                  <ul>
                    <li><h4>Claim Notes</h4></li>
                    <li >
                      <Tooltip title="Add claim Notes">
                        <AddCircleOutlineRoundedIcon style={{ cursor: "pointer" }} onClick={() => props.toggleClaimNotesDialog()} />
                      </Tooltip>
                    </li>
                  </ul>

                </Grid>
              </Grid>
              <Grid container spacing={2} className={classes.noteswrapper}>
                <Grid item xs={12} md={4} sm={4}>
                  <div className={classes.notesbox}>
                    <p>{props.claim.notes || "No claim notes available"}</p>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>) : props.isDeathClaim(props.claim) ? (<>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} className={classes.claimwrapper}>
              <Grid container spacing={2} className={classes.claimdetailswrapper}>
                <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                  <h4>Deceased Names</h4>
                  <p>{props.claim.claim_details.dependant_fullname || "N/A"}</p>
                </Grid>
                <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                  <h4>Relation to Principal</h4>
                  <p>{props.claim.claim_details.relationship_to_policyholder || "N/A"}</p>
                </Grid>
                <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                  <h4>Gender</h4>
                  <p>{props.claim.claim_details.gender || "N/A"}</p>
                </Grid>
                <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontentlast}>
                  <h4>Deceased Date of Birth</h4>
                  <p>{formatDateLocale(props.claim.claim_details.dependant_dob) || "N/A"}</p>
                </Grid>
                <div className={classes.borderbottom}></div>
                <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                  <h4> Date of Death</h4>
                  <p>{formatDateLocale(props.claim.event_date) || "N/A"}</p>
                </Grid>
                <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                  <h4> Deceased Age</h4>
                  <p>{props.claim.claim_details.additional_details.age || "N/A"}</p>
                </Grid>
                <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                  <h4>Cause of Death </h4>
                  <p>{props.claim.claim_details.additional_details.cause_of_death || "N/A"}</p>
                </Grid>
                <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontentlast}>
                  <h4>Deceased Suffered any Chronic Condition? </h4>
                  <p>{props.claim.claim_details.additional_details.suffered_chronic_condition === true ? "Yes" : "No"}</p>
                </Grid>
                <div className={classes.borderbottom}></div>
                <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                  <h4>Hospitalized? </h4>
                  <p>{props.claim.claim_details.additional_details.hospitalized === true ? "Yes" : "No"}</p>
                </Grid>
                <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                  <h4>Benefit Amount </h4>
                  <p>{`${currencyCode} ${(props.claim.benefits[0].max_benefit_amount_in_cents / 100).toLocaleString()}` || "N/A"}</p>
                </Grid>
                <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                  <h4>Claimant Full Name </h4>
                  <p>{claimant_full_name_death}</p>
                </Grid>

                <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontentlast}>
                  <h4>Claimant ID Number </h4>
                  <p>{claimant_id_number_death}</p>
                </Grid>
                <div className={classes.borderbottom}></div>
                <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                  <h4>Claimant Phone Number </h4>
                  <p>{claimant_phone_number_death}</p>
                </Grid>
                <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                  <h4>Claimant Relationship To Patient </h4>
                  <p>{claimant_relationship_to_patient_death}</p>
                </Grid>
                <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                  <h4>Agent Branch </h4>
                  <p>{agent_branch || "N/A"}</p>
                </Grid>
                <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontentlast}>
                  <h4>Agent Email </h4>
                  <p>{agent_email || "N/A"}</p>
                </Grid>
                <div className={classes.borderbottom}></div>

              </Grid>
            </Grid>

            <Grid container spacing={2} className={classes.notesheader}>
              <Grid item xs={12} md={12} sm={12}>
                <ul>
                  <li><h4>Claim Notes</h4></li>
                  <li >
                    <Tooltip title="Add claim Notes">
                      <AddCircleOutlineRoundedIcon style={{ cursor: "pointer" }} onClick={() => props.toggleClaimNotesDialog()} />
                    </Tooltip>
                  </li>
                </ul>

              </Grid>
            </Grid>
            <Grid container spacing={2} className={classes.noteswrapper}>
              <Grid item xs={12} md={4} sm={4}>
                <div className={classes.notesbox}>
                  <p>{props.claim.notes || "No claim notes available"}</p>
                </div>
              </Grid>
            </Grid>
          </Grid>


        </>) : (<>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} className={classes.claimwrapper}>
              <Grid container spacing={2} className={classes.claimdetailswrapper}>
                <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                  <h4>LOU Number</h4>
                  <p>{preauth_number ? preauth_number : "N/A"}</p>
                </Grid>
                <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent} >
                  <h4>Created At</h4>
                  <p>{created_at ? formatDateLocale(created_at) : "N/A"}</p>
                </Grid>
                <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                  <h4>Created By </h4>
                  <p>{web_agent_name ? web_agent_name : "N/A"}</p>
                </Grid>
                <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontentlast}>
                  <h4>Updated By</h4>
                  <p>{web_agent_name ? web_agent_name : "N/A"}</p>
                </Grid>
                <div className={classes.borderbottom}></div>
                <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                  <h4>Facility</h4>
                  <p>{hospitalName ? hospitalName : "N/A"}</p>
                </Grid>
                <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                  <h4>Name of Patient</h4>
                  <p>{dependant_fullname ? dependant_fullname : "N/A"}</p>
                </Grid>
                <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                  <h4> Membership Number</h4>
                  <p>{claim_number ? claim_number : "N/A"}</p>
                </Grid>
                <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontentlast}>
                  <h4>Admission Date</h4>
                  <p>{admission_date ? formatDateLocale(admission_date) : "N/A"}</p>
                </Grid>
                <div className={classes.borderbottom}></div>
                <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                  <h4>Discharge Date</h4>
                  <p>{discharge_date ? formatDateLocale(discharge_date) : "N/A"}</p>
                </Grid>
                <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                  <h4>Total Days</h4>
                  <p>{totaldays ? totaldays : "N/A"}</p>
                </Grid>
                <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                  <h4>Diagnosis</h4>
                  <p>{diagnosis_name}</p>
                </Grid>
                <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontentlast}>
                  <h4>Scheme Name</h4>
                  <p>{"Kinga ya Mkulima"}</p>
                </Grid>
                <div className={classes.borderbottom}></div>
                <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                  <h4>Total Hospital Benefit</h4>
                  <p>{hospitalAmount ? `${currencyCode}  ${(hospitalAmount).toLocaleString()}` : "N/A"}</p>
                </Grid>
                <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                  <h4>Surgery Benefit</h4>
                  <p>{surgeryAmount ? `${currencyCode} ${(surgeryAmount).toLocaleString()}` : "N/A"}</p>
                </Grid>
                <Grid item xs={12} sm={3} md={3} className={classes.ClaimDetailscontent}>
                  <h4>Total Benefit</h4>
                  <p>{totalBenefit ? `${currencyCode} ${(totalBenefit).toLocaleString()}` : "N/A"}</p>
                </Grid>
                <div className={classes.borderbottom}></div>
              </Grid>
              <Grid container spacing={2} className={classes.notesheader}>
                <Grid item xs={12} md={12} sm={12}>
                  <ul>
                    <li><h4>Claim Notes</h4></li>
                    <li >
                      <Tooltip title="Add claim Notes">
                        <AddCircleOutlineRoundedIcon style={{ cursor: "pointer" }} onClick={() => props.toggleClaimNotesDialog()} />
                      </Tooltip>
                    </li>
                  </ul>

                </Grid>
              </Grid>
              <Grid container spacing={2} className={classes.noteswrapper}>
                <Grid item xs={12} md={4} sm={4}>
                  <div className={classes.notesbox}>
                    <p>{props.claim.notes || "No claim notes available"}</p>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

        </>)
      }


    </>
  )
}

export default ClaimDetails