import React, { useState } from 'react'
import { getPartner, getPartnerGuid } from '../../../../../lib/access.es6';
import AlertDialog from '../../../../../components/AlertDialog/index.es6';
import { CircularProgress } from '@material-ui/core';
import { Button } from '@mui/material';
import { find } from 'lodash';
import VehicleDetails from './VehicleDetails/VehicleDetails';
import Declarations from './Declarations/Declarations';
import SelectPaymentPlan from './SelectPaymentPlan';
import NavButtons from './NavButtons';
//import AddBenefits from './AddBenefits';
import ViewMembers from './ViewMembers';
import Adddependant from './AddDependant';
import { COMPONENT_TYPES } from '../../helpers';


const CreateQuote = (props) => {

  const [currentComponent, setCurrentComponent] = useState(COMPONENT_TYPES.ADD_DEPENDANT);
  const [selectedPlan, setPlan] = useState(null);
  const [parentsPlan, setParentsPlan] = useState(null);

  const [currentRelationShip, setCurrentRelationShip] = useState(null)
  const [memberRelationshipType, setMemberRelationshipType] = useState(null)

  // const [openAddBenefitsDialog, setOpenAddBenefitsDialog] = useState(null)

  //const [suspendDuplicateIdCheck, setSuspendDuplicateIdCheck] = useState(false)


  const [togglequoteanddependant, settogglequoteanddependant] = useState(true)
  const [dependants, setDependants] = useState([])
  const [depAlert, setDepAlert] = useState(false);

  //const [additionalBenefits, setAdditionalBenefits] = useState({})



  const [vehicleRegistrationNumber, setVehicleRegistrationNumber] = useState('')
  const [chasisNumber, setChasisNumber] = useState('')
  const [engineNumber, setEngineNumber] = useState('')
  const [bodyDescription, setBodyDescription] = useState('')
  const [fuelType, setFuelType] = useState('')
  const [sittingCapabilities, setSittingCapabilities] = useState('')
  const [yearsWithDrivingLicence, setYearsWithDrivingLicence] = useState('')
  const [color, setColor] = useState('')

  const [declinedProposal, setDeclinedProposal] = useState(false);
  const [increasedPremium, setIncreasedPremium] = useState(false);
  const [policyCancelled, setPolicyCancelled] = useState(false)
  const [defectiveCondition, setDefectiveCondition] = useState(false)
  const [drivingOffense, setDrivingOffense] = useState(false)

  const [premiumCardinality, setPremiumCardinality] = useState(1)
  const [premiumGranularity, setPremiumGranularity] = useState('')
  const [paymentPlanPrice, setPaymentPlanPrice] = useState('')
  const [paymentPlanDescription, setPaymentPlanDescription] = useState('')

  console.log("premiumGranularity", premiumGranularity

  )

  const handleAdddependant = () => {
    settogglequoteanddependant(!togglequoteanddependant)
  }


  const removeDependant = (uuid) => {
    const item = find(dependants, { uuid: uuid })
    if (item.relationship === 'Principal') {
      setDependants([])
    } else {
      const updatedDependants = dependants.filter(item => item.uuid !== uuid);
      setDependants(updatedDependants);

    }
  };


  const handleCreateQuote = () => {
    // attachBenefits()
    const payload = {
      "product_guid": props.productDetails.guid,
      "product_name": props.productType,
      "product_code": props.productDetails.code,
      "customer_guid": props.principal.guid,
      "partner": getPartnerGuid(),
      "lapse_period": 12,
      "lapse_period_granularity": "month",
      "lapse_period_cardinality": 1,
      "premium_granularity": premiumGranularity === "Annually" ? "year" : "month",
      "premium_cardinality": parseFloat(premiumCardinality),
      "policy_schedule_entries": [
        ...dependants
      ],
      additional_details: {
        "vehicle_registration_number": vehicleRegistrationNumber,
        "chasis_number": chasisNumber,
        "engine_number": engineNumber,
        "body_description": bodyDescription,
        "fuel_type": fuelType,
        "sitting_capabilities": sittingCapabilities,
        "years_with_driving_licence": yearsWithDrivingLicence,
        "color": color,
        "declarations": {
          "declined_proposal": declinedProposal,
          "increased_premium": increasedPremium,
          "policy_cancelled": policyCancelled,
          "defective_condition": defectiveCondition,
          "driving_offense": drivingOffense
        },
        "payment_plan": premiumGranularity,
        "payment_plan_price": paymentPlanPrice,
        "payment_plan_description": paymentPlanDescription

      }
    }
    console.log("quotepayload", payload)
    props.createQuote(payload)


  }

  const handleQuoteCreatedSuccessfully = () => {
    props.resetAddQuoteSuccessAlert();

  }

  const handleQuoteCreateError = () => {
    props.resetAddQuoteErrorAlert();

  }

  const handlenextStep = () => {
    props.handleNext();
  }





  const currencyCode = getPartner().country.currency_code


  return (

    <>
      {/* {currentComponent === COMPONENT_TYPES.SELECT_COVER_TYPE &&
        <SelectCoverType
          coverType={coverType}
          setCoverType={setCoverType}
          customerGuid={props.principal.guid}
        />
      } */}
      {/* 
      {currentComponent === COMPONENT_TYPES.SELECT_PLAN &&
        <SelectPlan
          coverType={coverType}
          hasParentsPlan={hasParentsPlan}
          plans={props.products}
          selectedPlan={selectedPlan}
          setParentsPlan={setParentsPlan}
          setPlan={setPlan}
          parentsPlan={parentsPlan}
        />
      } */}
      {/* 
      {currentComponent === COMPONENT_TYPES.SELECT_RELATIONSHIP &&
        <SelectRelationship
          hasParentsPlan={hasParentsPlan}
          setHasParentsPlan={setHasParentsPlan}
          selectedPlan={selectedPlan}
          parentsPlan={parentsPlan}
          currentRelationShip={currentRelationShip}
          setCurrentRelationShip={setCurrentRelationShip}
          dependants={dependants}
          setCurrentComponent={setCurrentComponent}
          COMPONENT_TYPES={COMPONENT_TYPES}
          viewMembers={() => setCurrentComponent(COMPONENT_TYPES.VIEW_MEMBERS)}
          coverType={coverType}
          lastSelectedExtendedRelationship={lastSelectedExtendedRelationship}
          setLastSelectedExtendedRelationship={setLastSelectedExtendedRelationship}
          memberRelationshipType={memberRelationshipType}
        />
      } */}

      {currentComponent === COMPONENT_TYPES.ADD_DEPENDANT &&
        <Adddependant
          productType={props.productType}
          productDetails={props.productDetails}
          dependant={props.dependant}
          handleCreateQuote={handleCreateQuote}
          handleAdddependant={handleAdddependant}
          createQuote={props.createQuote}
          setDependants={setDependants}
          dependants={dependants}
          createDependant={props.createDependant}
          viewMembers={() => setCurrentComponent(COMPONENT_TYPES.VIEW_MEMBERS)}

          currencyCode={currencyCode}
          removeDependant={removeDependant}
          depAlert={depAlert}
          setDepAlert={setDepAlert}
          principal={props.principal}
          autoFillPrincipal={props.autoFillPrincipal}
          toggleAutoFillPrincipal={props.toggleAutoFillPrincipal}
          selectedPlan={selectedPlan}
          parentsPlan={parentsPlan}
          currentRelationShip={currentRelationShip}

          memberRelationshipType={memberRelationshipType}
        // suspendDuplicateIdCheck={suspendDuplicateIdCheck}

        />
      }

      {currentComponent === COMPONENT_TYPES.VIEW_MEMBERS &&
        <ViewMembers
          currencyCode={currencyCode}
          removeDependant={removeDependant}
          dependants={dependants}
          addAnotherMember={(relationshipType) => {
            setMemberRelationshipType(relationshipType)
            setCurrentComponent(COMPONENT_TYPES.SELECT_RELATIONSHIP)
            setCurrentRelationShip(null)
          }}
          //setOpenAddBenefitsDialog={setOpenAddBenefitsDialog}
          //additionalBenefits={additionalBenefits}
          selectedPlan={selectedPlan}
          parentsPlan={parentsPlan}
          handleCreateQuote={handleCreateQuote}
        />
      }

      {
        currentComponent === COMPONENT_TYPES.VEHICLE_DETAILS && <VehicleDetails



          vehicleRegistrationNumber={vehicleRegistrationNumber}
          setVehicleRegistrationNumber={setVehicleRegistrationNumber}
          chasisNumber={chasisNumber}
          setChasisNumber={setChasisNumber}
          engineNumber={engineNumber}
          setEngineNumber={setEngineNumber}
          bodyDescription={bodyDescription}
          setBodyDescription={setBodyDescription}
          fuelType={fuelType}
          setFuelType={setFuelType}
          sittingCapabilities={sittingCapabilities}
          setSittingCapabilities={setSittingCapabilities}
          yearsWithDrivingLicence={yearsWithDrivingLicence}
          setYearsWithDrivingLicence={setYearsWithDrivingLicence}
          color={color}
          setColor={setColor}



        />
      }



      {
        currentComponent === COMPONENT_TYPES.DECLARATIONS && <Declarations


          declinedProposal={declinedProposal}
          increasedPremium={increasedPremium}
          policyCancelled={policyCancelled}
          defectiveCondition={defectiveCondition}
          drivingOffense={drivingOffense}

          setDeclinedProposal={setDeclinedProposal}
          setIncreasedPremium={setIncreasedPremium}
          setPolicyCancelled={setPolicyCancelled}
          setDefectiveCondition={setDefectiveCondition}
          setDrivingOffense={setDrivingOffense}
        />
      }

      {
        currentComponent === COMPONENT_TYPES.SELECT_PAYMENT_PLAN && <SelectPaymentPlan
          productDetails={props.productDetails}
          dependants={dependants}
          handleCreateQuote={handleCreateQuote}
          premiumCardinality={premiumCardinality}
          setPremiumCardinality={setPremiumCardinality}
          setPremiumGranularity={setPremiumGranularity}

          setPaymentPlanPrice={setPaymentPlanPrice}
          setPaymentPlanDescription={setPaymentPlanDescription}

        />
      }

      {/* 
      <AddBenefits
        currencyCode={currencyCode}
        setOpenAddBenefitsDialog={setOpenAddBenefitsDialog}
        openAddBenefitsDialog={openAddBenefitsDialog}
        selectedPlan={selectedPlan}
        parentsPlan={parentsPlan}
        dependants={dependants}
        setDependants={setDependants}
        additionalBenefits={additionalBenefits}
        setAdditionalBenefits={setAdditionalBenefits}
      /> */}



      <NavButtons
        currentComponent={currentComponent}
        setCurrentComponent={setCurrentComponent}
        selectedPlan={selectedPlan}
        setPlan={setPlan}
        parentsPlan={parentsPlan}
        setParentsPlan={setParentsPlan}
      />


      <AlertDialog
        custom
        show={props.showAddQuoteProgressAlert}
        size="sm"
        style={{ marginTop: '0', top: '30vh' }}
        confirmBtnText={'ok'}
        showCancel={false}
        showConfirm={false}
        cancelBtnText={'cancel'}
        showTitle={false}
        confirmBtnCssClass
      >
        <CircularProgress />
        <h2> Creating Quote...</h2>
      </AlertDialog>

      <AlertDialog
        success
        show={props.showAddQuoteSuccessAlert}
        size="sm"
        title={'Quote  created'}
        style={{ marginTop: '0', top: '30vh' }}
        onConfirm={() => { handleQuoteCreatedSuccessfully() }}
        confirmBtnText={'ok'}
        showCancel={false}
        showConfirm={false}
        cancelBtnText={'cancel'}
        confirmBtnCssClass
      >
        <div >
          <Button variant="contained" onClick={() => { handlenextStep() }} style={{ background: 'green', }}>View Quote Details</Button>
        </div>
      </AlertDialog>

      <AlertDialog
        show={props.showAddQuoteErrorAlert}
        danger
        title={'Error creating Quote'}
        onConfirm={() => handleQuoteCreateError()}
        confirmBtnText={'Try again'}
        confirmBtnCssClass
        showCancel={false}
        style={{ marginTop: '0', top: '30vh' }}
      >
        {
          props.createQuoteError ? props.createQuoteError.message : ""
        }
      </AlertDialog>

      <hr />

      {/* {selectedPlan &&
        <>
          {dependants.length > 0 &&
            <div className={classes.createquote}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={handleCreateQuote}
              >
                Create Quote
              </Button>
            </div>
          }
        </>
      } */}

    </>
  )
}

export default CreateQuote


