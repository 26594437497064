import React from "react";
import { connect } from "react-redux";
import HomePageCta from './HomePageCta'
import { hashHistory } from 'react-router';
import LocalizedStrings from 'react-localization';
import { localisedText } from "../../../lib/localisation.es6";
import RegisterCustomer from "../CustomerStatus/Containers/RegisterCustomer/Components/RegisterCustomer";
import { registerDcpCustomerRequest, resetDcpRegistration } from "../CustomerStatus/Containers/RegisterCustomer/redux/actions";
import { reloadPage } from "../../../lib/utils.es6";

class HomePage extends React.Component {
  constructor(props) {
    const localizedStrings = new LocalizedStrings(localisedText);
    super(props);
    this.state = {
      openRegisterCustomerDialog: false,
      labels: localizedStrings,
    }
  }

  componentWillMount() {
  }


  toggleRegisterCustomerDialog() {
    this.setState({ openRegisterCustomerDialog: !this.state.openRegisterCustomerDialog })
  }
  registerCustomer(customerData) {
    this.props.dispatch(registerDcpCustomerRequest(customerData));
  }
  resetRegistrationSuccess() {
    this.props.dispatch(resetDcpRegistration());
		hashHistory.push('admin/customer_status/' + this.props.customerData.currentCustomer.guid);
		this.toggleRegisterCustomerDialog()
		reloadPage();
  }
  resetRegistrationError() {
		this.props.dispatch(resetDcpRegistration())

  }

  render() {
    return (
      <>
        <HomePageCta
          open={this.toggleRegisterCustomerDialog.bind(this)}
          labels={this.state.labels}
        />
          <RegisterCustomer
            openRegisterCustomerDialog={this.state.openRegisterCustomerDialog}
            toggleRegisterCustomerDialog={this.toggleRegisterCustomerDialog.bind(
              this
            )}
            registerCustomer={this.registerCustomer.bind(this)}
            registrationData={this.props.DCPRegistrationData}
            resetRegistrationSuccess={this.resetRegistrationSuccess.bind(this)}
            resetRegistrationError={this.resetRegistrationError.bind(this)}
            labels={this.state.labels}
            error={this.props.DCPRegistrationData.errors}
            showRegisterCustomerErrorAlert={
              !!this.props.DCPRegistrationData.errors
            }
          />
        
      </>
    );


  }
}

export default connect((state) => ({
  customerData: state.currentCustomer,
  DCPRegistrationData: state.DCPRegistrationData,
  registrationData: state.customerRegistration,
  userData: state.userAdmin,
}))(HomePage);