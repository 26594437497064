export const productData = {
  "created_at": "2025-02-12T07:28:27.911Z",
  "guid": "596890f9-88b4-4fad-8ea3-fbf3de208d0a",
  "name": "Family Life Cover",
  "code": "Family_Life_Cover",
  "type": "Paid",
  "cover_type": "Family",
  "minimum_age": 0,
  "maximum_age": 90,
  "waiting_period_days": 30,
  "lapse_period_days": 30,
  "cool_off_period_hours": 10,
  "debit_grace_days": 10,
  "partner_guid": "omu_posbank",
  "eligibility_threshold": 0,
  "active": true,
  "tag": "",
  "notes": "",
  "membership_configs": {
    "guid": "1f051adc-126b-4d29-86cc-a4f40fe84455",
    "product_guid": "596890f9-88b4-4fad-8ea3-fbf3de208d0a",
    "content": {
      "packages": [
        {
          "package_name": "Package 1",
          "premium_amount": 310000,
          "package_id": "il55zx2xc",
          "currency": "UGX",
          "premium_frequency": "Annually"
        },
        {
          "package_name": "Package 2",
          "premium_amount": 130000,
          "package_id": "xmar5a32k",
          "currency": "UGX",
          "premium_frequency": "Annually"
        },
        {
          "package_name": "Package 3",
          "premium_amount": 65000,
          "package_id": "5xylg7q8s",
          "currency": "UGX",
          "premium_frequency": "Annually"
        }
      ],
      "benefits": [
        {
          "max_members": 1,
          "member_type": "main_member",
          "package_1_amount": 7000000,
          "package_2_amount": 10000000,
          "package_3_amount": 5000000,
          "benefit_id": "8n9sw0q6u",
          "currency": "UGX"
        },
        {
          "max_members": 1,
          "member_type": "spouse",
          "package_1_amount": 5000000,
          "package_2_amount": 5000000,
          "package_3_amount": 2500000,
          "benefit_id": "6pm1wooo9",
          "currency": "UGX"
        },
        {
          "max_members": 4,
          "member_type": "children",
          "package_1_amount": 3000000,
          "package_2_amount": 1000000,
          "package_3_amount": 500000,
          "benefit_id": "z6dvhx7vf",
          "currency": "UGX"
        },
        {
          "max_members": 4,
          "member_type": "parents",
          "package_1_amount": 3000000,
          "package_2_amount": 1000000,
          "package_3_amount": 500000,
          "benefit_id": "fqb7h4nb5",
          "currency": "UGX"
        }
      ],
      "member_validations": {
        "config": {
          "date_format": "YYYY-MM-DD",
          "allowed_relationships": ["Parent", "Parent-in-law"],
          "identification_types": [
            { "value": "national_id", "label": "National ID" },
            { "value": "passport", "label": "Passport" }
          ]
        },
        "main_member": {
          "age_limits": {
            "min": 15,
            "max": 75,
            "error_message": "Main member must be between 15 and 75 years old",
            "calculation_basis": "years" // Specify how age is calculated
          },
          "fields": {
            // "title": {
            //   "type": "dropdown",
            //   "placeholder": "Select title",
            //   "required": true,
            //   "options": [
            //     { "value": "Mr", "label": "Mr" },
            //     { "value": "Mrs", "label": "Mrs" },
            //     { "value": "Miss", "label": "Miss" },
            //     { "value": "Dr", "label": "Dr" }
            //   ],
            //   "error_messages": {
            //     "required": "Title is required"
            //   }
            // },
            "first_name": {
              "type": "textField",
              "input_type": "text",
              "placeholder": "Enter first name",
              "required": true,
              "min_length": 2,
              "max_length": 50,
              "pattern": "^[a-zA-Z ]*$",
              "error_messages": {
                "required": "First name is required",
                "min_length": "First name must be at least 2 characters",
                "max_length": "First name cannot exceed 50 characters",
                "pattern": "First name can only contain letters and spaces"
              }
            },
            "last_name": {
              "type": "textField",
              "input_type": "text",
              "placeholder": "Enter last name",
              "required": true,
              "min_length": 2,
              "max_length": 50,
              "pattern": "^[a-zA-Z ]*$",
              "error_messages": {
                "required": "Last name is required",
                "min_length": "Last name must be at least 2 characters",
                "max_length": "Last name cannot exceed 50 characters",
                "pattern": "Last name can only contain letters and spaces"
              }
            },
            "date_of_birth": {
              "type": "datePicker",
              "placeholder": "Select date of birth",
              "required": true,
              "error_messages": {
                "required": "Date of birth is required",
                "invalid": "Please enter a valid date"
              }
            },
            "identification_type": {
              "type": "dropdown",
              "placeholder": "Choose identification type",
              "required": true,
              "options": [
                { "value": "national_id", "label": "National ID" },
                { "value": "passport", "label": "Passport" }
              ],
              "error_messages": {
                "required": "Identification type is required"
              }
            },
            "identification_number": {
              "type": "textField",
              "input_type": "text",
              "placeholder": "Enter identification number",
              "required": true,
              "validation_rules": {
                "national_id": {
                  "pattern": "^CM[0-9]{8}[A-Z]{4}$",
                  "error": "Please enter a valid National ID (Format: CM12345678ABCD)"
                },
                "passport": {
                  "pattern": "^[A-Z]{1}[0-9]{7}$",
                  "error": "Please enter a valid Passport number (Format: B1234567)"
                }
              },
              "error_messages": {
                "required": "Identification number is required"
              }
            },
            "phone_number": {
              "type": "textField",
              "input_type": "tel",
              "placeholder": "256712345678",
              "required": true,
              "pattern": "^256[0-9]{9}$",
              "defaultValue": "256",
              "error_messages": {
                "required": "Phone number is required",
                "pattern": "Please enter a valid Ugandan phone number starting with 256"
              }
            },
            "email": {
              "type": "textField",
              "input_type": "email",
              "placeholder": "example@email.com",
              "required": false,
              "pattern": "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$",
              "error_messages": {
                "required": "Email is required",
                "pattern": "Please enter a valid email address"
              }
            },
            "address": {
              "type": "textField",
              "input_type": "text",
              "placeholder": "Enter residential address",
              "required": true,
              "min_length": 5,
              "error_messages": {
                "required": "Address is required",
                "min_length": "Address must be at least 5 characters long"
              }
            },
            "gender": {
              "type": "dropdown",
              "placeholder": "Select gender",
              "required": true,
              "options": [
                { "value": "Male", "label": "Male" },
                { "value": "Female", "label": "Female" }
              ],
              "error_messages": {
                "required": "Gender is required"
              }
            },
            // "marital_status": {
            //   "type": "dropdown",
            //   "placeholder": "Select marital status",
            //   "required": true,
            //   "options": [
            //     { "value": "single", "label": "Single" },
            //     { "value": "married", "label": "Married" },
            //     { "value": "divorced", "label": "Divorced" },
            //     { "value": "widowed", "label": "Widowed" }
            //   ],
            //   "error_messages": {
            //     "required": "Marital status is required"
            //   }
            // }
          },
          "field_order": [ "first_name", "last_name", "gender", "date_of_birth",  "identification_type", "identification_number", "phone_number", "email", "address"]
        },
        "spouse": {
          "age_limits": {
            "min": 15,
            "max": 75,
            "error_message": "Spouse must be between 15 and 75 years old"
          },
          "fields": {
            "first_name": {
              "type": "textField",
              "input_type": "text",
              "placeholder": "Enter first name",
              "required": true,
              "min_length": 2,
              "max_length": 50,
              "pattern": "^[a-zA-Z ]*$",
              "error_messages": {
                "required": "First name is required",
                "min_length": "First name must be at least 2 characters",
                "max_length": "First name cannot exceed 50 characters",
                "pattern": "First name can only contain letters and spaces"
              }
            },
            "last_name": {
              "type": "textField",
              "input_type": "text",
              "placeholder": "Enter last name",
              "required": true,
              "min_length": 2,
              "max_length": 50,
              "pattern": "^[a-zA-Z ]*$",
              "error_messages": {
                "required": "Last name is required",
                "min_length": "Last name must be at least 2 characters",
                "max_length": "Last name cannot exceed 50 characters",
                "pattern": "Last name can only contain letters and spaces"
              }
            },
            "date_of_birth": {
              "type": "datePicker",
              "placeholder": "Select date of birth",
              "required": true,
              "error_messages": {
                "required": "Date of birth is required",
                "invalid": "Please enter a valid date"
              }
            },
            "identification_type": {
              "type": "dropdown",
              "placeholder": "Choose identification type",
              "required": true,
              "options": [
                { "value": "national_id", "label": "National ID" },
                { "value": "passport", "label": "Passport" }
              ],
              "error_messages": {
                "required": "Identification type is required"
              }
            },
            "identification_number": {
              "type": "textField",
              "input_type": "text",
              "placeholder": "Enter identification number",
              "required": true,
              "validation_rules": {
                "national_id": {
                  "pattern": "^CM[0-9]{8}[A-Z]{4}$",
                  "error": "Please enter a valid National ID (Format: CM12345678ABCD)"
                },
                "passport": {
                  "pattern": "^[A-Z]{1}[0-9]{7}$",
                  "error": "Please enter a valid Passport number (Format: B1234567)"
                }
              },
              "error_messages": {
                "required": "Identification number is required"
              }
            },
            "gender": {
              "type": "dropdown",
              "placeholder": "Select gender",
              "required": true,
              "options": [
                { "value": "Male", "label": "Male" },
                { "value": "Female", "label": "Female" }
              ],
              "error_messages": {
                "required": "Gender is required"
              }
            }
          },
          "field_order": ["first_name", "last_name", "gender", "date_of_birth", "identification_type", "identification_number"]
        },
        "children": {
          "age_limits": {
            "min": 0,
            "max": 18,
            "max_student": 25,
            "error_message": "Child must be under 18 years old (or under 25 if in full-time education)",
          },
          "fields": {
            "first_name": {
              "type": "textField",
              "input_type": "text",
              "placeholder": "Enter first name",
              "required": true,
              "min_length": 2,
              "max_length": 50,
              "pattern": "^[a-zA-Z ]*$",
              "error_messages": {
                "required": "First name is required",
                "min_length": "First name must be at least 2 characters",
                "max_length": "First name cannot exceed 50 characters",
                "pattern": "First name can only contain letters and spaces"
              }
            },
            "last_name": {
              "type": "textField",
              "input_type": "text",
              "placeholder": "Enter last name",
              "required": true,
              "min_length": 2,
              "max_length": 50,
              "pattern": "^[a-zA-Z ]*$",
              "error_messages": {
                "required": "Last name is required",
                "min_length": "Last name must be at least 2 characters",
                "max_length": "Last name cannot exceed 50 characters",
                "pattern": "Last name can only contain letters and spaces"
              }
            },
            "date_of_birth": {
              "type": "datePicker",
              "placeholder": "Select date of birth",
              "required": true,
              "error_messages": {
                "required": "Date of birth is required",
                "invalid": "Please enter a valid date"
              }
            },
            "gender": {
              "type": "dropdown",
              "placeholder": "Select gender",
              "required": true,
              "options": [
                { "value": "Male", "label": "Male" },
                { "value": "Female", "label": "Female" }
              ],
              "error_messages": {
                "required": "Gender is required"
              }
            },
            "is_student": {
              "type": "checkbox",
              "label": "Child is in full-time education",
              "required": false,
              "error_messages": {
                "invalid": "Please indicate if the child is a student"
              }
            },
            "student_details": {
              "type": "group",
              "dependent_on": "is_student",
              "dependent_value": true,
              "fields": {
                "school_name": {
                  "type": "textField",
                  "input_type": "text",
                  "placeholder": "Enter school name",
                  "required": true,
                  "error_messages": {
                    "required": "School name is required"
                  }
                },
                "student_id": {
                  "type": "textField",
                  "input_type": "text",
                  "placeholder": "Enter student ID",
                  "required": true,
                  "error_messages": {
                    "required": "Student ID is required"
                  }
                }
              }
            }
          },
          "field_order": ["first_name", "last_name", "gender", "date_of_birth", "is_student", "student_details"]
        },
        "parents": {
          "age_limits": {
            "min": 15,
            "max": 75,
            "error_message": "Parent must be between 15 and 75 years old"
          },
          "fields": {
            "first_name": {
              "type": "textField",
              "input_type": "text",
              "placeholder": "Enter first name",
              "required": true,
              "min_length": 2,
              "max_length": 50,
              "pattern": "^[a-zA-Z ]*$",
              "error_messages": {
                "required": "First name is required",
                "min_length": "First name must be at least 2 characters",
                "max_length": "First name cannot exceed 50 characters",
                "pattern": "First name can only contain letters and spaces"
              }
            },
            "last_name": {
              "type": "textField",
              "input_type": "text",
              "placeholder": "Enter last name",
              "required": true,
              "min_length": 2,
              "max_length": 50,
              "pattern": "^[a-zA-Z ]*$",
              "error_messages": {
                "required": "Last name is required",
                "min_length": "Last name must be at least 2 characters",
                "max_length": "Last name cannot exceed 50 characters",
                "pattern": "Last name can only contain letters and spaces"
              }
            },
            "date_of_birth": {
              "type": "datePicker",
              "placeholder": "Select date of birth",
              "required": true,
              "error_messages": {
                "required": "Date of birth is required",
                "invalid": "Please enter a valid date"
              }
            },
            "identification_type": {
              "type": "dropdown",
              "placeholder": "Choose identification type",
              "required": true,
              "options": [
                { "value": "national_id", "label": "National ID" },
                { "value": "passport", "label": "Passport" }
              ],
              "error_messages": {
                "required": "Identification type is required"
              }
            },
            "identification_number": {
              "type": "textField",
              "input_type": "text",
              "placeholder": "Enter identification number",
              "required": true,
              "validation_rules": {
                "national_id": {
                  "pattern": "^CM[0-9]{8}[A-Z]{4}$",
                  "error": "Please enter a valid National ID (Format: CM12345678ABCD)"
                },
                "passport": {
                  "pattern": "^[A-Z]{1}[0-9]{7}$",
                  "error": "Please enter a valid Passport number (Format: B1234567)"
                }
              },
              "error_messages": {
                "required": "Identification number is required"
              }
            },
            "relationship": {
              "type": "dropdown",
              "placeholder": "Select relationship",
              "required": true,
              "options": [
                { "value": "Parent", "label": "Parent" },
                { "value": "Parent-in-law", "label": "Parent-in-law" }
              ],
              "error_messages": {
                "required": "Relationship is required",
                "invalid": "Please select a valid relationship"
              }
            },
            "gender": {
              "type": "dropdown",
              "placeholder": "Select gender",
              "required": true,
              "options": [
                { "value": "Male", "label": "Male" },
                { "value": "Female", "label": "Female" }
              ],
              "error_messages": {
                "required": "Gender is required"
              }
            }
          },
          "field_order": ["relationship", "first_name", "last_name", "gender", "date_of_birth", "identification_type", "identification_number"]
        }
      },
      beneficiary_validations: {
        beneficiary_details: {
          first_name: {
            type: "textField",
            input_type: "text",
            placeholder: "Enter first name",
            required: true,
            min_length: 2,
            max_length: 50,
            pattern: "^[a-zA-Z ]*$",
            error_messages: {
              required: "First name is required",
              min_length: "First name must be at least 2 characters",
              max_length: "First name cannot exceed 50 characters",
              pattern: "First name can only contain letters and spaces"
            }
          },
          last_name: {
            type: "textField",
            input_type: "text",
            placeholder: "Enter last name",
            required: true,
            min_length: 2,
            max_length: 50,
            pattern: "^[a-zA-Z ]*$",
            error_messages: {
              required: "Last name is required",
              min_length: "Last name must be at least 2 characters",
              max_length: "Last name cannot exceed 50 characters",
              pattern: "Last name can only contain letters and spaces"
            }
          },
          relationship: {
            type: "dropdown",
            placeholder: "Select relationship",
            required: true,
            options: [
              { value: "spouse", label: "Spouse" },
              { value: "child", label: "Child" },
              { value: "parent", label: "Parent" },
              { value: "sibling", label: "Sibling" },
              { value: "other", label: "Other" }
            ],
            error_messages: {
              required: "Relationship is required"
            }
          },
          date_of_birth: {
            type: "datePicker",
            placeholder: "Select date of birth",
            required: true,
            error_messages: {
              required: "Date of birth is required",
              invalid: "Please enter a valid date"
            }
          },
          identification_type: {
            type: "dropdown",
            placeholder: "Choose identification type",
            required: true,
            options: [
              { value: "national_id", label: "National ID" },
              { value: "passport", label: "Passport" }
            ]
          },
          identification_number: {
            type: "textField",
            input_type: "text",
            placeholder: "Enter identification number",
            required: true,
            dependent_on: "identification_type",
            validation_rules: {
              national_id: {
                pattern: "^CM[0-9]{8}[A-Z]{4}$",
                error: "Please enter a valid National ID (Format: CM12345678ABCD)"
              },
              passport: {
                pattern: "^[A-Z]{1}[0-9]{7}$",
                error: "Please enter a valid Passport number (Format: B1234567)"
              }
            }
          },
          percentage: {
            type: "textField",
            input_type: "number",
            placeholder: "Enter benefit percentage",
            required: true,
            validation_rules: {
              min: 1,
              max: 100,
              error: "Percentage must be between 1 and 100"
            },
            error_messages: {
              required: "Benefit percentage is required"
            }
          }
        },
        bank_details: {
          bank_name: {
            type: "dropdown",
            placeholder: "Select bank",
            required: true,
            options: [
              { value: "postbank", label: "PostBank Uganda" },
              { value: "stanbic", label: "Stanbic Bank" },
              { value: "dfcu", label: "DFCU Bank" },
              { value: "centenary", label: "Centenary Bank" }
            ],
            error_messages: {
              required: "Bank name is required"
            }
          },
          account_number: {
            type: "textField",
            input_type: "text",
            placeholder: "Enter account number",
            required: true,
            validation_rules: {
              pattern: "^[0-9]{10,14}$",
              error: "Please enter a valid account number (10-14 digits)"
            },
            error_messages: {
              required: "Account number is required"
            }
          },
          account_type: {
            type: "dropdown",
            placeholder: "Select account type",
            required: true,
            options: [
              { value: "savings", label: "Savings Account" },
              { value: "current", label: "Current Account" }
            ],
            error_messages: {
              required: "Account type is required"
            }
          },
          account_holder: {
            type: "textField",
            input_type: "text",
            placeholder: "Enter account holder name",
            required: true,
            validation_rules: {
              pattern: "^[a-zA-Z ]{2,50}$",
              error: "Please enter a valid account holder name"
            },
            error_messages: {
              required: "Account holder name is required"
            }
          },
          // debit_order_day: {
          //   type: "dropdown",
          //   placeholder: "Select debit order day",
          //   required: true,
          //   options: Array.from({length: 28}, (_, i) => ({
          //     value: (i + 1).toString(),
          //     label: `${i + 1}${getOrdinalSuffix(i + 1)} of the month`
          //   })),
          //   error_messages: {
          //     required: "Debit order day is required"
          //   }
          // },
          branch_code: {
            type: "textField",
            input_type: "text",
            placeholder: "Enter branch code",
            required: true,
            validation_rules: {
              pattern: "^[0-9]{3,6}$",
              error: "Please enter a valid branch code (3-6 digits)"
            },
            error_messages: {
              required: "Branch code is required"
            }
          }
        }
      }
    }
  }
};

// Helper function for ordinal suffixes
function getOrdinalSuffix(number) {
  const j = number % 10;
  const k = number % 100;
  if (j === 1 && k !== 11) return "st";
  if (j === 2 && k !== 12) return "nd";
  if (j === 3 && k !== 13) return "rd";
  return "th";
}