import React, { useState, useEffect } from 'react'
import moment from 'moment';
import { useStyle } from "./Style";
import Grid from "@material-ui/core/Grid";
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';

const HospitalClaimDetails = (props) => {
  const classes = useStyle();

  const [admissionDate, setAdmissionDate] = useState('');
  const [dischargeDate, setDischargeDate] = useState('');
  const [numberOfDaysInHospital, setNumberOfDaysInHospital] = useState('');
  const [hospitalAttended, setHospitalAttended] = useState('');
  const [inpatientNumber, setInpatientNumber] = useState('');
  const [causeOfHospitilization, setCauseOfHospitilization] = useState('');




  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "admissionDate") {
      setAdmissionDate(value)
      props.setAdditionalState("admission_date", value)
    }
    if (name === "dischargeDate") {
      setDischargeDate(value)
      props.setAdditionalState("discharge_date", value)
      props.setAdditionalState("beneficiary_national_id", props.eventData.additional_details.beneficiary_national_id)
      props.setAdditionalState("product_code", props.eventData.product_code)
      props.setAdditionalState("policy_start_date", props.eventData.start_date)
      props.setAdditionalState("weekly_installment_amount", props.eventData.additional_details.weekly_installment_amount)
    }

  }

  useEffect(() => {
    if (dischargeDate && admissionDate) {
      const days = daysInHospital(dischargeDate, admissionDate);
      setNumberOfDaysInHospital(days);
      props.setAdditionalState("number_of_days_in_hospital", days)
    }
  }, [dischargeDate, admissionDate]);

  const daysInHospital = (dischargeDate, admissionDate) =>{

    return moment(dischargeDate).diff(moment(admissionDate), 'days');

  }




  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <div className={classes.headingclaim}>
            <h4>Claim Information For Hospitilization</h4>
          </div>
        </Grid>
      </Grid>
      <ValidatorForm onSubmit={() => { }}>
        <Grid container spacing={2} className={classes.selectpolicyWrapper}>

          <Grid item xs={12} md={3} sm={3}>
            <TextValidator
              id="admissionDate"
              label="Admission Date"
              name='admissionDate'
              value={admissionDate}
              onChange={handleChange}
              fullWidth
              type='date'
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              validators={['required']}
              errorMessages={['Please enter admission date']}
              style={{ width: "100%" }}
            />
          </Grid>



          <Grid item xs={12} md={3} sm={3}>
            <TextValidator
              id="dischargeDate"
              label="Discharge Date "
              name='dischargeDate'
              value={dischargeDate}
              onChange={handleChange}
              fullWidth
              type='date'
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              style={{ width: "100%" }}
            />
          </Grid>

          <Grid item xs={12} md={3} sm={3}>
            <TextValidator
              id="numberOfDaysInHospital"
              style={{ width: "100%" }}
              label="Number Of Days In Hospital"
              name='numberOfDaysInHospital'
              value={numberOfDaysInHospital}
              onChange={handleChange}
              disabled
              variant="outlined"
              validators={['required', 'minNumber:10', 'maxNumber:28']}
              errorMessages={['This field is required', 'number of days in hospital must be 10 to 28', 'number of days in hospital must be 10 to 28']}
            />
          </Grid>

          <Grid item xs={12} md={3} sm={3}>
            <TextValidator
              id="hospital_attended"
              style={{ width: "100%" }}
              label="Hospital Attended"
              name='hospital_attended'
              value={hospitalAttended}
              type='text'
              onChange={(e) => {
                setHospitalAttended(e.target.value)
                props.setAdditionalState("hospital_attended", e.target.value)
              }}
              variant="outlined"
          
            />
          </Grid>

          <Grid item xs={12} md={3} sm={3}>
            <TextValidator
              id="inpatient_number"
              style={{ width: "100%" }}
              label="Inpatient Number"
              name='inpatient_number'
              type='text'
              value={inpatientNumber}
              onChange={(e) => {
                setInpatientNumber(e.target.value)
                props.setAdditionalState("inpatient_number", e.target.value)
              }}
              variant="outlined"
          
            />
          </Grid>

          <Grid item xs={12} md={3} sm={3}>
            <TextValidator
              id="cause_of_hospitilization"
              style={{ width: "100%" }}
              label="Cause Of Hospitilization"
              name='cause_of_hospitilization'
              type='text'
              value={causeOfHospitilization}
              onChange={(e) => {
                setCauseOfHospitilization(e.target.value)
                props.setAdditionalState("cause_of_hospitilization", e.target.value)
              }}
              variant="outlined"
          
            />
          </Grid>

 

        </Grid>




      </ValidatorForm>
    </>
  )
}

export default HospitalClaimDetails
