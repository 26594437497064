import React from 'react';
import { hashHistory } from 'react-router';
import { connect } from "react-redux";
import LocalizedStrings from "react-localization";
import * as actions from './Redux/actions.js'

import { localisedText } from '../../../lib/localisation.es6';
import { getCustomer, updateCustomerRequest, resetUpdateCustomerl } from '../../../containers/Customer/actions.es6.js';
import { registerCustomerRequest, resetRegistration } from '../../../containers/CustomerScreen/components/RegisterCustomer/actions.es6.js';
import { getAllProductsRequest } from '../../../containers/CustomerScreen/actions.es6.js';
import { getUsersRequest } from '../../../containers/Users/actions.es6.js';
import { createDebitOrdersDcpRequest, uploadPolicyDcpDocumentRequest } from '../../../containers/Policyv2/Redux/actions.js';
import QuoteCreation from './Components/QuoteCreation/index.js';

class PolicyV2 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      labels: new LocalizedStrings(localisedText),
      openRegisterCustomerDialog: false,
      customerQuote: [],
      autoFillPrincipal: false,
      
    };
  }


  componentDidMount() {
    let customer_guid = this.props.params.customer_guid
    this.props.dispatch(getCustomer(customer_guid));
  }

  componentWillMount() {
    const currentCustomer = this.props.customerData.currentCustomer;
    if (currentCustomer) {
      this.props.dispatch(getCustomer(currentCustomer.guid))
    }
    this.props.dispatch(getUsersRequest());
    this.props.dispatch(getAllProductsRequest())
    this.resetAllAlerts();
  }

  resetAllAlerts() {
    this.props.dispatch(actions.resetQuoteSuccess());
    this.props.dispatch(actions.resetQuoteError());
    this.props.dispatch(actions.resetBuypolicySuccess());
    this.props.dispatch(actions.resetBuypolicyError());
  }

  registerCustomer(customerData) {
    this.props.dispatch(registerCustomerRequest(customerData));
  }
  resetRegistrationSuccess() {
    this.props.dispatch(resetRegistration());
    hashHistory.push('admin/home');
    this.toggleRegisterCustomerDialog()
  }

  resetRegistrationError() {
    this.props.dispatch(resetRegistration())
  }
  toggleRegisterCustomerDialog() {
    this.setState({ openRegisterCustomerDialog: !this.state.openRegisterCustomerDialog })
  }

  createQuote(payload) {
    payload.customer_guid = this.props.customerData.currentCustomer.guid

    this.props.dispatch(actions.createQuoteRequest(payload))
    this.setState({ customerQuote: payload })
  }


  createDependant(payload) {
    this.props.dispatch(actions.CreateDependantRequest(payload))
  }

  buyPolicy(payload) {
    this.props.dispatch(actions.buyPolicyRequest(payload))
  }

  editCustomer(payload) {
    this.props.dispatch(updateCustomerRequest(payload))
  }

  resetAfterSucesfulyEdit() {
    this.props.dispatch(resetUpdateCustomerl());
  }

  resetAfterFailedEdit() {
    this.props.dispatch(resetUpdateCustomerl());
  }

  changequote(payload) {
    this.props.dispatch(actions.changeQuoteRequest(payload))
  }

  toggleAutoFillPrincipal() {
    this.setState({ autoFillPrincipal: !this.state.autoFillPrincipal })
  }


  addDebitOrder(payload) {
    this.props.dispatch(createDebitOrdersDcpRequest(payload));
  }

  DocumentUpload(doc) {
    const policyGuid = this.props.QuoteData.buyPolicy.guid
    this.props.dispatch(uploadPolicyDcpDocumentRequest({ ...doc, policyGuid }))

  }

  render() {

    if (!this.props.customerData.currentCustomer) {
      return "Loading...";
  }



  
    // let agentUsersData = this.props.userData.users.filter(user => user.attributes.isAgent && user.attributes.isAgent[0] === 'true') || [];

    const documentUpload = (doc) => {
      // const policyGuid = this.props.QuoteData.buyPolicy.guid
      this.props.dispatch(uploadPolicyDcpDocumentRequest({ ...doc, policyGuid: 'b60788ce-ffa3-4b04-8ecc-f5a10286385b' }))
    }

    const createQoute = (payload) => {
      // const policyGuid = this.props.QuoteData.buyPolicy.guid
      this.props.dispatch(uploadPolicyDcpDocumentRequest({ ...payload, policyGuid: 'b60788ce-ffa3-4b04-8ecc-f5a10286385b' }))
    }

    const createPolicy = (payload) => {
      // const policyGuid = this.props.QuoteData.buyPolicy.guid
      this.props.dispatch(uploadPolicyDcpDocumentRequest({ ...payload, policyGuid: 'b60788ce-ffa3-4b04-8ecc-f5a10286385b' }))
    }

    const addBankingDetails = (payload) => {
      // const policyGuid = this.props.QuoteData.buyPolicy.guid
      this.props.dispatch(uploadPolicyDcpDocumentRequest({ ...payload, policyGuid: 'b60788ce-ffa3-4b04-8ecc-f5a10286385b' }))
    }
    return (

    <QuoteCreation 
      documentUpload={documentUpload}
      createQoute={createQoute}
      createPolicy={createPolicy}
      addBankingDetails={addBankingDetails}
      customerGuid={this.props.customerData.currentCustomer.guid}
      customerMsisdn={this.props.customerData.currentCustomer.msisdn}
      customer={this.props.customerData.currentCustomer}
    />
    )
  }
}
export default connect((state) => ({
  QuoteData: state.quoteData,
  customerStatusV2Data: state.customerStatusV2Data,
  customerData: state.currentCustomer,
  registrationData: state.customerRegistration,
  customerScreenData: state.customerScreen,
  userData: state.userAdmin,
  globalData: state.global,
}))(PolicyV2);