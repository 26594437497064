import React, { useState } from 'react';
import { 
  Box, 
  Typography,
  Paper,
  TextField,
  MenuItem,
} from '@mui/material';
import { makeStyles } from "@material-ui/styles";
import { QUOTE_COLORS } from '../constants';
import ButtonInc from '../../../../../../shared-ui/ButtonInc';
import { productData } from '../../../helpers/product';

const useStyles = makeStyles(() => ({
  root: {
    padding: '1.5rem'
  },
  title: {
    color: QUOTE_COLORS.PRIMARY,
    marginBottom: '1rem'
  },
  subtitle: {
    color: QUOTE_COLORS.TEXT.SECONDARY,
    marginBottom: '2rem',
    fontSize: '0.875rem'
  },
  formCard: {
    padding: '2rem',
    marginBottom: '1.5rem',
    '& > div': {
      marginBottom: '2rem',
      '&:last-child': {
        marginBottom: 0
      }
    }
  },
  field: {
    width: '100%'
  },
  actions: {
    display: 'flex',
    justifyContent: 'center',
    gap: '1rem',
    marginTop: '2rem'
  }
}));

const BankingDetails = ({ quoteData, updateQuoteData, onNext, onBack }) => {
  const classes = useStyles();
  const [formData, setFormData] = useState(quoteData.bankingDetails || {});
  const [errors, setErrors] = useState({});
  
  const bankingFields = productData.membership_configs.content.beneficiary_validations.bank_details;

  const validateField = (name, value) => {
    const fieldConfig = bankingFields[name];
    if (!fieldConfig) return null;

    if (fieldConfig.required && !value) {
      return fieldConfig.error_messages.required;
    }

    if (fieldConfig.validation_rules && fieldConfig.validation_rules.pattern) {
      const pattern = new RegExp(fieldConfig.validation_rules.pattern);
      if (!pattern.test(value)) {
        return fieldConfig.validation_rules.error;
      }
    }

    return null;
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));

    const error = validateField(name, value);
    setErrors(prev => ({
      ...prev,
      [name]: error
    }));
  };

  const handleSubmit = () => {
    // Validate all fields
    const newErrors = {};
    Object.keys(bankingFields).forEach(fieldName => {
      const error = validateField(fieldName, formData[fieldName]);
      if (error) newErrors[fieldName] = error;
    });

    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) return;

    // Save banking data and proceed
    updateQuoteData('bankingDetails', formData);
    onNext();
  };

  const renderField = (fieldName) => {
    const fieldConfig = bankingFields[fieldName];
    if (!fieldConfig) return null;

    const commonProps = {
      name: fieldName,
      value: formData[fieldName] || '',
      onChange: handleChange,
      error: !!errors[fieldName],
      helperText: errors[fieldName],
      fullWidth: true,
      className: classes.field,
      label: fieldConfig.placeholder,
      required: fieldConfig.required,
    };

    switch (fieldConfig.type) {
      case 'dropdown':
        return (
          <TextField
            {...commonProps}
            select
          >
            {fieldConfig.options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        );

      case 'datePicker':
        return (
          <TextField
            {...commonProps}
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
          />
        );

      default:
        return (
          <TextField
            {...commonProps}
            type={fieldConfig.input_type || 'text'}
          />
        );
    }
  };

  return (
    <Box className={classes.root}>
      <Typography variant="h6" className={classes.title}>
        Banking Details
      </Typography>
      <Typography className={classes.subtitle}>
        Please provide your banking details for premium collection
      </Typography>

      <Paper className={classes.formCard}>
        {Object.keys(bankingFields).map((fieldName) => (
          <Box key={fieldName} sx={{ width: '100%' }}>
            {renderField(fieldName)}
          </Box>
        ))}
      </Paper>

      <Box className={classes.actions}>
        <ButtonInc btnType="secondary" onClick={onBack}>
          Back
        </ButtonInc>
        <ButtonInc 
          onClick={handleSubmit}
          disabled={Object.values(errors).some(error => error)}
        >
          Continue
        </ButtonInc>
      </Box>
    </Box>
  );
};

export default BankingDetails; 