import * as types from '../../../../../../lib/actionTypes.es6';
import GAlogger, { monitoringEventCategories } from '../../../../../../lib/monitoring.es6';

export function resetDcpRegistration() {
  return {
    type: types.RESET_DCP_REGISTRATION,
  };
}

export function registerDcpCustomerRequest(customerData) {
  return {
    type: types.REGISTER_DCP_CUSTOMER_REQUEST,
    payload: customerData,
  };
}

export function registerDcpCustomerSuccess(customerInfo) {
  const event = monitoringEventCategories.userActions;
  GAlogger(event.name, event.actions.registerCustomer);
  return {
    type: types.REGISTER_DCP_CUSTOMER_SUCCESS,
    payload: customerInfo,
  };
}

export function registerDcpCustomerFail(response) {
  return {
    type: types.REGISTER_DCP_CUSTOMER_FAIL,
    payload: response,
  };
}

export function displayDcpError(message) {
  return {
    type: types.SET_DCP_REGISTRATION_ERROR,
    payload: message,
  };
}



export function updateDcpCustomerRequest(customerData) {
  return {
    type: types.UPDATE_DCP_CUSTOMER_REQUEST,
    payload: customerData,
  };
}

export function updateDcpCustomerSuccess(customerInfo) {
  const event = monitoringEventCategories.userActions;
  GAlogger(event.name, event.actions.updateCustomer);
  return {
    type: types.UPDATE_DCP_CUSTOMER_SUCCESS,
    payload: customerInfo,
  };
}

export function updateDcpCustomerFail(response) {
  return {
    type: types.UPDATE_DCP_CUSTOMER_FAIL,
    payload: response,
  };
}

export function resetDcpUpdateCustomer() {
  return {
    type: types.UPDATE_CUSTOMER_DCP_RESET,
  };
}

export function displayEditDcpError(error) {
  return {
    type: types.EDIT_DCP_CUSTOMER_DISPLAY_ERROR,
    payload: error,
  };
}