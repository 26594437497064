import React from "react"
import HospitalExpenses from "./HospitalExpenses"
import DeathExpenses from "./DeathExpenses"
import DisabilityExpenses from './DisabilityExpenses'
import TemporaryDisabilityExpenses from "./TemporaryDisabilityExpenses"
export default function ClaimExpenses(props) {

  if(props.hasDeathBenefit){
    return <DeathExpenses {...props} />
  }


  if(props.hasDisabilityBenefit){
    return <DisabilityExpenses {...props} />
  }


  if(props.hasTemporaryDisabilityBenefit){
    return <TemporaryDisabilityExpenses {...props} />
  }
  return (
    <HospitalExpenses {...props} />
  )
}