import React from 'react';
import { 
  Box, 
  Typography,
  Grid,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import { makeStyles } from "@material-ui/styles";
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import ChildCareIcon from '@mui/icons-material/ChildCare';
import ElderlyIcon from '@mui/icons-material/Elderly';
import { QUOTE_COLORS } from '../constants';

const useStyles = makeStyles(() => ({
  root: {
    padding: '1.5rem',
  },
  title: {
    marginBottom: '2rem',
    color: QUOTE_COLORS.PRIMARY,
    textAlign: 'center',
  },
  card: {
    cursor: 'pointer',
    transition: 'all 0.2s ease-in-out',
    height: '100%',
    '&:hover': {
      transform: 'translateY(-5px)',
      boxShadow: `0 8px 24px ${QUOTE_COLORS.SHADOW.HOVER}`,
    },
  },
  disabledCard: {
    opacity: 0.5,
    cursor: 'not-allowed',
    '&:hover': {
      transform: 'none',
      boxShadow: 'none',
    },
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '1.5rem',
  },
  icon: {
    fontSize: '48px',
    color: QUOTE_COLORS.PRIMARY,
    marginBottom: '1rem',
  },
  memberType: {
    fontWeight: 500,
    marginBottom: '0.5rem',
  },
  limit: {
    color: QUOTE_COLORS.TEXT.SECONDARY,
    fontSize: '0.875rem',
  }
}));

const MemberTypeSelection = ({ onSelect, memberCounts, memberLimits }) => {
  const classes = useStyles();

  const memberTypes = [
    {
      type: 'spouse',
      label: 'Spouse',
      icon: FamilyRestroomIcon,
      limit: 1
    },
    {
      type: 'children',
      label: 'Child',
      icon: ChildCareIcon,
      limit: 4
    },
    {
      type: 'parents',
      label: 'Parent',
      icon: ElderlyIcon,
      limit: 4
    }
  ];

  const canAddMember = (type) => {
    const currentCount = memberCounts[type] || 0;
    // Allow adding more children beyond limit, but enforce limit for other types
    if (type === 'children') {
      return true; // Always allow adding children
    }
    return currentCount < memberLimits[type];
  };

  return (
    <Box className={classes.root}>
      <Typography variant="h5" className={classes.title}>
        Add a Family Member
      </Typography>
      
      <Grid container spacing={3}>
        {memberTypes.map((memberType) => (
          <Grid item xs={12} sm={4} key={memberType.type}>
            <Card 
              className={`${classes.card} ${!canAddMember(memberType.type) ? classes.disabledCard : ''}`}
              onClick={() => canAddMember(memberType.type) && onSelect(memberType.type)}
            >
              <CardContent className={classes.cardContent}>
                <memberType.icon className={classes.icon} />
                <Typography className={classes.memberType}>
                  {memberType.label}
                </Typography>
                <Typography className={classes.limit}>
                  {memberCounts[memberType.type] || 0} of {memberType.limit} added
                  {memberType.type === 'children' && memberCounts[memberType.type] >= 4 && (
                    <Box component="span" sx={{ display: 'block', color: 'warning.main', fontSize: '0.75rem' }}>
                      Additional premium applies
                    </Box>
                  )}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default MemberTypeSelection; 