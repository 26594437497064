import React from 'react'
import DeathClaimDetails from '../DeathClaimDetails/DeathClaimDetails'
import HospitalClaimDetails from '../HospitalClaimDetails/HospitalClaimDetails'
import DisabilityClaimDetails from '../DisabilityClaimDetails/DisabilityClaimDetails'

export default function AdditionalDetails(props) {

  if(props.hasDeathBenefit){
    return <DeathClaimDetails {...props} />
  }
  if(props.hasHospitalBenefit || props.hasCriticalIllnessBenfit){
    return <HospitalClaimDetails {...props} />
  }


  if(props.hasParmanentDisabilityBenefit){
    return <DisabilityClaimDetails {...props} />
  }

  
}
