import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import DashboardCustomizeOutlinedIcon from '@mui/icons-material/DashboardCustomizeOutlined';
import { canViewDashboard, hasAuthourity } from '../../lib/access.es6';
import SlideshowIcon from '@mui/icons-material/Slideshow';


export const ktdaMenu = (labels) =>  {

  return [
    {
      id: 'application',
      title: 'Home',
      icon: HomeOutlinedIcon,
      type: 'group',
      permisions: [],
      children: [
        {
          id: 'admin/home',
          title: labels.home,
          type: 'item',
          url: 'admin/home',
          icon: HomeOutlinedIcon,
          permisions: []
        },
        {
          id: 'admin/dashboards',
          title: labels.dashboard,
          type: 'item',
          url: 'admin/dashboards',
          icon: DashboardCustomizeOutlinedIcon,
          permisions: [canViewDashboard()]
        },
 
        {
          title: 'Bulk Onboard',
          label: 'Bulk Onboard',
          type: 'item',
          url: 'admin/bulk-policy-onboarding',
          id: 'admin/bulk-policy-onboarding',
          style: '20px',
          icon: UploadFileOutlinedIcon,
          permisions: [hasAuthourity('BULK_ONBOARD_CUSTOMERS')]
        },
        {
          id: 'Claims',
          title: 'Claims',
          type: 'collapse',
          icon: InventoryOutlinedIcon,
          permisions: [hasAuthourity('VIEW_CLAIMS')],
          children: [
            {
              title: 'Hospital Claims',
              type: 'item',
              url: 'admin/Hospital_claims',
              id: 'admin/Hospital_claims',
              permisions: []
            },
            {
              title: 'Reimbursement',
              type: 'item',
              url: 'admin/Reimbursement_claims',
              id: 'admin/Reimbursement_claims',
              permisions: []
            },
            {
              title: 'Death Claims',
              type: 'item',
              url: 'admin/Death_claims',
              id: 'admin/Death_claims',
              permisions: []
            },
  
    
          ]
        },
  
        {
          title: 'Payments',
          id: 'Payments',
          label: labels.payments,
          type: 'collapse',
          url: 'admin/missing/payments',
          icon: AccountBalanceOutlinedIcon,
          permisions: [hasAuthourity('PREMIUM_UPDATE')],
          children:
            [
              {
                title: 'Bulk Payments',
                label: labels.bulkPayments,
                type: 'item',
                url: 'admin/bulk-payments',
                id: 'admin/bulk-payments',
                permisions: [hasAuthourity('PREMIUM_UPDATE')]
              },
            ]
        },
        {
          id: 'Reports',
          title: 'Reports',
          type: 'item',
          url: 'admin/reports',
          icon: AssessmentOutlinedIcon,
          permisions: [hasAuthourity('VIEW_NEW_REPORTS_INTERNAL')],
          children:

            [
              
            ]
        },
        // {
        //   id: 'Reports',
        //   title: labels.reports,
        //   type: 'item',
        //   url: 'admin/reports',
        //   icon: AssessmentOutlinedIcon,
        //   permisions: [hasAuthourity('VIEW_BUSINESS_REPORT')],
        //   children:

        //     [

        //       {
        //         title: labels.premiumReport,
        //         type: 'item',
        //         url: 'admin/premium_report',
        //         id: 'admin/premium_report',
        //         permisions: []
        //       },
        //       {
        //         title: labels.payments,
        //         type: 'item',
        //         url: 'admin/payment_report',
        //         id: 'admin/payment_report',
        //         permisions: []
        //       },

        //       {
        //         title: labels.openClaims,
        //         type: 'item',
        //         url: 'admin/open_claim_report',
        //         id: 'admin/open_claim_report',
        //         permisions: []
        //       },
        //       {
        //         title: labels.registrations,
        //         type: 'item',
        //         url: 'admin/registration_report',
        //         id: 'admin/registration_report',
        //         permisions: []
        //       },
        //       {
        //         title: labels.titlePolicies,
        //         type: 'item',
        //         url: 'admin/created_policies_report',
        //         id: 'admin/created_policies_report',
        //         permisions: []
        //       },

        //       {
        //         title: labels.reportSuspendedPolicies,
        //         type: 'item',
        //         url: 'admin/suspended_policies_report',
        //         id: 'admin/suspended_policies_report',
        //         permisions: []
        //       },
        //       {
        //         title: labels.reportPayableClaims,
        //         type: 'item',
        //         url: 'admin/claims_payable_report',
        //         id: 'admin/claims_payable_report',
        //         permisions: []
        //       },
        //       {
        //         title: labels.premiumDueReport,
        //         type: 'item',
        //         url: 'admin/premium_due_report',
        //         id: 'admin/premium_due_report',
        //         permisions: []
        //       },
        //       {
        //         title: labels.preAuthorizationReport,
        //         type: 'item',
        //         url: 'admin/pre_authorization_report',
        //         id: 'admin/pre_authorization_report',
        //         permisions: []
        //       },
        //       {
        //         title: labels.claimDeathReprt,
        //         type: 'item',
        //         url: 'admin/claim_death_report',
        //         id: 'admin/claim_death_report',
        //         permisions: []
        //       },
        //       {
        //         title: labels.hospitalClaimReport,
        //         type: 'item',
        //         url: 'admin/claim_hospital_report',
        //         id: 'admin/claim_hospital_report',
        //         permisions: []
        //       },
        //       {
        //         title: labels.claimReimbursementReport,
        //         type: 'item',
        //         url: 'admin/claim_reimbursement_report',
        //         id: 'admin/claim_reimbursement_report',
        //         permisions: []
        //       },
        //       {
        //         title: labels.claimFinalizationDeathReprt,
        //         type: 'item',
        //         url: 'admin/claim_finalization_death_report',
        //         id: 'admin/claim_finalization_death_report',
        //         permisions: []
        //       },
        //       {
        //         title: labels.hospitalFinalizationClaimReport,
        //         type: 'item',
        //         url: 'admin/claim_finalization_hospital_report',
        //         id: 'admin/claim_finalization_hospital_report',
        //         permisions: []
        //       },
        //       {
        //         title: labels.claimFinalizationReimbursementReport,
        //         type: 'item',
        //         url: 'admin/claim_finalization_reimbursement_report',
        //         id: 'admin/claim_finalization_reimbursement_report',
        //         permisions: []
        //       }
        //     ]
        // },
        {
          title: 'Knowledge Base',
          label: labels.partners,
          icon: SlideshowIcon,
          type: 'item',
          url: 'admin/knowledge_base',
          id: 'admin/knowledge_base',
          permisions: []
        }
      ]
    },
    {
      id: 'application',
      title: 'Configs',
      icon: HomeOutlinedIcon,
      type: 'group',
      permisions: [canViewDashboard()],
      children: [
        {
          title: 'Configurations',
          id: 'Configurations',
          label: labels.Configurations,
          type: 'collapse',
          url: 'configs',
          icon: SettingsOutlinedIcon,
          permisions: [hasAuthourity('INTERNAL_DASHBOARD')],
          children:
            [{
              title: 'Products',
              label: labels.products,
              type: 'item',
              url: 'admin/products',
              id: 'admin/products',
              permisions: [hasAuthourity('VIEW_ALL_PRODUCTS')]
            },
            {
              title: 'SMS Templates',
              label: labels.smsTemplates,
              type: 'item',
              url: 'admin/view_sms_templates',
              id: 'admin/view_sms_templates',
              permisions: [hasAuthourity('VIEW_ALL_SMS_TEMPLATES')]
            },
            {
              title: 'Document Templates',
              label: labels.Templates,
              type: 'item',
              url: 'admin/view_doc_templates',
              id: 'admin/view_doc_templates',
              permisions: [hasAuthourity('VIEW_ALL_TEMPLATES')]
            },
            {
              title: 'Reports Configs',
              label: 'Reports Configs',
              type: 'item',
              url: 'admin/view_reports_configs',
              id: 'admin/view_report_configs',
              permisions: [hasAuthourity('VIEW_NEW_REPORTS_INTERNAL')]
            },
            {
              title: 'Partner',
              label: labels.partners,
              type: 'item',
              url: 'admin/partners/view/partner',
              id: 'admin/partners/view/partner',
              permisions: [hasAuthourity('VIEW_ALL_PARTNERS')]
            }
            ]
        },
        {
          title: labels.InternalConfigurations,
          id: 'Internal Configurations',
          label: labels.InternalConfigurations,
          type: 'collapse',
          url: 'Internal Configurations',
          icon: TuneOutlinedIcon,
          permisions: [hasAuthourity('INTERNAL_DASHBOARD')],
          children:
            [{
              title: 'Partners',
              label: labels.partners,
              type: 'item',
              url: 'admin/partners',
              id: 'admin/partners',
              permisions: [hasAuthourity('VIEW_ALL_PARTNERS')]
            },
            {
              title: 'User Administration',
              label: labels.userAdministration,
              type: 'item',
              url: 'admin/users',
              id: 'admin/users',
              permisions: [hasAuthourity('VIEW_ALL_USER')]
            },
            {
              title: 'Country Administration',
              label: labels.countryAdministration,
              type: 'item',
              url: 'admin/country',
              id: 'admin/country',
              permisions: [hasAuthourity('VIEW_COUNTRIES')]
            },
            {
              title: 'Languages',
              label: labels.languages,
              type: 'item',
              url: 'admin/languages',
              id: 'admin/languages',
              permisions: []
            },
            {
              title: 'Feature Flags',
              label: 'Feature Flags',
              type: 'item',
              url: 'admin/feature-flags',
              id: 'admin/feature-flags',
              permisions: []
            },
            ]
        },
      ]
    },
  
  
  ]
}

