import React from 'react'
import Grid from "@material-ui/core/Grid";
import { useStyle } from "./Style";

import ButtonInc from '../../../../../../shared-ui/ButtonInc';
import { AddCircleRounded } from '@mui/icons-material';
import { membersTotalPremiums } from '../../../helpers';

export default function ViewMembers(props) {

  const classes = useStyle();

  const nuclear_members = props.dependants.filter(member => ['Principal', 'Spouse', 'Child'].includes(member.relationship))
  const parent_members = props.dependants.filter(member => ['Parent', 'Parent_In_Law'].includes(member.relationship))
  const extended_members = props.dependants.filter(member => !['Parent', 'Parent_In_Law','Principal', 'Spouse', 'Child'].includes(member.relationship))

  const spouse_member = props.dependants.filter(member => member.relationship === 'Spouse')[0]
console.log('parent_members: ', parent_members);

  const nuclearTotalPremiums = membersTotalPremiums(nuclear_members)
  const parentTotalPremiums = membersTotalPremiums(parent_members)
  console.log('parentTotalPremiums: ', parentTotalPremiums);
  
  const extendedTotalPremiums = membersTotalPremiums(extended_members)

  console.log('view members: ', props.dependants);


  return (
    <div>
      {
        nuclear_members.length > 0 && 
        <section className={classes.dependantdetailstable}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <h6>Nuclear members ({props.selectedPlan.name}) </h6>
            </Grid>
            <Grid item xs={3} style={{ textAlign: 'left', marginTop: '2px' }}>
              {props.coverType !== 'Member Only' && <AddCircleRounded
                style={{ cursor: "pointer", margin: 0 }}
                onClick={() => props.addAnotherMember('nuclear_members')}
              />}
            </Grid>
            <Grid item xs={6} style={{ textAlign: 'right', marginTop: '2px' }}>
              <h6 style={{ textAlign: 'right' }} > Nuclear Members Premiums: {props.currencyCode} {nuclearTotalPremiums}</h6>
            </Grid>

            <Grid item xs={12} sm={12} md={12}>

              <div className={classes.dependantWrapper}>

                <table>
                  <tr>
                    <th>Dependant Name</th>
                    <th>Relationship</th>
                    <th>Additional Benefits </th>
                    <th>Action</th>
                  </tr>
                  {nuclear_members.map((d, index) => {
                    return (
                      <tr key={index}>
                        <td>{d.first_name + " " + d.last_name}</td>
                        <td>{d.relationship}</td>
                        <td>
                          <ul>
                            <div style={{ display: 'flex' }}>
                              <div style={{ width: '80%' }}>
                                {d.benefits.map((b, i) => {
                                  return (
                                    <li key={i}>
                                      {(b.type + " ").toLowerCase()}
                                      <strong>
                                        {props.currencyCode +
                                          " " +
                                          (b.cover_amount_in_cents / 100).toLocaleString()
                                          + " (" + props.currencyCode +
                                          " " + b.premium_amount / 100 + " )"
                                        }
                                      </strong>
                                    </li>
                                  );
                                })}
                              </div>
                              {d.relationship !== 'Child' && <div style={{ width: '20%' }}>
                                <AddCircleRounded
                                  style={{ cursor: "pointer", margin: 0 }}
                                  onClick={() => {
                                    props.setOpenAddBenefitsDialog({
                                      coverType: props.coverType,
                                      memberUUID: d.uuid,
                                      selectedPlan: props.selectedPlan,
                                      spouse_member,
                                      relationship: d.relationship
                                    });
                                  }}
                                />
                              </div>}

                            </div>

                          </ul>

                        </td>
                        <th><ButtonInc style={{ cursor: "pointer" }} onClick={() => { props.removeDependant(d.uuid) }} >remove member</ButtonInc></th>

                      </tr>
                    );
                  })}
                </table>



              </div>
            </Grid>
          </Grid>
        </section>
      }

      {
        parent_members.length >= 0 && <section className={classes.dependantdetailstable}>
          <Grid container spacing={2}>
            <Grid item xs={3} style={{ display: 'flex' }}>
              <h6>Parents ({props.parentsPlan ? props.parentsPlan.name : props.selectedPlan.name}) </h6>
            </Grid>

            <Grid item xs={3} style={{ textAlign: 'left', marginTop: '2px' }}>
              <AddCircleRounded
                style={{ cursor: "pointer", margin: 0 }}
                onClick={() => props.addAnotherMember('parent_members')}
              />
            </Grid>
            <Grid item xs={6} style={{ textAlign: 'right', marginTop: '2px' }}>
              <h6 style={{ textAlign: 'right' }} > Parents Premiums:  { props.currencyCode + " " +parentTotalPremiums}</h6>
            </Grid>


            {parent_members.length > 0 &&
              <Grid item xs={12} sm={12} md={12}>

                <div className={classes.dependantWrapper}>

                  <table>
                    <tr>
                      <th>Dependant Name</th>
                      <th>Reletionship</th>
                      <th>Benefits</th>
                      <th>Action</th>
                    </tr>
                    {parent_members.map((d, index) => {
                      return <tr >
                        <td>{d.first_name + " " + d.last_name}</td>
                        <td>{d.relationship}</td>
                        <td>
                          <ul>
                            <div style={{ display: 'flex' }}>
                              <div style={{ width: '80%' }}>
                                {d.benefits.map((b, i) => {
                                  return (
                                    <li key={i}>
                                      {b.type + " "}
                                      <strong>
                                        {props.currencyCode +
                                          " " +
                                          (b.cover_amount_in_cents / 100).toLocaleString()
                                          + " (" + props.currencyCode +
                                          " " + b.premium_amount / 100 + " )"
                                        }
                                      </strong>
                                    </li>
                                  );
                                })}
                              </div>
                              <div style={{ width: '20%' }}>
                                <AddCircleRounded
                                  style={{ cursor: "pointer", margin: 0 }}
                                  onClick={() => {
                                    props.setOpenAddBenefitsDialog({
                                      coverType: d.relationship,
                                      memberUUID: d.uuid,
                                      selectedPlan: props.parentsPlan ? props.parentsPlan : props.selectedPlan
                                    });
                                  }}
                                />
                              </div>

                            </div>

                          </ul>

                        </td>
                        <th><ButtonInc style={{ cursor: "pointer" }} onClick={() => { props.removeDependant(d.uuid) }} >remove member</ButtonInc></th>

                      </tr>
                    })}
                  </table>


                </div>
              </Grid>}
          </Grid>
          <br />
        </section>
      }

      {
        extended_members.length >= 0 && <section className={classes.dependantdetailstable}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <h6>Extended Members </h6>
            </Grid>
            <Grid item xs={3} style={{ textAlign: 'left', marginTop: '2px' }}>
              <AddCircleRounded
                style={{ cursor: "pointer", margin: 0 }}
                onClick={() => props.addAnotherMember('extended_members')}
              />
            </Grid>
            <Grid item xs={6} style={{ textAlign: 'right', marginTop: '2px' }}>
              <h6 style={{ textAlign: 'right' }} > Extended Members Premiums:  { props.currencyCode + " " +extendedTotalPremiums}</h6>
            </Grid>
            <Grid item xs={12} style={{ textAlign: 'left', marginTop: '2px' }}>

              {extended_members.length > 0 &&
                <div className={classes.dependantWrapper}>

                  <table>
                    <tr>
                      <th>Dependant Name</th>
                      <th>Reletionship</th>
                      <th>Benefits</th>
                      <th>Action</th>
                    </tr>
                    {extended_members.map((d, index) => {
                      return <tr >
                        <td>{d.first_name + " " + d.last_name}</td>
                        <td>{d.relationship}</td>
                        <td>
                          <ul>
                            <div style={{ display: 'flex' }}>
                              <div style={{ width: '80%' }}>
                                {d.benefits.map((b, i) => {
                                  return (
                                    <li key={i}>
                                      {b.type + " "}
                                      <strong>
                                        {props.currencyCode +
                                          " " +
                                          (b.cover_amount_in_cents / 100).toLocaleString()
                                          + " (" + props.currencyCode +
                                          " " + b.premium_amount / 100 + " )"
                                        }
                                      </strong>
                                    </li>
                                  );
                                })}
                              </div>

                            </div>

                          </ul>

                        </td>
                        <th><ButtonInc style={{ cursor: "pointer" }} onClick={() => { props.removeDependant(d.uuid) }} >remove member</ButtonInc></th>

                      </tr>
                    })}
                  </table>


                </div>}
            </Grid>
          </Grid>
          <br />
          {<p style={{ textAlign: 'right' }} > Total Premiums: {props.currencyCode + " " } { parentTotalPremiums + extendedTotalPremiums + nuclearTotalPremiums}</p>}
        </section>
      }

    </div>
  )
}
