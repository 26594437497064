import React from 'react'
import { Box, Grid, Button } from '@mui/material';
import { hashHistory } from 'react-router';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import { useStyle } from "./Style";
import { getPartner } from '../../../../../../../lib/access.es6';
import { formatDateLocale } from '../../../../../../../lib/utils.es6';
import SkeletonWrapper from '../../../../../../../containers/NewProductList/components/SkeletonWrapper/SkeletonWrapper';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';

const Policies = (props) => {

  const [expanded, setExpanded] = React.useState(false);


  const dataForDisplay = expanded ? props.policies : props.policies.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)).slice(0, 2)

  const classes = useStyle();
  const currencyCode = getPartner().country.currency_code

  const viewPolicy = (policy_number) => {
    hashHistory.push(`admin/policy-processing/${policy_number}`);
  };

  if (props.loader) {
    return <SkeletonWrapper loading={props.loader} />
  }

  if (dataForDisplay.length > 0) {

    return (
      <>
        {
          dataForDisplay.map((policy, index) => (

            <Grid container className={classes.policyWrapper} key={index}>
              <Grid item xs={12} sm={12} md={12}>
                <Grid container className={classes.policyHeader}>
                  <Grid item xs={12} sm={6} md={6}>
                    <Grid container>
                      <Grid item xs={12} md={2} sm={2} className={classes.policyIconWrapper}   >
                        <Box className={classes.policyIcons}>
                          <NoteAddOutlinedIcon fontSize="14px" sx={{ color: "#ffffff" }} />
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={10} sm={10} className={classes.policyHeaderContent}>
                        <h6>{policy.policy_number || "N/A"}</h6>
                        <p> Policy Number</p>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} >

                    <Grid container>
                      <Grid item xs={12} md={4} sm={4}>
                        <div >

                        </div>
                      </Grid>
                      <Grid item xs={12} md={8} sm={8} className={classes.policyActiveStatusWrapper}>
                        <Box display="flex" justifyContent="flex-end">
                          <ul>
                            <li className={policy.active === true ? classes.activePill : classes.inactivePill}> <span>{policy.active === true ? `Active` : `Inactive`}</span></li>


                            <li onClick={() => viewPolicy(policy.policy_number)}>View Details <span><ChevronRightOutlinedIcon /></span></li>
                          </ul>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                  <div className={classes.policyHeaderBorder}></div>
                </Grid>

                <Grid container spacing={2} className={classes.policyContentWrapper}>
                  <Grid item xs={12} sm={3} md={3} >
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={3} md={3}></Grid>
                      <Grid item xs={12} sm={9} md={9}>
                        <p>Plan </p>
                        <h6> {policy.product_name || "N/A"}</h6>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3}>
                    <p> Start Date</p>
                    <h6>{formatDateLocale(policy.start_date) || "N/A"}
                    </h6>

                  </Grid>
                  <Grid item xs={12} sm={3} md={3}>
                    <p>Due Date</p>
                    <h6>{formatDateLocale(policy.due_date) || "N/A"}</h6>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3}>
                    <p>Premiums</p>
                    <h6>{
                      (policy.quotation && policy.quotation.total_premium_amount_in_cents)
                        ? `${currencyCode}  ${policy.quotation.total_premium_amount_in_cents / 100} per ${policy.quotation.premium_cardinality} ${policy.quotation.premium_granularity}` || "0.00"

                        : "N/A"
                    }</h6>
                  </Grid>

                </Grid>
                <div className={classes.policyContentBorder}></div>

                <Grid container spacing={2} className={classes.policyFooterWrapper}>
                  <Grid item xs={12} md={6} sm={6}>
                    <Grid container spacing={2} >


                      <Grid item xs={12} md={4} sm={4}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={3} md={3}></Grid>
                          <Grid item xs={12} sm={9} md={9}>
                            <p>Beneficiary Name</p>
                            <h6>{policy.beneficiary_name || "N/A"}</h6>

                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={4} sm={4}>
                        <p> Beneficiary Phone Number</p>
                        <h6>{policy.beneficiary_msisdn || "N/A"}</h6>
                      </Grid>

                      <Grid item xs={12} md={4} sm={4}>
                        <p> Created At</p>
                        <h6>{formatDateLocale(policy.created_at) || "N/A"}</h6>
                      </Grid>

                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={6} sm={6}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={6}>

                      </Grid>
                      <Grid container xs={12} sm={6} md={6}>
                        {/* <Grid item xs={12} sm={4} md={4}>
                          <div className={classes.policyBalance}>
                            <p style={{
                              textAlign: "center"
                            }}>Branch</p>
                            <h6 style={{
                              textAlign: "center"
                            }}>{policy.additional_details.branch || "N/A"}</h6>
                          </div>
                        </Grid> */}
                        <Grid item xs={12} sm={6} md={6}>
                          <div className={classes.policyBalance}>
                            <p style={{
                              textAlign: "center"
                            }}>status</p>
                            <h6 style={{
                              textAlign: "center"
                            }}>{policy.status || "N/A"}</h6>
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <div className={classes.policyBalance}>
                            <p >Balance</p>
                            <h6>{`${currencyCode}  ${policy.balance_amount_in_cents / 100}` || "0.00"}    </h6>
                          </div>
                        </Grid>

                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ))
        }
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            {
              dataForDisplay.length > 1 && (
                <Button style={{
                  background: "#FBA92D",
                  borderRadius: "8px",
                  fontFamily: "Open Sans",
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "12px",
                  lineHeight: "15px",
                  boxShadow: "none",
                  padding: "10px",
                  marginTop: "20px",
                  textTransform: "capitalize",
                }} variant="contained" size="large" endIcon={expanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />} onClick={() => setExpanded(!expanded)}>
                  {expanded ? `Show Less` : `Show More`}
                </Button>
              )
            }


          </Grid>
        </Grid>
      </>
    )


  } else {
    return <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} className={classes.claimwrapper}>
        <Grid container spacing={2} className={classes.claimdetailswrapper}>
          <Grid item xs={12} sm={12} md={12} className={classes.ClaimDetailscontent}>
            <p style={{ textAlign: 'center', marginTop: "30px" }}>No details found</p>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  }


}

export default Policies