
import React, { useState } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './SelectPlan.css'
import { white } from 'material-ui/styles/colors';

export default function SelectPlan({ setMonthlyPremium,setPlanName, currencyCode, plans, hasParentsPlan, setPlan, setParentsPlan }) {

  console.log("plans111", plans)
  const [selected, setSelected] = useState(null);

  const handleSelect = (value) => {
    setSelected(value);
    handleSelect2(value)
    setMonthlyPremium(value.monthly_premium)
    setPlanName(value.name)
    console.log("value1", value)
  }

  const handleSelect2 = (plan) => {
    if (hasParentsPlan === true) {
      setParentsPlan(plan);
    } else {
      setPlan(plan);
    }
  };

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 2
  };





  return (
    <div style={{ background: 'light grey', padding: '2%' }}>
      <Slider {...settings}>
        {plans.map((plan, index) => (
          <div key={index}>
            <div
              onClick={() => handleSelect(plan)}
              style={{
                color: (selected && selected.name === plan.name) ? white : '#bdbdbd',
                fontSize: 20,
                borderRadius: '10%',
                transition: 'all 0.3s ease-in-out',
                cursor: 'pointer',
                background: (selected && selected.name === plan.name) ? 'orange' : '#032a37',
                margin: '3%',
                textAlign: 'center'
              }}
            >
              <div style={{ textTransform: 'uppercase' }}><p style={{
                fontFamily: "Open Sans",
                fontStyle: "normal",
                fontWeight: 600,
                fontSize: "14px",
                lineHeight: "16px",
                letterSpacing: "0.005em",
                color: "#fff",
                marginTop: "20px",
                padding: "10px"
              }}>{plan.name}</p></div>
              <hr style={{ margin: '0px' }} />
              <div><p style={{
                fontFamily: "Open Sans",
                fontStyle: "normal",
                fontWeight: 600,
                fontSize: "14px",
                lineHeight: "16px",
                letterSpacing: "0.005em",
                color: "#fff",
                marginTop: "20px"
              }}>Monthly premium: {currencyCode} {plan.monthly_premium}</p></div>
              <div><p style={{
                fontFamily: "Open Sans",
                fontStyle: "normal",
                fontWeight: 600,
                fontSize: "14px",
                lineHeight: "16px",
                letterSpacing: "0.005em",
                color: "#fff",
                marginTop: "20px",
                paddingBottom: "10px"
              }}>Members Allowed: {plan.max_allowed}</p></div>
            </div>
            <hr />
          </div>
        ))}
      </Slider>
    </div>
  )
}
