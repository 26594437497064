import React from 'react'
import Benefits from './Components/Benefits/Benefits'
import PremiumRates from './Components/PremiumRates/PremiumRates'

const EunifinGhanaConfigs = (props) => {
  return (
    <>
       <Benefits
        membership_configs={props.membership_configs}
        productGuid={props.productGuid}
      />
      <br />
      <PremiumRates 
        membership_configs={props.membership_configs}
        productGuid={props.productGuid}
      />
      <br />
    
    </>
  )
}

export default EunifinGhanaConfigs