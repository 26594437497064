import React, { useState } from 'react'
import { useStyle } from "./Style";
import Grid from "@material-ui/core/Grid";
import { SelectValidator, ValidatorForm } from 'react-material-ui-form-validator';
import MenuItem from '@mui/material/MenuItem';

import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


function getStyles(name, selectBenefit, theme) {
  return {
    fontWeight:
    selectBenefit.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}


const SelectMember = (props) => {
  if (!props.eventQuote) {
    return
  }

  const classes = useStyle();
  const theme = useTheme();
  const [selectMember, setSelectMember] = useState('');
  const [selectBenefit, setSelectBenefit] = useState([]);
  //const [benefitName, setBenefitName] = useState([]);



  const handleChange = (event) => {
    const { name, value } = event.target

    if (name === "selectmember") {
      setSelectMember(value)
      console.log("member details", value)
    }
    // if (name === "selectbenefit") {
    //   setSelectBenefit(value)
    //   handleMember(value)
    // }
  }


  const handleMember = (benefits) => {
    const tempClaimInitiation = props.claimInitiationPayload;
    tempClaimInitiation["benefits"] = 
      benefits.map((benefit) => {return {
        type: benefit
      }} )
    console.log("temp claim init",{tempClaimInitiation})
    tempClaimInitiation["dependant_guid"] = selectMember.dependant.guid;
    tempClaimInitiation["member"] = selectMember
    props.setClaimInitiationPayload(tempClaimInitiation);
  }


  const handleBenefitChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectBenefit(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
    handleMember(value)
  };
  const members = props.eventQuote.policy_schedule_entries ? props.eventQuote.policy_schedule_entries : []

  return (
    <>
      <ValidatorForm
        onSubmit={() => { }}
      >
        <Grid container spacing={2} className={classes.selectpolicyWrapper}>
          <Grid item xs={12} md={6} sm={6}>
            <SelectValidator
              labelId="select-member"
              id="select-member"
              value={selectMember}
              name='selectmember'
              type="text"
              label="Select Member"
              onChange={handleChange}
              variant="outlined"
              validators={['required']}
              errorMessages={['Please select a member']}
              style={{ width: "100%" }}
              sx={{
                fontFamily: "Open Sans",
                fontStyle: "normal",
                fontWeight: 300,
                fontSize: "12px",
                lineHeight: "16px",
                letterSpacing: "0.005em",
                color: "#000000",
              }}
            >

              {
                members.map((entry, index) => (
                  <MenuItem
                    key={index}
                    sx={{
                      fontFamily: "Open Sans",
                      fontStyle: "normal",
                      fontWeight: 300,
                      fontSize: "12px",
                      lineHeight: "16px",
                      letterSpacing: "0.005em",
                      color: "#000000",
                    }}
                    value={entry}>{entry.dependant.first_name + " " + entry.dependant.last_name}</MenuItem>
                ))
              }

            </SelectValidator>
          </Grid>
          <Grid item xs={12} md={6} sm={6}>

          <FormControl sx={{ m: 1, width: "100%" }}>
              <InputLabel id="demo-multiple-chip-label">Select Benefits</InputLabel>
              <Select
                labelId="demo-multiple-chip-label"
                id="demo-multiple-chip"
                multiple
                value={selectBenefit}
                onChange={handleBenefitChange}
                input={<OutlinedInput id="select-multiple-chip" label="Select Benefits" />}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {
                
                (selectMember ? selectMember.benefits : []).map((benefit, index) => {
                  return <MenuItem
                    key={index}
                    style={getStyles(benefit, selectBenefit, theme)}
                    sx={{
                      fontFamily: "Open Sans",
                      fontStyle: "normal",
                      fontWeight: 300,
                      fontSize: "10px",
                      lineHeight: "16px",
                      letterSpacing: "0.005em",
                      color: "#000000",
                    }}
                     value={benefit.type}
                     >
                      {benefit.type + "-" + (benefit.cover_amount_in_cents/100).toLocaleString()}
                      </MenuItem>

                })
                }
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </ValidatorForm>
    </>
  )
}

export default SelectMember