import React, { useState } from 'react';
import { 
  Box, 
  Typography, 
  Grid, 
  Card, 
  CardContent, 
  CardActions,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  useTheme,
  useMediaQuery
} from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { makeStyles } from "@material-ui/styles";
import { productData } from '../../../helpers/product';
import { QUOTE_COLORS } from '../constants';
import ButtonInc from '../../../../../../shared-ui/ButtonInc';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '1rem',
    '@media (min-width: 600px)': {
      padding: '2rem',
    }
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    transition: 'all 0.2s ease-in-out',
    cursor: 'pointer',
    border: 'none',
    '&:hover': {
      transform: 'translateY(-5px)',
      boxShadow: `0 8px 24px ${QUOTE_COLORS.SHADOW.HOVER}`,
    },
  },
  selectedCard: {
    boxShadow: `0 4px 2px ${QUOTE_COLORS.SHADOW.CARD}`,
    '& .MuiCardContent-root': {
      '& .cardHeader': {
        background: QUOTE_COLORS.SECONDARY,
      }
    }
  },
  cardContent: {
    flexGrow: 1,
    padding: 0,
  },
  cardHeader: {
    padding: '1.5rem 1.5rem 3rem',
    textAlign: 'center',
    transition: 'all 0.2s ease-in-out',
    background: QUOTE_COLORS.PRIMARY,
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    position: 'relative',
  },
  packageTitle: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '24px',
    letterSpacing: '0.0015em',
    color: QUOTE_COLORS.TEXT.LIGHT,
    marginBottom: '0',
    position: 'relative',
    zIndex: 2,
    textTransform: 'uppercase',
  },
  premiumContainer: {
    background: QUOTE_COLORS.TEXT.LIGHT,
    padding: '1rem',
    margin: '0 1.5rem',
    borderRadius: '8px',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    transform: 'translateY(50%)',
    zIndex: 1,
  },
  premium: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '32px',
    lineHeight: '40px',
    letterSpacing: '0.005em',
    color: QUOTE_COLORS.SECONDARY,
    marginBottom: '0.25rem',
  },
  frequency: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    color: QUOTE_COLORS.TEXT.SECONDARY,
  },
  benefitIcon: {
    color: QUOTE_COLORS.SECONDARY,
  },
  benefitText: {
    '& .MuiTypography-root': {
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '20px',
    },
  },
  benefitTitle: {
    color: QUOTE_COLORS.PRIMARY,
    marginBottom: '4px',
  },
  benefitAmount: {
    color: QUOTE_COLORS.SECONDARY,
    fontWeight: 600,
  },
  benefitMembers: {
    color: QUOTE_COLORS.TEXT.SECONDARY,
    fontSize: '12px',
    fontStyle: 'italic',
  },
  cardActions: {
    padding: '1rem',
    '& > div': {
      width: '100%',
      '& button': {
        width: '100%',
      }
    }
  },
  pageTitle: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '24px',
    lineHeight: '32px',
    letterSpacing: '0.0015em',
    color: QUOTE_COLORS.PRIMARY,
    marginBottom: '1rem',
  },
  pageSubtitle: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    color: QUOTE_COLORS.TEXT.SECONDARY,
    marginBottom: '2rem',
  },
  benefitsContainer: {
    padding: '2.5rem 1.5rem 1.5rem',
  },
  benefitsList: {
    '& .MuiListItem-root': {
      padding: '0.75rem 0',
      '&:first-child': {
        paddingTop: 0,
      },
      '&:last-child': {
        paddingBottom: 0,
      }
    }
  },
  mobilePackageCard: {
    padding: '1.5rem',
    border: `1px solid ${QUOTE_COLORS.PRIMARY}`,
    borderRadius: '8px',
    cursor: 'pointer',
    transition: 'all 0.2s ease',
    backgroundColor: '#fff',
    '&:hover': {
      transform: 'translateY(-2px)',
      boxShadow: '0 4px 12px rgba(0,0,0,0.1)'
    }
  },
  selectedMobileCard: {
    backgroundColor: QUOTE_COLORS.PRIMARY,
    color: QUOTE_COLORS.TEXT.LIGHT,
    '& $mobilePackageName, & $mobilePremium, & $mobileCoverAmount': {
      color: QUOTE_COLORS.TEXT.LIGHT
    }
  },
  mobilePackageName: {
    fontSize: '1.25rem',
    fontWeight: 600,
    color: QUOTE_COLORS.PRIMARY,
    marginBottom: '0.5rem'
  },
  mobilePremium: {
    fontSize: '1.5rem',
    fontWeight: 700,
    color: QUOTE_COLORS.PRIMARY,
    display: 'flex',
    alignItems: 'baseline',
    gap: '0.25rem',
    marginBottom: '0.5rem'
  },
  mobileFrequency: {
    fontSize: '0.875rem',
    opacity: 0.75
  },
  mobileCoverAmount: {
    color: QUOTE_COLORS.SECONDARY,
    fontSize: '0.875rem'
  }
}));

const SelectCover = ({ onSelect, onNext }) => {
  const classes = useStyles();
  const [selectedPackage, setSelectedPackage] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  // Calculate grid size based on number of packages
  const getGridSize = (totalPackages) => {
    if (totalPackages <= 3) return 4; // md={4} for 3 or fewer packages
    if (totalPackages === 4) return 3; // md={3} for 4 packages
    return 2; // md={2} for 5 or more packages
  };

  const gridSize = getGridSize(productData.membership_configs.content.packages.length);

  const handlePackageSelect = (packageData) => {
    setSelectedPackage(packageData);
    onSelect(packageData);
  };

  const formatCurrency = (amount) => {
    // Format for Ugandan Shillings
    return `UGX ${amount.toLocaleString()}`; // This will format like "UGX 310,000"
  };

  const getBenefitsForPackage = (packageIndex) => {
    return productData.membership_configs.content.benefits.map(benefit => ({
      memberType: benefit.member_type,
      amount: benefit[`package_${packageIndex + 1}_amount`],
      maxMembers: benefit.max_members
    }));
  };

  const formatBenefitTitle = (memberType) => {
    return `${memberType.replace('_', ' ').split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')} Cover`;
  };

  const formatMemberLimit = (memberType, maxMembers) => {
    if (maxMembers <= 1) return null;
    const type = memberType.toLowerCase();
    return `Up to ${maxMembers} ${type}`;
  };

  const renderPackageCard = (packageData, index) => {
    const isSelected = selectedPackage && selectedPackage.package_id === packageData.package_id;
    const benefits = getBenefitsForPackage(index);

    return (
      <Card 
        className={`${classes.card} ${isSelected ? classes.selectedCard : ''}`}
        elevation={isSelected ? 4 : 1}
        onClick={() => handlePackageSelect(packageData)}
      >
        <CardContent className={classes.cardContent}>
          <div className={`cardHeader ${classes.cardHeader}`}>
            <Typography className={classes.packageTitle}>
              {packageData.package_name}
            </Typography>
            <div className={classes.premiumContainer}>
              <Typography className={classes.premium}>
                {formatCurrency(packageData.premium_amount)}
              </Typography>
              <Typography className={classes.frequency}>
                annually
              </Typography>
            </div>
          </div>
          
          <div className={classes.benefitsContainer}>
            <List className={classes.benefitsList}>
              {benefits.map((benefit, idx) => (
                <ListItem key={idx} dense>
                  <ListItemIcon className={classes.benefitIcon}>
                    <CheckCircleOutlineIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText 
                    className={classes.benefitText}
                    primary={
                      <Box>
                        <Typography className={classes.benefitTitle}>
                          {formatBenefitTitle(benefit.memberType)}
                        </Typography>
                        <Typography className={classes.benefitAmount}>
                          {formatCurrency(benefit.amount)}
                        </Typography>
                        {benefit.maxMembers > 1 && (
                          <Typography className={classes.benefitMembers}>
                            {formatMemberLimit(benefit.memberType, benefit.maxMembers)}
                          </Typography>
                        )}
                      </Box>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </div>
        </CardContent>
        <CardActions className={classes.cardActions}>
          <ButtonInc
            btnType={isSelected ? 'primary' : 'secondary'}
          >
            {isSelected ? 'Selected' : 'Select Package'}
          </ButtonInc>
        </CardActions>
      </Card>
    );
  };

  const renderMobilePackageCard = (pkg, index) => {
    const isSelected = selectedPackage && selectedPackage.package_id === pkg.package_id;
    const mainMemberBenefit = getBenefitsForPackage(index).find(b => b.memberType === 'main_member');

    return (
      <Box
        className={`${classes.mobilePackageCard} ${isSelected ? classes.selectedMobileCard : ''}`}
        onClick={() => handlePackageSelect(pkg)}
      >
        <Typography className={classes.mobilePackageName}>
          {pkg.package_name}
        </Typography>
        <Typography className={classes.mobilePremium}>
          <span>UGX {pkg.premium_amount.toLocaleString()}</span>
          <span className={classes.mobileFrequency}>
            /{pkg.premium_frequency.toLowerCase()}
          </span>
        </Typography>
        <Typography className={classes.mobileCoverAmount}>
          Main Member Cover: UGX {mainMemberBenefit.amount.toLocaleString()}
        </Typography>
      </Box>
    );
  };

  return (
    <Box className={classes.root}>
      <Typography className={classes.pageTitle} align="center">
        Select Your Cover Package
      </Typography>
      <Typography className={classes.pageSubtitle} align="center">
        Choose the package that best suits your needs
      </Typography>

      {isMobile ? (
        // Mobile view with simplified cards
        <Grid container spacing={2}>
          {productData.membership_configs.content.packages.map((pkg, index) => (
            <Grid item xs={12} key={pkg.package_id}>
              {renderMobilePackageCard(pkg, index)}
            </Grid>
          ))}
        </Grid>
      ) : (
        // Desktop view with full pricing table
        <Grid container spacing={3}>
          {productData.membership_configs.content.packages.map((pkg, index) => (
            <Grid item xs={12} sm={6} md={gridSize} key={pkg.package_id}>
              {renderPackageCard(pkg, index)}
            </Grid>
          ))}
        </Grid>
      )}

      <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
        <ButtonInc
          onClick={onNext}
          disabled={!selectedPackage}
          style={{ width: isMobile ? '100%' : 'auto' }}
        >
          Continue with {selectedPackage ? selectedPackage.package_name : 'Selected Package'}
        </ButtonInc>
      </Box>
    </Box>
  );
};

export default SelectCover; 