import React, { useState } from 'react';
import { Paper, Typography } from '@mui/material';
import TableInc from '../../../../../shared-ui/TableInc';
import { updateExtendedConfig } from '../../../../../lib/requests.es6';

const BenefitsPostbankTable = ({ membership_configs, productGuid, refreshProduct }) => {
  const getInitialBenefits = () => {
    if (membership_configs && membership_configs.content && membership_configs.content.benefits) {
      return membership_configs.content.benefits;
    }
    return [];
  };

  const [benefits, setBenefits] = useState(getInitialBenefits());

  const handleRowUpdate = async (newData, oldData) => {
    const updatedBenefits = benefits.map(benefit => 
      benefit.benefit_id === oldData.benefit_id ? newData : benefit
    );

    const payload = {
      product_guid: productGuid,
      ...membership_configs,
      content: {
        ...membership_configs.content,
        benefits: updatedBenefits
      }
    };

    try {
      await updateExtendedConfig(payload);
      setBenefits(updatedBenefits);
      refreshProduct(productGuid);
      return true;
    } catch (error) {
      console.error('Error updating benefit:', error);
      return false;
    }
  };

  const handleRowAdd = async (newData) => {
    const newBenefit = {
      ...newData,
      benefit_id: Math.random().toString(36).substr(2, 9),
      currency: 'UGX'
    };

    const newBenefits = [...benefits, newBenefit];

    const payload = {
      product_guid: productGuid,
      ...membership_configs,
      content: {
        ...membership_configs.content,
        benefits: newBenefits
      }
    };

    try {
      await updateExtendedConfig(payload);
      setBenefits(newBenefits);
      refreshProduct(productGuid);
      return true;
    } catch (error) {
      console.error('Error adding benefit:', error);
      return false;
    }
  };

  const handleRowDelete = async (oldData) => {
    const newBenefits = benefits.filter(benefit => benefit.benefit_id !== oldData.benefit_id);

    const payload = {
      product_guid: productGuid,
      ...membership_configs,
      content: {
        ...membership_configs.content,
        benefits: newBenefits
      }
    };

    try {
      await updateExtendedConfig(payload);
      setBenefits(newBenefits);
      refreshProduct(productGuid);
      return true;
    } catch (error) {
      console.error('Error deleting benefit:', error);
      return false;
    }
  };

  return (
    
    <Paper sx={{ p: 3 }}>
      <Typography variant="h6" sx={{ mb: 2 }}>
        Benefits Configuration
      </Typography>

      <TableInc
        title=""
        columns={[
          { 
            title: 'Member Type', 
            field: 'member_type',
            validate: rowData => rowData.member_type ? '' : 'Member type is required',
            lookup: {
              'main_member': 'Main Member',
              'spouse': 'Spouse',
              'children': 'Children',
              'parents': 'Parents & Parents in-law'
            }
          },
          { 
            title: 'Package 1 Amount', 
            field: 'package_1_amount',
            type: 'numeric',
            validate: rowData => rowData.package_1_amount ? '' : 'Amount is required',
            render: rowData => {
              if (rowData && rowData.currency && rowData.package_1_amount) {
                return `${rowData.currency} ${rowData.package_1_amount}`;
              }
              return '';
            }
          },
          { 
            title: 'Package 2 Amount', 
            field: 'package_2_amount',
            type: 'numeric',
            validate: rowData => rowData.package_2_amount ? '' : 'Amount is required',
            render: rowData => {
              if (rowData && rowData.currency && rowData.package_2_amount) {
                return `${rowData.currency} ${rowData.package_2_amount}`;
              }
              return '';
            }
          },
          { 
            title: 'Package 3 Amount', 
            field: 'package_3_amount',
            type: 'numeric',
            validate: rowData => rowData.package_3_amount ? '' : 'Amount is required',
            render: rowData => {
              if (rowData && rowData.currency && rowData.package_3_amount) {
                return `${rowData.currency} ${rowData.package_3_amount}`;
              }
              return '';
            }
          },
          {
            title: 'Max Members',
            field: 'max_members',
            type: 'numeric',
            initialEditValue: 1,
            validate: rowData => {
              if (rowData.member_type === 'children' && (!rowData.max_members || rowData.max_members > 4)) {
                return 'Children max members must be between 1 and 4';
              }
              if (rowData.member_type === 'parents' && (!rowData.max_members || rowData.max_members > 4)) {
                return 'Parents max members must be between 1 and 4';
              }
              return '';
            }
          }
        ]}
        data={benefits}
        options={{
          search: true,
          paging: true,
          pageSize: 5,
          actionsColumnIndex: -1
        }}
        editable={{
          onRowAdd: handleRowAdd,
          onRowUpdate: handleRowUpdate,
          onRowDelete: handleRowDelete
        }}
      />
    </Paper>
  );
};

export default BenefitsPostbankTable; 