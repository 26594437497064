import React from 'react'
import AddHospitalClaimExpense from './AddHospitalClaimExpense'
import AddDeathClaimExpense from './AddDeathClaimExpense';
import AddDisabilityClaimExpense from './AddDisabilityClaimExpense'
import AddCriticalIllnessClaimExpense from './AddCriticalIllnessClaimExpense'

const AddClaimExpense = (props) => {

  switch (true) {
    case props.hasDeathBenefit:
      return <AddDeathClaimExpense {...props} />;
    case props.hasHospitalBenefit:
      return <AddHospitalClaimExpense {...props} />;

      case props.hasCriticalIllnessBenefit:
        return <AddCriticalIllnessClaimExpense {...props} />;

    case props.hasDisabilityBenefit:
    case props.hasTemporaryDisabilityBenefit:
      return <AddDisabilityClaimExpense {...props} />;

    default:
      return <div>No applicable benefit type found</div>;
  }


}

export default AddClaimExpense