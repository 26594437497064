import React from 'react'
import TableInc from '../../../../../shared-ui/TableInc';
import { updateExtendedConfig } from "../../../../../lib/requests.es6";

const LivesCovered = (props) => {
  if (props.lives_covered) {


    const addBenefits = (benefits, index, newPlan) => {
      const currentBenefits = benefits[index]
      currentBenefits.benefits.push(newPlan)
      benefits[index] = currentBenefits
      return benefits
    }

    const deleteBenefit = (benefits, parentIndex, oldData) => {
      const itemIndexToDelete = oldData.tableData.id
      const currentPayout = benefits[parentIndex]
      
      let benefit = currentPayout.benefits
      benefit.splice(itemIndexToDelete, 1)
      currentPayout['benefits'] = benefit

      benefits[parentIndex] = currentPayout
      return benefits
    }
    
    const deleteBenefitPlans = (benefit, oldData) => {
      const itemIndexToDelete = oldData.tableData.id
      benefit.splice(itemIndexToDelete, 1)
      console.log({
        benefit, oldData
      });
      return benefit
    }


    return (
      <>
        <div style={{ marginTop: "2%" }}>
          {


            <TableInc
              title="Lives Covered"
              columns={[
                { title: 'Name', field: 'name'},
                { title: 'Monthly Premium', field: 'monthly_premium',  type: 'numeric'},
                { title: 'Max Allowed', field: 'max_allowed',  type: 'numeric'},
              ]}
              data={
                props.lives_covered
              }
              options={{
                showTitle: true,
                pageSize: props.lives_covered.length,
                actionsColumnIndex: -1,
                addRowPosition: 'first',
              }}
              editable={{
                onBulkUpdate: changes =>
                  new Promise((resolve, reject) => {
                    console.log('you are doing this bulk changes: ', changes)
                    resolve();
                  }),

                onRowAdd: newData =>
                  new Promise((resolve, reject) => {
                    console.log("index", 0)
                    const membershipConfig = {
                      "product_guid": props.productGuid,
                      ...props.extendedConfig,
                      "content": {
                        ...props.extendedConfig.content,
                        lives_covered: [...props.extendedConfig.content.lives_covered, {name: newData.name, monthly_premium: newData.monthly_premium, max_allowed: newData.max_allowed, benefits: []} ]
                      }
                    }
                    console.log("membershipConfig", membershipConfig)
                    updateExtendedConfig(membershipConfig).then(
                      (response) => {
                        console.log("here is response", response);
                        resolve();
                        window.location.reload(true);
                      }
                    ).catch(
                      (error) => {
                        console.log("did not work", error)
                        reject();
                      }
                    )


                  }
                ),
                  onRowDelete: oldData =>
                  new Promise((resolve, reject) => {
                    console.log("index", 1)
                    const membershipConfig = {
                      "product_guid": props.productGuid,
                      ...props.extendedConfig,
                      "content": {
                        ...props.extendedConfig.content,
                        lives_covered: deleteBenefitPlans(props.lives_covered, oldData)
                      }
                    }
                    updateExtendedConfig(membershipConfig).then(
                      (response) => {
                        console.log("here is response", response);
                        resolve();
                        window.location.reload(true);
                      }
                    ).catch(
                      (error) => {
                        console.log("did not work", error)
                        reject();
                      }
                    )


                  }),
              }}
              detailPanel={rowData => {
                return (
                  <div style={{ width: '96%', margin: "auto", zIndex: '999999' }}>
                    {
                      <TableInc
                       title="Benefits"
                        columns={[
                          { title: 'Name', field: 'name', },
                          { title: 'Type', field: 'type'  },
                          { title: 'Quantity', field: 'quantity' , type: 'numeric' },
                          { title: 'Cover Usage Count', field: 'cover_usage_count' , type: 'numeric' },
                          { title: 'Min', field: 'min' , type: 'numeric' },
                          { title: 'Max', field: 'max', type: 'numeric' },
                        ]}
                        data={
                          rowData.benefits
                        }
                        options={{
                          pageSize: rowData.benefits.length,
                          showTitle: true,
                          actionsColumnIndex: -1,
                          addRowPosition: 'first',
                        }}

                        editable={{
                          onRowAdd: newData =>
                            new Promise((resolve, reject) => {
                              console.log("index", 1)
                              const membershipConfig = {
                                "product_guid": props.productGuid,
                                ...props.extendedConfig,
                                "content": {
                                  ...props.extendedConfig.content,
                                  lives_covered: addBenefits(props.lives_covered, rowData.tableData.id, newData)
                                }
                              }
                              console.log("membershipConfig", membershipConfig)
                              updateExtendedConfig(membershipConfig).then(
                                (response) => {
                                  console.log("here is response", response);
                                  resolve();
                                  window.location.reload(true);
                                }
                              ).catch(
                                (error) => {
                                  console.log("did not work", error)
                                  reject();
                                }
                              )


                            }),
                          onRowDelete: oldData =>
                            new Promise((resolve, reject) => {
                              console.log("index", 1)
                              const membershipConfig = {
                                "product_guid": props.productGuid,
                                ...props.extendedConfig,
                                "content": {
                                  ...props.extendedConfig.content,
                                  lives_covered: deleteBenefit(props.lives_covered, rowData.tableData.id, oldData)
                                }
                              }
                              updateExtendedConfig(membershipConfig).then(
                                (response) => {
                                  console.log("here is response", response);
                                  resolve();
                                  window.location.reload(true);
                                }
                              ).catch(
                                (error) => {
                                  console.log("did not work", error)
                                  reject();
                                }
                              )


                            }),
                        }}

                      />
                    }
                  </div>
                )
              }}

            />

          }

        </div>
      </>
    );
  }
  return "No configs found, click on the add button to add new configs."

}

export default LivesCovered