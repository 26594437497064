import React, { useState } from 'react';
import { 
  Box, 
  Typography,
  Paper,
  FormControlLabel,
  Checkbox,
  Divider
} from '@mui/material';
import { makeStyles } from "@material-ui/styles";
import { QUOTE_COLORS } from '../constants';
import ButtonInc from '../../../../../../shared-ui/ButtonInc';

const useStyles = makeStyles(() => ({
  root: {
    padding: '1.5rem',
    height: 'calc(100vh - 200px)', // Account for header and padding
    display: 'flex',
    flexDirection: 'column'
  },
  title: {
    color: QUOTE_COLORS.PRIMARY,
    marginBottom: '1rem'
  },
  subtitle: {
    color: QUOTE_COLORS.TEXT.SECONDARY,
    marginBottom: '2rem',
    fontSize: '0.875rem'
  },
  termsCard: {
    padding: '2rem',
    marginBottom: '1.5rem',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden' // Prevent card from scrolling
  },
  termsContent: {
    flex: 1,
    overflowY: 'auto',
    paddingRight: '1rem' // Add padding for scrollbar
  },
  termsSection: {
    '& h6': {
      color: QUOTE_COLORS.PRIMARY,
      marginBottom: '1rem',
      fontSize: '1.1rem',
      fontWeight: 600,
      position: 'sticky',
      top: 0,
      backgroundColor: '#fff',
      padding: '1rem 0',
      zIndex: 1
    },
    '& p': {
      color: QUOTE_COLORS.TEXT.SECONDARY,
      marginBottom: '1rem',
      lineHeight: 1.6
    }
  },
  scrollableContent: {
    padding: '1rem',
    backgroundColor: '#f8f9fa',
    borderRadius: '4px',
    marginBottom: '2rem'
  },
  acceptanceBox: {
    padding: '1.5rem',
    backgroundColor: '#f8f9fa',
    borderRadius: '4px',
    marginTop: 'auto' // Push to bottom of card
  },
  actions: {
    display: 'flex',
    justifyContent: 'center',
    gap: '1rem',
    marginTop: '2rem',
    position: 'sticky',
    bottom: 0,
    backgroundColor: '#fff',
    padding: '1rem 0'
  },
  divider: {
    margin: '2rem 0'
  }
}));

const TermsAndConditions = ({ quoteData, updateQuoteData, onNext, onBack }) => {
  const classes = useStyles();
  const [accepted, setAccepted] = useState(false);

  const handleAccept = () => {
    updateQuoteData('termsAccepted', true);
    onNext();
  };

  const handleDecline = () => {
    updateQuoteData('termsAccepted', false);
    // You might want to handle decline differently
    // For now, just go back
    onBack();
  };

  return (
    <Box className={classes.root}>
      <Typography variant="h6" className={classes.title}>
        Terms and Conditions
      </Typography>
      <Typography className={classes.subtitle}>
        Please review and accept the terms and conditions to proceed
      </Typography>

      <Paper className={classes.termsCard}>
        <Box className={classes.termsContent}>
          <Box className={classes.termsSection}>
            <Typography variant="h6">
              1. Policy Terms
            </Typography>
            <Box className={classes.scrollableContent}>
              <Typography>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
              </Typography>
              <Typography>
                Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
              </Typography>
              <Typography>
                At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga.
              </Typography>
            </Box>
          </Box>

          <Divider className={classes.divider} />

          <Box className={classes.termsSection}>
            <Typography variant="h6">
              2. Premium Payments
            </Typography>
            <Box className={classes.scrollableContent}>
              <Typography>
                Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.
              </Typography>
              <Typography>
                Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.
              </Typography>
              <Typography>
                Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus.
              </Typography>
            </Box>
          </Box>

          <Divider className={classes.divider} />

          <Box className={classes.termsSection}>
            <Typography variant="h6">
              3. Claims Process
            </Typography>
            <Box className={classes.scrollableContent}>
              <Typography>
                Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae. Itaque earum rerum hic tenetur a sapiente delectus.
              </Typography>
              <Typography>
                Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus.
              </Typography>
              <Typography>
                Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur.
              </Typography>
            </Box>
          </Box>

          <Divider className={classes.divider} />

          <Box className={classes.termsSection}>
            <Typography variant="h6">
              4. Policy Cancellation
            </Typography>
            <Box className={classes.scrollableContent}>
              <Typography>
                Sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam.
              </Typography>
              <Typography>
                Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.
              </Typography>
              <Typography>
                At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident.
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box className={classes.acceptanceBox}>
          <FormControlLabel
            control={
              <Checkbox
                checked={accepted}
                onChange={(e) => setAccepted(e.target.checked)}
                color="primary"
              />
            }
            label="I have read and agree to the terms and conditions"
          />
        </Box>
      </Paper>

      <Box className={classes.actions}>
        <ButtonInc 
          btnType="secondary" 
          onClick={handleDecline}
        >
          Decline
        </ButtonInc>
        <ButtonInc 
          onClick={handleAccept}
          disabled={!accepted}
        >
          Accept & Continue
        </ButtonInc>
      </Box>
    </Box>
  );
};

export default TermsAndConditions; 