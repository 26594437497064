import React, { useState, useEffect } from 'react';
import { 
  TextField, 
  Button, 
  Grid, 
  Paper,
  Typography,
  IconButton,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Snackbar,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import RefreshIcon from '@mui/icons-material/Refresh';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { v4 as uuidv4 } from 'uuid';
import { PRIMARY_THEME_COLOR_SHADE_1 } from "../../../../lib/constants";
import { updateExtendedConfig } from "../../../../lib/requests.es6";
import TableInc from "../../../../shared-ui/TableInc";

const IframeConfigs = ({ product, refreshProduct }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [openViewDialog, setOpenViewDialog] = useState(false);
  const [showCopySuccess, setShowCopySuccess] = useState(false);
  const [iframeConfigs, setIframeConfigs] = useState([]);
  const [selectedConfig, setSelectedConfig] = useState(null);
  const [currentConfig, setCurrentConfig] = useState({
    iframe_id: uuidv4(),
    partnerDomain: '',
    apiToken: uuidv4(),
    type: 'quotecreation',
    theme: 'default',
    iframeWidth: '100%',
    iframeHeight: '700px',
    iframeBorder: 'none',
    primaryColor: PRIMARY_THEME_COLOR_SHADE_1,
    secondaryColor: '#ffffff',
    productName: '',
    intro_page: {
      title: '',
      subtitle: '',
      description: '',
      features: []
    },
    outro_page: {
      title: '',
      subtitle: '',
      next_steps: [],
      contact: {
        email: '',
        phone: ''
      }
    }
  });

  useEffect(() => {
    if (product && product.membership_configs && product.membership_configs.content) {
      const configs = product.membership_configs.content.iframe_configs || [];
      setIframeConfigs([...configs]);
    }
    if (product && product.name) {
      setCurrentConfig(prev => ({
        ...prev,
        productName: product.name
      }));
    }
  }, [product]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCurrentConfig(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleIntroPageChange = (field, value) => {
    setCurrentConfig(prev => ({
      ...prev,
      intro_page: {
        ...prev.intro_page,
        [field]: value
      }
    }));
  };

  const handleOutroPageChange = (field, value) => {
    setCurrentConfig(prev => ({
      ...prev,
      outro_page: {
        ...prev.outro_page,
        [field]: value
      }
    }));
  };

  const handleContactChange = (field, value) => {
    setCurrentConfig(prev => ({
      ...prev,
      outro_page: {
        ...prev.outro_page,
        contact: {
          ...prev.outro_page.contact,
          [field]: value
        }
      }
    }));
  };

  const generateIframeCode = (config) => {
    const baseUrl = 'https://staging.inclusivity-aspin.com/embed/';
    const params = new URLSearchParams({
      apikey: config.apiToken,
      partner_domain: config.partnerDomain,
      partnerId: product && product.partner_guid ? product.partner_guid : '',
      productId: product && product.guid ? product.guid : '',
      flow: config.type,
      theme: config.theme,
      iframeId: config.iframe_id
    }).toString();

    return `<iframe 
  src="${baseUrl}?${params}"
  style="width: ${config.iframeWidth}; height: ${config.iframeHeight}; border: ${config.iframeBorder};"
  title="Insurance Quote"
></iframe>`;
  };

  const handleCopyCode = (code) => {
    navigator.clipboard.writeText(code);
    setShowCopySuccess(true);
  };

  const handleSubmit = async () => {
    if (!product || !product.membership_configs) return;
    
    const newConfigs = [...iframeConfigs, currentConfig];
    
    const payload = {
      product_guid: product.guid,
      ...product.membership_configs,
      content: {
        ...product.membership_configs.content,
        iframe_configs: newConfigs
      }
    };

    try {
      await updateExtendedConfig(payload);
      setIframeConfigs(newConfigs);
      setOpenDialog(false);
      setCurrentConfig({
        ...currentConfig,
        iframe_id: uuidv4(),
        apiToken: uuidv4()
      });
      refreshProduct(product.guid);
    } catch (error) {
      console.error('Error updating iframe configs:', error);
    }
  };

  const handleDelete = async (configToDelete) => {
    if (!product || !product.membership_configs) return;
    
    const newConfigs = iframeConfigs.filter(config => config.iframe_id !== configToDelete.iframe_id);
    
    const payload = {
      product_guid: product.guid,
      ...product.membership_configs,
      content: {
        ...product.membership_configs.content,
        iframe_configs: newConfigs
      }
    };

    try {
      await updateExtendedConfig(payload);
      setIframeConfigs(newConfigs);
      refreshProduct(product.guid);
    } catch (error) {
      console.error('Error deleting iframe config:', error);
    }
  };

  const handleViewConfig = (config) => {
    setSelectedConfig(config);
    setOpenViewDialog(true);
  };

  const handleAddFeature = () => {
    if (currentConfig.intro_page.features.length < 5) {
      setCurrentConfig(prev => ({
        ...prev,
        intro_page: {
          ...prev.intro_page,
          features: [...prev.intro_page.features, '']
        }
      }));
    }
  };

  const handleRemoveFeature = (index) => {
    setCurrentConfig(prev => ({
      ...prev,
      intro_page: {
        ...prev.intro_page,
        features: prev.intro_page.features.filter((_, i) => i !== index)
      }
    }));
  };

  const handleAddNextStep = () => {
    if (currentConfig.outro_page.next_steps.length < 3) {
      setCurrentConfig(prev => ({
        ...prev,
        outro_page: {
          ...prev.outro_page,
          next_steps: [...prev.outro_page.next_steps, '']
        }
      }));
    }
  };

  const handleRemoveNextStep = (index) => {
    setCurrentConfig(prev => ({
      ...prev,
      outro_page: {
        ...prev.outro_page,
        next_steps: prev.outro_page.next_steps.filter((_, i) => i !== index)
      }
    }));
  };

  return (
    <Paper sx={{ p: 3, mt: 3 }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
        <Typography variant="h6">
          Iframe Configurations ({iframeConfigs.length})
        </Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => setOpenDialog(true)}
        >
          Add New Iframe
        </Button>
      </div>

      {iframeConfigs.length > 0 ? (
        <TableInc
          title=""
          columns={[
            { title: 'Domain', field: 'partnerDomain' },
            { title: 'Type', field: 'type' },
            { title: 'Product Name', field: 'productName' },
            { 
              title: 'Actions', 
              render: rowData => (
                <div>
                  <IconButton onClick={() => handleViewConfig(rowData)}>
                    <VisibilityIcon />
                  </IconButton>
                  <IconButton onClick={() => handleCopyCode(generateIframeCode(rowData))}>
                    <ContentCopyIcon />
                  </IconButton>
                  <IconButton onClick={() => handleDelete(rowData)}>
                    <DeleteIcon />
                  </IconButton>
                </div>
              )
            }
          ]}
          data={iframeConfigs}
          options={{
            search: true,
            paging: true,
            pageSize: 5,
            actionsColumnIndex: -1
          }}
        />
      ) : (
        <Typography variant="body1" align="center" sx={{ py: 3 }}>
          No iframe configurations found. Click the "Add New Iframe" button to create one.
        </Typography>
      )}

      <Dialog 
        open={openDialog} 
        onClose={() => setOpenDialog(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Add New Iframe Configuration</DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Partner Domain"
                name="partnerDomain"
                value={currentConfig.partnerDomain}
                onChange={handleChange}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="API Token"
                value={currentConfig.apiToken}
                disabled
                margin="normal"
                InputProps={{
                  endAdornment: (
                    <IconButton 
                      onClick={() => setCurrentConfig(prev => ({
                        ...prev,
                        apiToken: uuidv4()
                      }))}
                      size="small"
                      sx={{ mr: -1 }}
                    >
                      <RefreshIcon />
                    </IconButton>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth margin="normal">
                <InputLabel>Type</InputLabel>
                <Select
                  name="type"
                  value={currentConfig.type}
                  onChange={handleChange}
                  label="Type"
                >
                  <MenuItem value="quotecreation">Quote Creation</MenuItem>
                  <MenuItem value="management">Management</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Iframe Width"
                name="iframeWidth"
                value={currentConfig.iframeWidth}
                onChange={handleChange}
                margin="normal"
                placeholder="e.g. 100% or 800px"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Iframe Height"
                name="iframeHeight"
                value={currentConfig.iframeHeight}
                onChange={handleChange}
                margin="normal"
                placeholder="e.g. 600px"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Iframe Border"
                name="iframeBorder"
                value={currentConfig.iframeBorder}
                onChange={handleChange}
                margin="normal"
                placeholder="e.g. none or 1px solid #ccc"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth margin="normal">
                <InputLabel shrink>Primary Color</InputLabel>
                <br/>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={3}>
                    <input
                      type="color"
                      value={currentConfig.primaryColor}
                      onChange={(e) => handleChange({
                        target: {
                          name: 'primaryColor',
                          value: e.target.value
                        }
                      })}
                      style={{ 
                        width: '100%',
                        height: '40px',
                        padding: 0,
                        border: 'none'
                      }}
                    />
                  </Grid>
                  <Grid item xs={9}>
                    <TextField
                      fullWidth
                      value={currentConfig.primaryColor}
                      onChange={handleChange}
                      name="primaryColor"
                      placeholder="#000000"
                      size="small"
                    />
                  </Grid>
                </Grid>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth margin="normal">
                <InputLabel shrink>Secondary Color</InputLabel>
                <br/>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={3}>
                    <input
                      type="color"
                      value={currentConfig.secondaryColor}
                      onChange={(e) => handleChange({
                        target: {
                          name: 'secondaryColor',
                          value: e.target.value
                        }
                      })}
                      style={{ 
                        width: '100%',
                        height: '40px',
                        padding: 0,
                        border: 'none'
                      }}
                    />
                  </Grid>
                  <Grid item xs={9}>
                    <TextField
                      fullWidth
                      value={currentConfig.secondaryColor}
                      onChange={handleChange}
                      name="secondaryColor"
                      placeholder="#FFFFFF"
                      size="small"
                    />
                  </Grid>
                </Grid>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6" sx={{ mt: 3, mb: 2 }}>
                Intro Page Content
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Title"
                name="intro_page.title"
                value={currentConfig.intro_page.title}
                onChange={(e) => handleIntroPageChange('title', e.target.value)}
                margin="normal"
                placeholder="e.g. Get Life Cover Now"
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Subtitle"
                name="intro_page.subtitle"
                value={currentConfig.intro_page.subtitle}
                onChange={(e) => handleIntroPageChange('subtitle', e.target.value)}
                margin="normal"
                placeholder="e.g. From UGX 130 p/a"
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Description"
                name="intro_page.description"
                value={currentConfig.intro_page.description}
                onChange={(e) => handleIntroPageChange('description', e.target.value)}
                margin="normal"
                multiline
                rows={4}
                placeholder="Enter the main description text"
              />
            </Grid>

            <Grid item xs={12}>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Features List ({currentConfig.intro_page.features.length}/5)
                </Typography>
                <Button
                  startIcon={<AddCircleOutlineIcon />}
                  onClick={handleAddFeature}
                  disabled={currentConfig.intro_page.features.length >= 5}
                  size="small"
                >
                  Add Feature
                </Button>
              </div>
              {currentConfig.intro_page.features.map((feature, index) => (
                <div key={index} style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                  <TextField
                    fullWidth
                    label={`Feature ${index + 1}`}
                    value={feature}
                    onChange={(e) => {
                      const newFeatures = [...currentConfig.intro_page.features];
                      newFeatures[index] = e.target.value;
                      setCurrentConfig(prev => ({
                        ...prev,
                        intro_page: {
                          ...prev.intro_page,
                          features: newFeatures
                        }
                      }));
                    }}
                    margin="normal"
                    placeholder={`e.g. Feature ${index + 1}`}
                  />
                  <IconButton 
                    onClick={() => handleRemoveFeature(index)}
                    color="error"
                    size="small"
                  >
                    <RemoveCircleOutlineIcon />
                  </IconButton>
                </div>
              ))}
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6" sx={{ mt: 3, mb: 2 }}>
                Outro Page Content
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Title"
                name="outro_page.title"
                value={currentConfig.outro_page.title}
                onChange={(e) => handleOutroPageChange('title', e.target.value)}
                margin="normal"
                placeholder="e.g. Thank You for Your Interest"
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Subtitle"
                name="outro_page.subtitle"
                value={currentConfig.outro_page.subtitle}
                onChange={(e) => handleOutroPageChange('subtitle', e.target.value)}
                margin="normal"
                placeholder="e.g. Your quote has been generated successfully"
              />
            </Grid>

            <Grid item xs={12}>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Next Steps ({currentConfig.outro_page.next_steps.length}/3)
                </Typography>
                <Button
                  startIcon={<AddCircleOutlineIcon />}
                  onClick={handleAddNextStep}
                  disabled={currentConfig.outro_page.next_steps.length >= 3}
                  size="small"
                >
                  Add Step
                </Button>
              </div>
              {currentConfig.outro_page.next_steps.map((step, index) => (
                <div key={index} style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                  <TextField
                    fullWidth
                    label={`Step ${index + 1}`}
                    value={step}
                    onChange={(e) => {
                      const newSteps = [...currentConfig.outro_page.next_steps];
                      newSteps[index] = e.target.value;
                      setCurrentConfig(prev => ({
                        ...prev,
                        outro_page: {
                          ...prev.outro_page,
                          next_steps: newSteps
                        }
                      }));
                    }}
                    margin="normal"
                    placeholder={`e.g. Step ${index + 1}`}
                  />
                  <IconButton 
                    onClick={() => handleRemoveNextStep(index)}
                    color="error"
                    size="small"
                  >
                    <RemoveCircleOutlineIcon />
                  </IconButton>
                </div>
              ))}
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1" sx={{ mt: 2, mb: 1 }}>
                Contact Details
              </Typography>
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Email"
                name="outro_page.contact.email"
                value={currentConfig.outro_page.contact.email}
                onChange={(e) => handleContactChange('email', e.target.value)}
                margin="normal"
                placeholder="e.g. support@example.com"
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Phone"
                name="outro_page.contact.phone"
                value={currentConfig.outro_page.contact.phone}
                onChange={(e) => handleContactChange('phone', e.target.value)}
                margin="normal"
                placeholder="e.g. +256 123 456 789"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => setOpenDialog(false)}
          >
            Cancel
          </Button>
          <Button 
            onClick={handleSubmit}
            variant="contained"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog 
        open={openViewDialog} 
        onClose={() => setOpenViewDialog(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>View Iframe Configuration</DialogTitle>
        <DialogContent>
          {selectedConfig && (
            <>
              <Grid container spacing={3} sx={{ mb: 3 }}>
                <Grid item xs={12} md={6}>
                  <Typography variant="subtitle2">Iframe ID</Typography>
                  <Typography>{selectedConfig.iframe_id}</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="subtitle2">Partner Domain</Typography>
                  <Typography>{selectedConfig.partnerDomain}</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="subtitle2">API Token</Typography>
                  <Typography>{selectedConfig.apiToken}</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="subtitle2">Type</Typography>
                  <Typography>{selectedConfig.type}</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="subtitle2">Theme</Typography>
                  <Typography>{selectedConfig.theme}</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="subtitle2">Width</Typography>
                  <Typography>{selectedConfig.iframeWidth}</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="subtitle2">Height</Typography>
                  <Typography>{selectedConfig.iframeHeight}</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="subtitle2">Border</Typography>
                  <Typography>{selectedConfig.iframeBorder}</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="subtitle2">Primary Color</Typography>
                  <Grid container spacing={1} alignItems="center">
                    <Grid item>
                      <div style={{ 
                        width: '20px', 
                        height: '20px', 
                        backgroundColor: selectedConfig.primaryColor,
                        border: '1px solid #ccc',
                        borderRadius: '4px',
                        marginRight: '8px'
                      }} />
                    </Grid>
                    <Grid item>
                      <Typography>{selectedConfig.primaryColor}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="subtitle2">Secondary Color</Typography>
                  <Grid container spacing={1} alignItems="center">
                    <Grid item>
                      <div style={{ 
                        width: '20px', 
                        height: '20px', 
                        backgroundColor: selectedConfig.secondaryColor,
                        border: '1px solid #ccc',
                        borderRadius: '4px',
                        marginRight: '8px'
                      }} />
                    </Grid>
                    <Grid item>
                      <Typography>{selectedConfig.secondaryColor}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="subtitle2">Product Name</Typography>
                  <Typography>{selectedConfig && selectedConfig.productName}</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="subtitle2">Product ID</Typography>
                  <Typography>{product.guid}</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="subtitle2">Partner ID</Typography>
                  <Typography>{product.partner_guid}</Typography>
                </Grid>
              </Grid>

              <Typography variant="subtitle1" gutterBottom>
                Generated Iframe Code
              </Typography>
              <Paper 
                sx={{ 
                  p: 2, 
                  bgcolor: '#f5f5f5',
                  position: 'relative',
                  mb: 3
                }}
              >
                <pre style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>
                  {generateIframeCode(selectedConfig)}
                </pre>
                <IconButton 
                  onClick={() => handleCopyCode(generateIframeCode(selectedConfig))}
                  sx={{ position: 'absolute', top: 8, right: 8 }}
                >
                  <ContentCopyIcon />
                </IconButton>
              </Paper>

              <Grid item xs={12}>
                <Typography variant="subtitle2">Intro Page Content</Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="body2" color="textSecondary">Title</Typography>
                    <Typography>{selectedConfig.intro_page.title}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body2" color="textSecondary">Subtitle</Typography>
                    <Typography>{selectedConfig.intro_page.subtitle}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body2" color="textSecondary">Description</Typography>
                    <Typography style={{ whiteSpace: 'pre-wrap' }}>
                      {selectedConfig.intro_page.description}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body2" color="textSecondary">Features</Typography>
                    <ul>
                      {selectedConfig.intro_page.features.map((feature, index) => (
                        <li key={index}>{feature}</li>
                      ))}
                    </ul>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="subtitle2">Outro Page Content</Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="body2" color="textSecondary">Title</Typography>
                    <Typography>{selectedConfig.outro_page.title}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body2" color="textSecondary">Subtitle</Typography>
                    <Typography>{selectedConfig.outro_page.subtitle}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body2" color="textSecondary">Next Steps</Typography>
                    <ul>
                      {selectedConfig.outro_page.next_steps.map((step, index) => (
                        <li key={index}>{step}</li>
                      ))}
                    </ul>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body2" color="textSecondary">Contact Details</Typography>
                    <Typography>Email: {selectedConfig.outro_page.contact.email}</Typography>
                    <Typography>Phone: {selectedConfig.outro_page.contact.phone}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenViewDialog(false)}>Close</Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={showCopySuccess}
        autoHideDuration={3000}
        onClose={() => setShowCopySuccess(false)}
      >
        <Alert severity="success" onClose={() => setShowCopySuccess(false)}>
          Code copied to clipboard!
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default IframeConfigs; 