import React, { useState } from 'react';
import { 
  Box, 
  Typography, 
  Paper,
  Divider,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import { makeStyles } from "@material-ui/styles";
import { QUOTE_COLORS } from '../constants';
import ButtonInc from '../../../../../../shared-ui/ButtonInc';
import { calculateTotalPremium, formatCurrency } from '../../../helpers';

const useStyles = makeStyles(() => ({
  root: {
    padding: '1.5rem'
  },
  premiumCard: {
    backgroundColor: '#032a37 !important',
    padding: '1.5rem',
    textAlign: 'center',
    marginBottom: '1rem',
    borderRadius: '8px',
    '& > h6': {
      color: '#FFFFFF',
      fontSize: '14px',
      marginBottom: '0.5rem',
      opacity: 0.85,
      textTransform: 'lowercase',
    },
    '& > div': {
      display: 'flex',
      alignItems: 'baseline',
      justifyContent: 'center',
      gap: '8px',
      color: '#FFFFFF',
      '& > span:nth-of-type(1)': { // currency
        fontSize: '24px',
        opacity: 0.9,
      },
      '& > span:nth-of-type(2)': { // amount
        fontSize: '48px',
        fontWeight: 600,
      },
      '& > span:nth-of-type(3)': { // frequency
        fontSize: '16px',
        opacity: 0.75,
        fontWeight: 400,
      }
    }
  },
  detailsCard: {
    padding: '1.5rem',
    marginBottom: '1rem',
    backgroundColor: '#fff',
    borderRadius: '8px',
    boxShadow: '0 2px 8px rgba(0,0,0,0.05)'
  },
  sectionTitle: {
    color: QUOTE_COLORS.PRIMARY,
    fontWeight: 600,
    fontSize: '1.125rem',
    marginBottom: '1rem',
    paddingBottom: '0.5rem',
    borderBottom: `2px solid ${QUOTE_COLORS.PRIMARY}`
  },
  detailRow: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0.75rem 0'
  },
  label: {
    color: QUOTE_COLORS.TEXT.SECONDARY,
    marginBottom: '0.25rem',
    fontSize: '0.875rem'
  },
  value: {
    color: QUOTE_COLORS.PRIMARY,
    fontWeight: 500,
    fontSize: '1rem'
  },
  memberCard: {
    padding: '0.75rem',
    marginBottom: '0.75rem',
    backgroundColor: '#f5f5f5',
    '&:last-child': {
      marginBottom: 0
    }
  },
  memberName: {
    color: QUOTE_COLORS.PRIMARY,
    fontWeight: 500,
    marginBottom: '0.25rem'
  },
  memberType: {
    color: QUOTE_COLORS.TEXT.SECONDARY,
    fontSize: '0.875rem'
  },
  benefitAmount: {
    color: QUOTE_COLORS.SECONDARY,
    fontWeight: 500,
    marginTop: '0.25rem'
  },
  declaration: {
    marginTop: '1rem',
    padding: '1rem',
    backgroundColor: '#f5f5f5',
    borderRadius: '4px'
  },
  actions: {
    marginTop: '1.5rem',
    display: 'flex',
    justifyContent: 'center',
    gap: '1rem'
  }
}));

const QuoteDetails = ({ quoteData, onNext, onBack, loading }) => {
  const classes = useStyles();
  const [declarationAccepted, setDeclarationAccepted] = useState(false);
  const { selectedCover, members } = quoteData;
  const totalPremium = calculateTotalPremium(selectedCover, members);

  const formatCurrency = (amount) => {
    return amount.toLocaleString();
  };

  const getMemberTypeLabel = (type) => {
    switch(type) {
      case 'main_member': return 'Main Member';
      case 'spouse': return 'Spouse';
      case 'children': return 'Child';
      case 'parents': return 'Parent';
      default: return type;
    }
  };

  const getMainMemberName = () => {
    if (!members.mainMember) return 'Not specified';
    return `${members.mainMember.first_name} ${members.mainMember.last_name}`;
  };

  const getMainMemberEmail = () => {
    if (!members.mainMember) return 'Not specified';
    return members.mainMember.email || 'Not specified';
  };

  const renderMembersList = () => {
    const allMembers = [
      members.mainMember,
      members.spouse,
      ...(members.children || []),
      ...(members.parents || [])
    ].filter(member => member !== null && member !== undefined);

    return allMembers.map((member) => (
      <Paper key={member.id} className={classes.memberCard}>
        <Typography className={classes.memberName}>
          {member.first_name} {member.last_name}
        </Typography>
        <Typography className={classes.memberType}>
          {getMemberTypeLabel(member.type)}
        </Typography>
        <Typography className={classes.benefitAmount}>
          Cover Amount: UGX {formatCurrency(member.benefit_amount)}
        </Typography>
      </Paper>
    ));
  };

  return (
    <Box className={classes.root}>
      {/* Premium Card */}
      <Paper className={classes.premiumCard} elevation={0}>
        <Typography component="h6">
          ongoing premium
        </Typography>
        <Box>
          <Typography component="span">
            UGX
          </Typography>
          <Typography component="span">
            {formatCurrency(totalPremium)}
          </Typography>
          <Typography component="span">
            / Yearly
          </Typography>
        </Box>
      </Paper>

      {/* Package Details */}
      <Paper className={classes.detailsCard}>
        <Typography variant="h6" className={classes.sectionTitle}>
          Package Details
        </Typography>
        <Box>
          <Box className={classes.detailRow}>
            <Typography className={classes.label}>Package Name</Typography>
            <Typography className={classes.value}>
              {selectedCover.package_name}
            </Typography>
          </Box>
          <Divider />
          <Box className={classes.detailRow}>
            <Typography className={classes.label}>Policyholder</Typography>
            <Typography className={classes.value}>
              {getMainMemberName()}
            </Typography>
          </Box>
          <Divider />
          <Box className={classes.detailRow}>
            <Typography className={classes.label}>Email</Typography>
            <Typography className={classes.value}>
              {getMainMemberEmail()}
            </Typography>
          </Box>
        </Box>
      </Paper>

      {/* Members List */}
      <Paper className={classes.detailsCard}>
        <Typography variant="h6" className={classes.sectionTitle}>
          Covered Members
        </Typography>
        {renderMembersList()}
      </Paper>

      {/* Declaration */}
      <Paper className={classes.declaration}>
        <Typography variant="h6" gutterBottom>
          Declaration
        </Typography>
        <FormControlLabel
          control={
            <Checkbox
              checked={declarationAccepted}
              onChange={(e) => setDeclarationAccepted(e.target.checked)}
              color="primary"
            />
          }
          label={
            <Typography variant="body2">
              I, {getMainMemberName()}, confirm that all information provided is accurate and complete.
            </Typography>
          }
        />
      </Paper>

      {/* Actions */}
      <Box className={classes.actions}>
        <ButtonInc 
          btnType="secondary" 
          onClick={onBack}
          disabled={loading}
        >
          Back
        </ButtonInc>
        <ButtonInc 
          onClick={onNext}
          disabled={loading || !declarationAccepted}
        >
          Proceed to Payment
        </ButtonInc>
      </Box>
    </Box>
  );
};

export default QuoteDetails; 