import React, { useState, useEffect } from 'react'
import { getPartner, getPartnerGuid } from '../../../../../lib/access.es6';
import AlertDialog from '../../../../../components/AlertDialog/index.es6';
import { CircularProgress } from '@material-ui/core';
import { Button } from '@mui/material';
import { find } from 'lodash';
import NavButtons from './NavButtons';
import ViewMembers from './ViewMembers';
import Adddependant from './AddDependant';
import { COMPONENT_TYPES } from '../../helpers';
import SelectPlan from './SelectPlan';


const CreateQuote = (props) => {

  const [currentComponent, setCurrentComponent] = useState(COMPONENT_TYPES.SELECT_PLAN);
  const [selectedPlan, setPlan] = useState(null);
  const [parentsPlan, setParentsPlan] = useState(null);



  const [togglequoteanddependant, settogglequoteanddependant] = useState(true)
  const [dependants, setDependants] = useState([])
  const [depAlert, setDepAlert] = useState(false);


  const [monthlyPremium, setMonthlyPremium] = useState()
  const [planName, setPlanName] = useState()
  const [chpPremium, setChpPremiums] = useState(0)


  useEffect(() => {


    if (props.productDetails.code === "chp_care_benefit") {
      setCurrentComponent(COMPONENT_TYPES.ADD_DEPENDANT)
    }
  }, [props.plans]);

  const handleAdddependant = () => {
    settogglequoteanddependant(!togglequoteanddependant)
  }


  const removeDependant = (uuid) => {
    const item = find(dependants, { uuid: uuid })
    if (item.relationship === 'Principal') {
      setDependants([])
    } else {
      const updatedDependants = dependants.filter(item => item.uuid !== uuid);
      setDependants(updatedDependants);

    }
  };


  const handleCreateQuote = () => {
    // attachBenefits()
    const payload = {
      "product_guid": props.productDetails.guid,
      "product_name": props.productType,
      "product_code": props.productDetails.code,
      "customer_guid": props.principal.guid,
      "partner": getPartnerGuid(),
      "lapse_period": 12,
      "lapse_period_granularity": "month",
      "lapse_period_cardinality": 1,
      "premium_granularity": "month",
      "premium_cardinality": 1,
      "policy_schedule_entries": [
        ...dependants
      ],
      additional_details: {
        "monthly_premium": monthlyPremium,
        "plan_name": planName
      }
    }
    console.log("quotepayload", payload)
    props.createQuote(payload)


  }

  const handleQuoteCreatedSuccessfully = () => {
    props.resetAddQuoteSuccessAlert();

  }

  const handleQuoteCreateError = () => {
    props.resetAddQuoteErrorAlert();

  }

  const handlenextStep = () => {
    props.handleNext();
  }





  const currencyCode = getPartner().country.currency_code

  return (

    <>

      {currentComponent === COMPONENT_TYPES.SELECT_PLAN &&
        <SelectPlan
          setMonthlyPremium={setMonthlyPremium}
          setPlanName={setPlanName}
          // coverType={coverType}
          //hasParentsPlan={hasParentsPlan}
          plans={props.plans}
          // setPlans={setPlans}
          // plans={plans}
          selectedPlan={selectedPlan}
          currencyCode={currencyCode}
          // setParentsPlan={setParentsPlan}
          setPlan={setPlan}
        // parentsPlan={parentsPlan}
        />
      }

      {

        currentComponent === COMPONENT_TYPES.ADD_DEPENDANT &&
        <Adddependant
          productCode={props.productCode}
          productType={props.productType}
          productDetails={props.productDetails}
          dependant={props.dependant}
          handleCreateQuote={handleCreateQuote}
          handleAdddependant={handleAdddependant}
          createQuote={props.createQuote}
          setDependants={setDependants}
          dependants={dependants}
          createDependant={props.createDependant}
          viewMembers={() => setCurrentComponent(COMPONENT_TYPES.VIEW_MEMBERS)}

          currencyCode={currencyCode}
          removeDependant={removeDependant}
          depAlert={depAlert}
          setDepAlert={setDepAlert}
          principal={props.principal}
          autoFillPrincipal={props.autoFillPrincipal}
          toggleAutoFillPrincipal={props.toggleAutoFillPrincipal}
          selectedPlan={selectedPlan}
          parentsPlan={parentsPlan}
    

          monthlyPremium={monthlyPremium}
          setMonthlyPremium={setMonthlyPremium}
          chpPremium={chpPremium}
          setChpPremiums={setChpPremiums}
        // suspendDuplicateIdCheck={suspendDuplicateIdCheck}

        />
      }



      {
        currentComponent === COMPONENT_TYPES.VIEW_MEMBERS &&
        <ViewMembers
          productCode={props.productCode}
          currencyCode={currencyCode}
          removeDependant={removeDependant}
          dependants={dependants}
          addAnotherMember={() => {
            //setMemberRelationshipType(relationshipType)
            setCurrentComponent(COMPONENT_TYPES.ADD_DEPENDANT)
            //setCurrentRelationShip(null)
          }}
          //setOpenAddBenefitsDialog={setOpenAddBenefitsDialog}
          //additionalBenefits={additionalBenefits}
          selectedPlan={selectedPlan}
          parentsPlan={parentsPlan}
          handleCreateQuote={handleCreateQuote}

          monthlyPremium={monthlyPremium}
          chpPremium={chpPremium}
        />
      }



      <NavButtons
        currentComponent={currentComponent}
        setCurrentComponent={setCurrentComponent}
        selectedPlan={selectedPlan}
        setPlan={setPlan}
        parentsPlan={parentsPlan}
        setParentsPlan={setParentsPlan}
      />


      <AlertDialog
        custom
        show={props.showAddQuoteProgressAlert}
        size="sm"
        style={{ marginTop: '0', top: '30vh' }}
        confirmBtnText={'ok'}
        showCancel={false}
        showConfirm={false}
        cancelBtnText={'cancel'}
        showTitle={false}
        confirmBtnCssClass
      >
        <CircularProgress />
        <h2> Creating Quote...</h2>
      </AlertDialog>

      <AlertDialog
        success
        show={props.showAddQuoteSuccessAlert}
        size="sm"
        title={'Quote  created'}
        style={{ marginTop: '0', top: '30vh' }}
        onConfirm={() => { handleQuoteCreatedSuccessfully() }}
        confirmBtnText={'ok'}
        showCancel={false}
        showConfirm={false}
        cancelBtnText={'cancel'}
        confirmBtnCssClass
      >
        <div >
          <Button variant="contained" onClick={() => { handlenextStep() }} style={{ background: 'green', }}>View Quote Details</Button>
        </div>
      </AlertDialog>

      <AlertDialog
        show={props.showAddQuoteErrorAlert}
        danger
        title={'Error creating Quote'}
        onConfirm={() => handleQuoteCreateError()}
        confirmBtnText={'Try again'}
        confirmBtnCssClass
        showCancel={false}
        style={{ marginTop: '0', top: '30vh' }}
      >
        {
          props.createQuoteError ? props.createQuoteError.message : ""
        }
      </AlertDialog>

      <hr />

      {/* {selectedPlan &&
        <>
          {dependants.length > 0 &&
            <div className={classes.createquote}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={handleCreateQuote}
              >
                Create Quote
              </Button>
            </div>
          }
        </>
      } */}

    </>
  )
}

export default CreateQuote


