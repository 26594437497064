import React from 'react';
import Grid from "@material-ui/core/Grid";
import { useStyle } from "./Style";
import ButtonInc from '../../../../../../shared-ui/ButtonInc';
import { getPartner } from '../../../../../../lib/access.es6';

const SelectPaymentPlan = ({ productDetails, dependants, handleCreateQuote, premiumCardinality, setPremiumCardinality, setPremiumGranularity, setPaymentPlanPrice, setPaymentPlanDescription }) => {
  const classes = useStyle();

  const [disableCreateQuote, setDisableCreateQuote] = React.useState(false)


  const handleSelect = (value) => {
    setDisableCreateQuote(true)
    setPremiumCardinality(value.label === "Annually" ? 1 : value.months);
    setPremiumGranularity(value.label)
    setPaymentPlanDescription(value.description)

    setPaymentPlanPrice(
      (dependants[0].premium_amount_in_cents / 100 /
        (value.label === "Annually" ? 1 :
          value.label === "Monthly" ? 12 :
          value.label === "Semi-Annually" ? 2 :
          value.months)
      ).toLocaleString()
    )



  };


  const currencyCode = getPartner().country.currency_code
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <h2 style={{
            fontFamily: 'Open Sans',
            fontStyle: "normal",
            fontWeight: 300,
            fontSize: "20px",
            lineHeight: "16px",
            letterSpacing: "0.005em",
            color: "#000000",
            textAlign: "center"
          }}>
            Please select payment plan
          </h2>
        </Grid>
        {productDetails.membership_configs.content.payment_plans.map((plan, index) => (
          <Grid item xs={12} sm={4} md={4} key={index}>
            <div
              // className={`${classes.productWrapper} ${premiumCardinality === plan.label ? 'selected' : ''}`}
              className={`${classes.productWrapper} ${premiumCardinality === plan.months ? 'selected' : ''}`}

              onClick={() => handleSelect(plan)}>
              <div><h3>{plan.label}</h3></div>
              <p>{plan.description}</p>
              {/* <p>Price: {currencyCode}  {(dependants[0].premium_amount_in_cents / 100 / (plan.label === "Annually" ? 1 : plan.months)).toLocaleString()}</p> */}
              {/* <p>Price:  {currencyCode}  {(dependants[0].premium_amount_in_cents / 100 /
                (plan.label === "Annually" ? 1 : plan.label === "Monthly" ? 12 : plan.months)
              ).toLocaleString()}</p> */}
              <p>Price: {currencyCode}  {(dependants[0].premium_amount_in_cents / 100 /
                (plan.label === "Annually" ? 1 :
                  plan.label === "Monthly" ? 12 :
                    plan.label === "Semi-Annually" ? 2 :
                      plan.months)
              ).toLocaleString()}</p>
            </div>
          </Grid>
        ))}
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} md={4} sm={4}></Grid>
        <Grid item xs={12} md={4} sm={4}>
          {dependants.length > 0 &&
            <div className={classes.createquote}>
              <ButtonInc
                variant="contained"
                color="primary"
                size="large"
                disabled={!disableCreateQuote}
                onClick={() => handleCreateQuote()}
              >
                Create Quote
              </ButtonInc>

            </div>
          }
        </Grid>
        <Grid item xs={12} md={4} sm={4}></Grid>
      </Grid>
    </>

  );
};

export default SelectPaymentPlan;
