import React from 'react'
import { useStyle } from "./Style";
import Grid from "@material-ui/core/Grid";
import TableInc from '../../../../../shared-ui/TableInc';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Tooltip from '@mui/material/Tooltip';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import find from 'lodash/find';
import { Chip } from '@mui/material';
import { ClaimTypeEnum } from '../../../lib/enum';
import ViewDocument from '../../../../../shared-ui/ViewDocument/ViewDocument';
import FileDownloadIcon from '@mui/icons-material/FileDownload';


const ClaimDocuments = (props) => {
  
  const [viewDocumentUrl, setViewDocumentUrl] = React.useState(null);
  const [numPages, setNumPages] = React.useState(null);

  const classes = useStyle();
  const claimDocs = props.claimDocuments || [];

  const staticHospitalDocs = [
    { description: 'Hospital_Invoice *' },
    { description: 'Discharge_Summary *' },
    { description: 'LOU_Document *' },
  ]

  const staticDeathDocs = [
    // { description: ' Claim discharge form completed by client *' },
    { description: 'Death certificate *' },
    { description: 'Identity document *' },
    { description: 'Claimant Identity document *' },
    // { description: 'Bank statement / confirmation of bank account *' },
    // { description: 'Claim document (download it on the claim details section) *' },
    // { description: 'Copy of clients funeral application form / policy document (use the Download policy button to get policy document ) *' },

  ]

  const staticReimbursementDocs = [
    { description: 'Hospital_Invoice *' },
    { description: 'Discharge_Summary *' },
    { description: 'Payment Receipts *' },
    { description: 'Claimants ID copy *' },
    { description: 'Medical/Diagnostic Report *' },
  ]
  let staticDocs = []
  if (props.type === ClaimTypeEnum.death) {
    staticDocs = [...staticDeathDocs]
  }
  if (props.type === ClaimTypeEnum.hospital) {
    staticDocs = [...staticHospitalDocs]
  }
  if (props.type === ClaimTypeEnum.reimbursement) {
    staticDocs = [...staticReimbursementDocs]
  }

  const missingStaticDocs = []

  for (let doc of staticDocs) {
    if (!find(claimDocs, { description: doc.description })) {
      missingStaticDocs.push({ ...doc, status: 'missing' })
      if (!props.missingDocs) {
        props.toggleMissingDocs();
      }

    }
  }

  const result = [
    ...missingStaticDocs,
    ...claimDocs,
    { description: '', status: 'any other doc' }
  ];


  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}  >
          <Grid container spacing={2} className={classes.claimdocswrapper}>
            <Grid item xs={12} sm={12} md={12}>

              <TableInc
                columns={[
                  {
                    title: 'Document  Name', field: 'description'
                  },
                  {
                    title: 'Status', field: 'created_At', render: rowData => rowData.status === 'missing'
                      ? <Chip
                        size="small"
                        variant="outlined"
                        label={'missing'}
                        style={{
                          margin: '1px',
                          backgroundColor: "red",
                          color: "#fff"
                        }}
                      />
                      : (rowData.status === 'any other doc' ? 'upload any other doc'
                        : <Chip
                          size="small"
                          variant="outlined"
                          label={'uploaded'}
                          style={{
                            margin: '1px',
                            backgroundColor: "green",
                            color: "#fff"
                          }}
                        />)
                  },

                  {
                    title: 'Actions', field: 'created_At', render: rowData => {
                      return !(rowData.status === 'missing' || rowData.status === 'any other doc') ? <div className={classes.actionbtn}>
                        <Tooltip title="View Document">
                          <span onClick={() => setViewDocumentUrl(rowData.url)} style={{ cursor: "pointer" }} ><VisibilityIcon sx={{ color: " #4B7E34" }} /> </span>
                        </Tooltip>
                        <Tooltip title="Download Document">
                          <span style={{ cursor: "pointer", marginLeft: "10px" }} ><a href={rowData.url} download target='_blank' rel="noopener noreferrer" ><FileDownloadIcon sx={{ color: " #000" }} /></a> </span>
                        </Tooltip>
                        <Tooltip title="Delete Document">
                          <span style={{ cursor: "pointer",  marginLeft: "5px" }} onClick={() => props.setClaimDocumentToDelete(rowData)} > <DeleteIcon sx={{ color: "#8F191C" }} /></span>
                        </Tooltip>

                      </div> :

                        <div className={classes.actionbtn}>

                          <Tooltip title="Upload Document">
                            <span style={{ cursor: "pointer", marginLeft: "5px" }} onClick={() => {
                              props.setDescription(rowData.description);
                              props.openUploadDialog();
                            }} > <FileUploadIcon sx={{ color: "#000" }} /></span>
                          </Tooltip>
                        </div>
                    }
                  },


                ]}
                data={
                  result
                }
                options={{
                  pageSize: 7,
                  showTitle: false,
                }
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <ViewDocument
        title="Claim Document"
        open={viewDocumentUrl}
        close={() => setViewDocumentUrl(null)}
        viewDocumentUrl={viewDocumentUrl}
        numPages={numPages}
        onLoadSuccess={({ numPages }) => setNumPages(numPages)}
      />
    </>
  )
}

export default ClaimDocuments