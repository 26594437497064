import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { 
  Box,
  Container,
  Paper,
  LinearProgress,
  Typography,
  IconButton,
  Alert,
  Backdrop,
  CircularProgress
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useStyle } from './Style';
import SelectCover from './Steps/SelectCover';
import AddMembers from './Steps/AddMembers';
import QuoteDetails from './Steps/QuoteDetails';
import BeneficiaryDetails from './Steps/BeneficiaryDetails';
import TermsAndConditions from './Steps/TermsAndConditions';
import BankingDetails from './Steps/BankingDetails';
//import DocumentUpload from './Steps/DocumentUpload';
import PolicyCreated from './Steps/PolicyCreated';
import { productData } from '../../helpers/product';
import { getPartnerGuid, getUserGuid } from '../../../../../lib/access.es6';
import { buyPolicy, createDebitOrderz, createQuote } from '../../../../../lib/requests.es6';
import { calculateTotalPremium, formatPremiumPayload } from '../../helpers';

const steps = [
  'Select Cover',
  'Add Members',
  'Quote Details', 
  'Beneficiary Details',
  'Terms & Conditions',
  'Banking Details',
 // 'Upload Documents',
  'Policy Created'
];

const QuoteCreation = ({ product=productData, documentUpload, customerGuid, customerMsisdn, customer }) => {
  const classes = useStyle();
  const [activeStep, setActiveStep] = useState(0);
  const [progress, setProgress] = useState(0);
  const [quoteData, setQuoteData] = useState({
    selectedCover: null,
    members: {
      mainMember: null,
      spouse: null,
      children: [],
      parents: []
    },
    "documents": [],
    "termsAccepted": true
});
  const [apiResponses, setApiResponses] = useState({
    quoteResponse: null,
    policyResponse: null,
    bankingResponse: null
  });

  // Add new state for loading and errors
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const newProgress = ((activeStep + 1) / steps.length) * 100;
    setProgress(newProgress);
  }, [activeStep]);

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const updateQuoteData = (key, value) => {
    setQuoteData(prev => ({
      ...prev,
      [key]: value
    }));
  };

  // Helper to show error for 5 seconds
  const showTemporaryError = (errorMessage) => {
    setError(errorMessage);
    setTimeout(() => setError(null), 5000);
  };

  const handleQuoteDetailsNext = async () => {
    try {
      setLoading(true);
      setError(null);
      const payload = formatQuotePayload(quoteData);
      const response = await createQuote(payload);
      setApiResponses(prev => ({
        ...prev,
        quoteResponse: response.data
      }));
      handleNext();
    } catch (error) {
      console.error('Failed to create quote:', error);
      const errorMessage = error.response && error.response.data && error.response.data.message 
        ? error.response.data.message 
        : 'Failed to create quote. Please try again.';
      showTemporaryError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const handleBeneficiaryNext = async () => {
    try {
      setLoading(true);
      setError(null);
      const payload = formatPolicyPayload(quoteData, apiResponses.quoteResponse);
      const response = await buyPolicy(payload);
      setApiResponses(prev => ({
        ...prev,
        policyResponse: response.data
      }));
      handleNext();
    } catch (error) {
      console.error('Failed to create policy:', error);
      const errorMessage = error.response && error.response.data && error.response.data.message 
        ? error.response.data.message 
        : 'Failed to create policy. Please try again.';
      showTemporaryError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const handleBankingDetailsNext = async () => {
    try {
      setLoading(true);
      setError(null);
      const payload = formatBankingDetailsPayload(quoteData, apiResponses.policyResponse);
      const response = await createDebitOrderz(payload);
      setApiResponses(prev => ({
        ...prev,
        bankingResponse: response
      }));
      handleNext();
    } catch (error) {
      console.error('Failed to add banking details:', error);
      const errorMessage = error.response && error.response.data && error.response.data.message 
        ? error.response.data.message 
        : 'Failed to add banking details. Please try again.';
      showTemporaryError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  console.log('quoteData', quoteData);

  const formatQuotePayload = (quoteData) => {
    const { selectedCover, members } = quoteData;
    const premiumPayload = formatPremiumPayload(totalPremium);
    const childrenCount = members.children ? members.children.length : 0;
    const childrenPremium = childrenCount > 4 ? (childrenCount - 4) * 10000 : 0;
    const totalPremium = Number(selectedCover.premium_amount) + childrenPremium;
    console.log('totalPremium', totalPremium);

    const formatMember = (member, relationship) => {
      console.log('relationship', relationship);
      const benefits =  [
        {
          "type": "FUNERAL BENEFIT",
          "cover_amount_in_cents": Number(member.benefit_amount) * 100,
          "waiting_period_in_days": 30,
          "cover_usage_count": 0,
          "benefit_period_cardinality": 1,
          'benefit_period_granuality': 'year',
          "premium_amount_in_cents": relationship === "Principal" ? totalPremium * 100 : 0,
          "premium_amount": relationship === "Principal" ? totalPremium : 0,
          "uuid": uuidv4(),
          "is_initialized": true,
          "additional_details": {
              "premium_amount": relationship === "Principal" ? Number(selectedCover.premium_amount) : 0,
              "uuid": uuidv4()
          }
      }
      ];

      return {
        first_name: member.first_name,
        middle_name: member.middle_name || "",
        last_name: member.last_name,
        msisdn: member.phone,
        date_of_birth: member.date_of_birth,
        gender: member.gender,
        national_id: member.national_id,
        passport: member.passport || "N/A",
        postal_address: member.postal_address || "",
        relationship: relationship,
        residential_address: member.residential_address || "N/A",
        type: "Nuclear",
        benefits: benefits,
        uuid: member.uuid || Date.now().toString(),
        additional_details: {
          uuid: member.uuid || Date.now().toString(),
          memberType: "Nuclear"
        },
        premium_amount_in_cents: relationship === "Principal" ? totalPremium * 100 : 0,
        premium_amount: relationship === "Principal" ? totalPremium : 0,
      };
    };

    // Create policy schedule entries for all members
    const policy_schedule_entries = [];

    // Add main member
    if (members.mainMember) {
      policy_schedule_entries.push(formatMember({...members.mainMember, relationship: "Principal"}, "Principal"));
    }

    // Add spouse if exists
    if (members.spouse) {
      policy_schedule_entries.push(formatMember({...members.spouse, relationship: "Spouse"}, "Spouse"));
    }

    // Add children if any
    if (members.children && members.children.length) {
      members.children.forEach(child => {
        policy_schedule_entries.push(formatMember(child, "Child"));
      });
    }

    // Add parents if any
    if (members.parents && members.parents.length) {
      members.parents.forEach(parent => {
        policy_schedule_entries.push(formatMember(parent, "Parent"));
      });
    }

    return {
      product_guid: 'Family-Package-2',
      product_name: 'Family Package 2',
      product_code: 'Family-Package-2',
      branch: "string", // TODO: Get from context
      customer_guid: customerGuid,
      partner: getPartnerGuid(), // TODO: Get from context/config
      lapse_period: 12,
      lapse_period_granularity: "month",
      lapse_period_cardinality: 1,
      premium_granularity: "year",
      premium_cardinality: 1,
      policy_schedule_entries,
      additional_details: {
        primary_plan: selectedCover.code,
        secondary_plan: null,
        cover_type: members.spouse ? "Member & Family" : "Member",
        extended_members_benefit: null
      }
    };
  };


  const formatPolicyPayload = (quoteData, quoteResponse) => {
    const { members, beneficiary } = quoteData;
    
    return {
      customer_guid: quoteResponse.customer_guid,
      quotation_guid: quoteResponse.guid,
      partner: getPartnerGuid(), // TODO: Get from context
      beneficiary_name: `${beneficiary.first_name} ${beneficiary.last_name}`,
      beneficiary_msisdn: beneficiary.phone,
      web_agent_guid: getUserGuid(), // TODO: Get from context
      debit: true,
      beneficiary_date_of_birth: beneficiary.date_of_birth,
      payment_method: "Mobile_Money",
      additional_details: {
        // application_date: new Date().toISOString().split('T')[0],
        beneficiary_relationship: beneficiary.relationship,
        channel: "ui-quote-creation"
      }
    };
  };

  const formatBankingDetailsPayload = (quoteData, policyResponse) => {
    const { bankingDetails, selectedCover, members } = quoteData;
    
    return {
      

      start_date: new Date().toISOString().split('T')[0],
      msisdn: customerMsisdn,
      bank_account_number: bankingDetails.account_number,
      bank_name: bankingDetails.bank_name,
      branch_code: bankingDetails.branch_code,
      policy_guid: policyResponse.guid,
      granularity: "Year",
      at: 2,
      mno_reference: `${bankingDetails.phone_number}${bankingDetails.account_number}`,
      amount_in_cents: Number(selectedCover.premium_amount) * 100, // Convert premium to cents
      account_holder_name: members.mainMember.first_name,
      branch_name: 'postbank',
      additional_details: {},
      payment_type: "bank_transfer",
      channel: "ApiClient",
    };
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <SelectCover 
            product={product}
            onSelect={(cover) => updateQuoteData('selectedCover', cover)}
            onNext={handleNext}
            loading={loading}
          />
        );
      case 1:
        return (
          <AddMembers
            quoteData={quoteData}
            updateQuoteData={updateQuoteData}
            onNext={handleNext}
            onBack={handleBack}
            loading={loading}
            customer={customer}
          />
        );
      case 2:
        return (
          <QuoteDetails 
            quoteData={quoteData}
            onNext={handleQuoteDetailsNext}
            onBack={handleBack}
            loading={loading}
          />
        );
      case 3:
        return (
          <BeneficiaryDetails
            quoteData={quoteData}
            updateQuoteData={updateQuoteData}
            onNext={handleBeneficiaryNext}
            onBack={handleBack}
            loading={loading}
          />
        );
      case 4:
        return (
          <TermsAndConditions
            quoteData={quoteData}
            updateQuoteData={updateQuoteData}
            onNext={handleNext}
            onBack={handleBack}
            loading={loading}
          />
        );
      case 5:
        return (
          <BankingDetails
            quoteData={quoteData}
            updateQuoteData={updateQuoteData}
            onNext={handleBankingDetailsNext}
            onBack={handleBack}
            loading={loading}
          />
        );
      // case 6:
      //   return (
      //     <DocumentUpload
      //       quoteData={quoteData}
      //       updateQuoteData={updateQuoteData}
      //       onNext={handleNext}
      //       onBack={handleBack}
      //       documentUpload={documentUpload}
      //       policyGuid={apiResponses.policyResponse.guid}
      //       loading={loading}
      //     />
      //   );
      case 6:
        return (
          <PolicyCreated
            policy={apiResponses.policyResponse}
            quote={apiResponses.quoteResponse}
            policyHolder={`${quoteData.members.mainMember.first_name} ${quoteData.members.mainMember.last_name}`}
            email={quoteData.members.mainMember.email}
            coverAmount={Number(quoteData.members.mainMember.benefit_amount)}
            monthlyPremium={Number(quoteData.selectedCover.premium_amount)}
            onFinish={() => {
              // Redirect to policies list or dashboard
              window.location.href = '/policies';  // Or use your routing method
            }}
            loading={loading}
          />
        );
      default:
        return 'Unknown step';
    }
  };

  return (
    <Container maxWidth="lg">
      {/* Add error alert */}
      {/* {error && (
        <Alert 
          severity="error" 
          sx={{ 
            marginBottom: 2,
            position: 'fixed',
            top: 20,
            right: 20,
            zIndex: 9999
          }}
          onClose={() => setError(null)}
        >
          {error}
        </Alert>
      )} */}

      {/* Add loading backdrop */}
      <Backdrop
        sx={{ 
          color: '#fff', 
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backgroundColor: 'rgba(0, 0, 0, 0.7)'
        }}
        open={loading}
      >
        <Box sx={{ textAlign: 'center' }}>
          <CircularProgress color="inherit" />
          <Typography 
            sx={{ 
              color: 'white', 
              marginTop: 2,
              fontSize: '1.1rem'
            }}
          >
            Processing...
          </Typography>
        </Box>
      </Backdrop>

      <Paper elevation={3} className={classes.paper}>
        <Box sx={{ width: '100%' }}>
          {/* Header with back button and step title */}
          <Box className={classes.header}>
            {activeStep > 0 && (
              <IconButton 
                onClick={handleBack}
                className={classes.backButton}
              >
                <ArrowBackIcon />
              </IconButton>
            )}
            <Typography variant="h6" className={classes.stepTitle}>
              {steps[activeStep]}
            </Typography>
          </Box>

          {/* Progress bar */}
          <Box sx={{ width: '100%', mb: 2 }}>
            <LinearProgress 
              variant="determinate" 
              value={progress} 
              className={classes.progressBar}
            />
            <Typography variant="body2" className={classes.progressText}>
              Step {activeStep + 1} of {steps.length}
            </Typography>
          </Box>

          {/* Content area */}
          <Box className={classes.contentBox}>
            {getStepContent(activeStep)}
          </Box>
        </Box>
      </Paper>
    </Container>
  );
};

export default QuoteCreation; 