import React from "react";
// { Suspense, lazy } 
import { Route, Router, IndexRoute, hashHistory } from 'react-router';
import ReactGA from 'react-ga';
import {
  ClaimsDashboard, CustomerStatusX, PolicyAdmin, BulkPolicyOnboarding, FailedEnteries, ClaimProcessing, ClaimInitiation,
  BulkPayments, FailedPaymentsEnteries, HomePage, PolicyProcessing,
  KnowledgeBase,
  ViewGuide,
  elasticSearch,
  Death_Claims,
  Disability_Claims,
  Hospital_claims,
  Last_Expense_claims,
  property_claims,
  Reimbursement_claims,
  Device_Damage_Claims,
  Device_theft_Claims,
  Funeral_Claims,
  SimpleClaimProcessing,
  Critical_Illness_Claims,
  Cancer_Care_Claims
} from './components';
import { getAnanlyticsId } from '../lib/monitoring.es6';
// import Main from '../containers/Main/index.es6';


 import Main from '../containers/Main/index.es6'
 import SelectOption from '../containers/SelectOption/index.es6'
 import Admin from '../containers/Admin/index.es6'
 import RegisterCustomer from '../containers/RegisterCustomer/index.es6'

 import CustomerStatus from '../containers/CustomerStatus/index.es6'

 import PremiumList from '../containers/PremiumList/index.es6'
 import PaymentList from '../containers/PaymentList/index.es6'
 import ClaimList from '../containers/ClaimList/index.es6'
 import PolicyList from '../containers/PolicyList/index.es6'
 import BuyPolicy from '../containers/BuyPolicy/index.es6'
 import MakeClaim from '../containers/ClaimSubmission/index.es6'
 import Customer from '../containers/Customer/index.es6'
 import ReportsPremium from '../containers/ReportsPremium/index.es6'
 import PaymentsReport from '../containers/ReportsPayment/index.es6'
 import ReportClaimFinalisation from '../containers/ReportClaimFinalisation/index.es6'
 import PoliciesReports from '../containers/ReportPolicies/index.es6'
 import ReportOpenClaim from '../containers/ReportOpenClaim/index.es6'
 import ReportRegistration from '../containers/ReportRegistration/index.es6'
 import Profile from '../containers/Profile/index.es6'
 import Settings from '../containers/Settings/index.es6'

 import ClaimsClosed from '../containers/ClaimsClosed/index.es6'
//  import ClaimsProcessing from '../containers/ClaimsProcessing/index.es6'
 import ClaimsPayable from '../containers/ClaimsPayable/index.es6'
 import ClaimsOpen from '../containers/ClaimsOpen/index.es6'
 import ClaimsStatus from '../containers/ClaimsStatus/index.es6'
 import ClaimSearch from '../containers/ClaimSearch/index.es6'
 import ClaimCallback from '../containers/ClaimCallback/index.es6'
 import UsersNew from '../containers/UsersNew/UsersNew'
 import LoyaltyEligibility from '../containers/LoyaltyEligibility/index.es6'
 import LoyaltyEligibilityTigo from '../containers/LoyaltyEligibilityTigoRwanda/index.es6'
 import LoyaltyRenewal from '../containers/LoyaltyRenewal/index.es6'
 import DebitOrderManagement from '../containers/DebitOrderManagement/index.es6'
 import DebitOrderTransactions from '../containers/DebitOrderTransactions/index.es6'

 import CustomerDebitOrder from '../containers/CustomerDebitOrder/index.es6'

 import ReportRefund from '../containers/ReportRefund/index.es6'

 import ReportAgent from '../containers/ReportsAgent/index.es6'
 import ReportAgentWeb from '../containers/ReportsAgentWeb/index.es6'
 import ReportPayableClaim from '../containers/ReportsPayableClaims/index.es6'
 import Dashboards from '../containers/Dashboards/index.es6'
 import ReportPaidClaim from '../containers/ClaimPaidReport/index.es6'

 import InternalDashboards from '../containers/InternalDashboard/index.es6'
 import ReportClaimNotification from '../containers/ReportClaimNotification/index.es6'
 import ReportClaimRegister from '../containers/ReportClaimRegister/index.es6'
 import ReportPremiumRegister from '../containers/ReportPremiumRegister/index.es6'
 import ReportSuspendedPolicies from '../containers/ReportSuspendedPolicies/index.es6'
 import ReportPremiumDue from '../containers/ReportPremiumDue/index.es6'
 import ReportPreAuthorization from '../containers/ReportPreAuthorization/index.es6'
 import ReportDeathClaim from '../containers/DeathClaimReport/index.es6'
 import ReportClaimFinalizationReimbursement from '../containers/ReimbursementFinalisationReport/index.es6'
 import ReportFinalizationHospitalClaim from '../containers/HospitalFinalizationClaimReport/index.es6'
 import ReportFinalizationDeathClaim from '../containers/DeathFinalizationClaimReport/index.es6'
 import ReportHospitalClaim from '../containers/HospitalClaimReport/index.es6'
 import ReportClaimReimbursement from '../containers/ReimbursementReport/index.es6'
 import PageNotFound from '../containers/PageNotFound/index.es6'
 import BulkSms from '../containers/BulkSms/index.es6'
 import Partners from '../containers/Partners/index.es6'
 import NewProducts from '../containers/NewProductList/index.es6'
 import ViewProductStatus from '../containers/NewProductList/components/ViewProduct/index'
 import ManualPayment from '../containers/ManualPayment/index.es6'
 import SmsTemplate from '../containers/SmsTemplate/index.es6'
 import BritamLandingPage from '../containers/BritamLandingPage/index.es6'
 import InclusivityAgencyLandingPage from '../containers/InclusivityAgencyLandingPage/index.es6'

 import GroupRegistration from '../containers/Group/GroupRegistration/index.es6'
 import Groups from '../containers/Group/AllGroups/index.es6'
 import MyGroups from '../containers/Group/MyGroups/index.es6'
 import GroupStatus from '../containers/Group/GroupStatus/index.es6'
 import AddGroupMembers from '../containers/Group/AddGroupMembers/index.es6'
 import Members from '../containers/Group/GroupMembers/index.es6'
 import ManualRemittances from "../containers/ManualRemittance/index.es6"
 import Cashback from '../containers/Cashback/index.es6'
 import PartnerStatus from '../containers/PartnerStatus'
 import ViewLanguages from '../containers/Languages/ViewLanguages'
 import DependantsList from '../containers/DependantsList/index.es6'
 import ViewCountries from '../containers/Country/ViewCountries'
 import LandingPage from '../containers/Login/LandingPage/LandingPage'
 import DrawerNew from '../components/Drawer/DrawerNew'
 import ClaimDashboardv2 from '../containers/ClaimDashboardv2/index'
 import SearchResults from '../containers/PolicyV2CustomerStatus/Components/SearchResults/SearchResults'
 import Alerts from '../components/AlertDialogNew/Alerts'
 import DocumentGeneration from "../containers/DocumentGeneration"


 import Hospitals from '../partner-apps/ktda/Hospitals/index'
 import Diagnosis from '../partner-apps/ktda/Diagnosis/index'
 import DailyReports from '../containers/DailyReports'
 import FeatureFlags from '../containers/FeatureFlags'

 import PoliciesDashboard from '../partner-apps/AccessBank/PoliciesDashboard/index.js'

 import PaymentsDashboard from '../partner-apps/AccessBank/PaymentsDashboard/index'
 import PaymentProcessing from '../partner-apps/AccessBank/PaymentProcessing'
 import EmailTemplates from '../containers/EmailTemplates'
 import ReportsConfigs from '../containers/ReportsConfigs'
 import Reports from '../containers/Reports'
 import PostHogPageviewTracker from '../PostHogPageviewTracker.js'
import { isPolicyWithSpecialChars } from "../lib/utils.es6.js";

import ClaimInitiationMobile from "../shared-ui/MobileCustomerProfileInc/Components/ClaimINitiationMobile/index.js";
// import ClaimsProcessingRefactored from "../containers/ClaimsProcessingRefactored/index.js";

// Initialize Google Analyticts
const ANALYTICS_ID = getAnanlyticsId();
ReactGA.initialize(ANALYTICS_ID, 'auto');
function logPageView() {
  ReactGA.set({ page: window.location.hash });
  ReactGA.pageview(window.location.hash);
}

export default (
      // <Suspense fallback={<div>Loading...</div>}>
  <div>
    <Router onUpdate={logPageView} history={hashHistory}>
      {/* Main component will always be rendered */}
      <PostHogPageviewTracker />
      <Route path="/" component={Main}>
        <IndexRoute component={LandingPage} />
        <Route path="britam" component={BritamLandingPage} />
        <Route path="drawer" component={DrawerNew} />
        <Route path="agency" component={InclusivityAgencyLandingPage} />
        <Route path="login" component={LandingPage} />
        <Route path="select" component={SelectOption} />
        <Route path="search-results-v2" component={SearchResults} />
        <Route path="admin" component={Admin}>
          <IndexRoute component={HomePage} />
          <Route path="alerts" component={Alerts} />
          <Route path="policies_dashboard" component={PoliciesDashboard} />
          <Route path="payments_dashboard" component={PaymentsDashboard} />
          <Route path="create-quote/:customer_guid" component={PolicyAdmin} />
          <Route path="admin-home" component={HomePage} />
          <Route path="register" component={RegisterCustomer} />
          <Route path="group-registeration" component={GroupRegistration} />
          <Route path="my-groups" component={MyGroups} />
          <Route path="groups" component={Groups} />
          <Route path="group/:groupId" component={Members} />
          <Route path="group-status/:groupId" component={GroupStatus} />
          <Route path="add-group-members/:groupId" component={AddGroupMembers} />
          <Route path="knowledge_base" component={KnowledgeBase} />
          <Route path="knowledge_base/:id" component={ViewGuide} />
          <Route path="claim-initiation-mobile/:customer_guid" component={ClaimInitiationMobile} />

          <Route path="cashback" component={Cashback} />
          <Route path="claim-dashboard-v2" component={ClaimDashboardv2} />
          <Route path=  "claim-initiation-complex/:customer_guid"   component={ClaimInitiation} />
          <Route path="claim-processing-v2/:claim_guid" component={ClaimProcessing} />
          <Route path={isPolicyWithSpecialChars() ? "policy-processing/*" : "policy-processing/:policy_number"} component={PolicyProcessing} />
          <Route path="payment-processing/:guid" component={PaymentProcessing} />

          <Route path="partners" component={Partners} />
          <Route path="partners/view/partner" component={PartnerStatus} />
          <Route path="status" component={CustomerStatus} />
          {/* <Route path="search" component={Search} /> */}
          <Route path="customer" component={Customer} />
          <Route path="create-hospital" component={Hospitals} />
          <Route path="create-Diagnosis" component={Diagnosis} />
          <Route path="customer_status/:customer_guid" component={CustomerStatusX} />
          <Route path="bulk-policy-onboarding" component={BulkPolicyOnboarding} />
          <Route path="bulk-payments" component={BulkPayments} />
          <Route path="bulk-policy-onboarding/failed-enteries/:guid" component={FailedEnteries} />
          <Route path="bulk-payments/payment_enteries/:guid" component={FailedPaymentsEnteries} />
          <Route path="search" component={elasticSearch} />
          <Route path="home" component={HomePage} />
          <Route path="buy_policy" component={BuyPolicy} />
          <Route path="make_claim" component={MakeClaim} />
          <Route path="new-products" component={NewProducts} />
          <Route path="products/view/product/:productGuid" component={ViewProductStatus} />
          <Route path="products" component={NewProducts} />
          <Route path="premiums" component={PremiumList} />
          <Route path="payments" component={PaymentList} />
          <Route path="claims" component={ClaimList} />
          <Route path="policies" component={PolicyList} />
          <Route path="dependants" component={DependantsList} />
          <Route path="profile" component={Profile} />
          <Route path="settings" component={Settings} />
          <Route path="claims_closed" component={ClaimsClosed} />
          <Route path="claims_dashboard" component={ClaimsDashboard} />

          <Route path="Death_claims" component={Death_Claims} />
          <Route path="Disability_Claims" component={Disability_Claims} />
          <Route path="Hospital_claims" component={Hospital_claims} /> 
          <Route path="Last_Expense" component={Last_Expense_claims} />
          <Route path="property_claims" component={property_claims} />
          <Route path="Device_Damage_claims" component={Device_Damage_Claims} />
          <Route path="Device_Theft_claims" component={Device_theft_Claims} />
          <Route path="Funeral_Claims" component={Funeral_Claims} />
          <Route path="Reimbursement_claims" component={Reimbursement_claims} />
          <Route path="Cancer_Claims" component={Cancer_Care_Claims} />
          

          <Route path="critical_Illness_Claims" component={Critical_Illness_Claims} />

          <Route path="claims_payable" component={ClaimsPayable} />
          <Route path="claims_processing/:claim_guid" component={SimpleClaimProcessing} />
          <Route path="claims_open" component={ClaimsOpen} />
          <Route path="claims_status" component={ClaimsStatus} />
          <Route path="claim_search" component={ClaimSearch} />
          <Route path="claim_callback" component={ClaimCallback} />
          <Route path="daily_reports" component={DailyReports} />
          <Route path="premium_report" component={ReportsPremium} />
          <Route path="payment_report" component={PaymentsReport} />
          <Route
            path="claim_finalisation_report"
            component={ReportClaimFinalisation}
          />
          <Route path="open_claim_report" component={ReportOpenClaim} />
          <Route path="registration_report" component={ReportRegistration} />
          <Route path="users" component={UsersNew} />
          <Route path="country" component={ViewCountries} />
          <Route path="languages" component={ViewLanguages} />
          <Route path="missing/payments" component={ManualPayment} />
          <Route path="view_sms_templates" component={SmsTemplate} />
          <Route path="view_doc_templates" component={DocumentGeneration} />
          <Route path="view_email_templates" component={EmailTemplates} />
          <Route path="view_reports_configs" component={ReportsConfigs} />
          <Route path="reports" component={Reports} />
          <Route path="feature-flags" component={FeatureFlags} />
          <Route path="loyalty_eligibility" component={LoyaltyEligibility} />
          <Route
            path="loyalty_eligibility_tigo"
            component={LoyaltyEligibilityTigo}
          />
          <Route path="loyalty_renewal" component={LoyaltyRenewal} />
          <Route path="customer_debit_orders" component={CustomerDebitOrder} />
          <Route
            path="debit_order_management"
            component={DebitOrderManagement}
          />
          <Route
            path="debit_order_transactions"
            component={DebitOrderTransactions}
          />
          <Route path="created_policies_report" component={PoliciesReports} />
          <Route path="refunds_report" component={ReportRefund} />
          <Route path="vas_agents_report" component={ReportAgent} />
          <Route path="web_agents_report" component={ReportAgentWeb} />
          <Route path="claims_payable_report" component={ReportPayableClaim} />
          <Route path="dashboards" component={Dashboards} />
          <Route path="bulk_sms" component={BulkSms} />
          <Route path="internal_dashboards" component={InternalDashboards} />
          <Route path="claims_paid_report" component={ReportPaidClaim} />
          <Route
            path="claim_notification_report"
            component={ReportClaimNotification}
          />
          <Route path="claim_register_report" component={ReportClaimRegister} />
          <Route
            path="premium_register_report"
            component={ReportPremiumRegister}
          />
          <Route
            path="suspended_policies_report"
            component={ReportSuspendedPolicies}
          />
          <Route path="premium_due_report" component={ReportPremiumDue} />
          <Route path="pre_authorization_report" component={ReportPreAuthorization} />
          <Route path="claim_death_report" component={ReportDeathClaim} />
          <Route path="claim_hospital_report" component={ReportHospitalClaim} />
          <Route path="claim_reimbursement_report" component={ReportClaimReimbursement} />
          <Route path="claim_finalization_death_report" component={ReportFinalizationDeathClaim} />
          <Route path="claim_finalization_hospital_report" component={ReportFinalizationHospitalClaim} />
          <Route path="claim_finalization_reimbursement_report" component={ReportClaimFinalizationReimbursement} />
          <Route path="remittances" component={ManualRemittances} />
          <Route path="*" component={PageNotFound} />
        </Route>
        <Route path="*" component={PageNotFound} />
      </Route>
      <Route path="*" component={PageNotFound} />
    </Router>
  </div>
        // </Suspense>
);