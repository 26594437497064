import React from "react";
import { hashHistory } from 'react-router';
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import BoxInc from "../../../shared-ui/BoxInc/Index";
import ButtonInc from "../../../shared-ui/ButtonInc";
import Batches from "./Components/OnboardingData/Batches";
import Upload from "./Components/Upload/Upload";
import * as actions from "./Redux/actions"
import { hasAuthourity } from "../../../lib/access.es6";


class BulkPayments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openUploadDialog: false,
    };
  }

  componentWillMount() {
    this.getBatches()
  }

  getBatches(page, page_size){
    this.props.dispatch(actions.getBulkPaymentsBatchesRequest({
      page: page || 1,
      page_size: page_size || 10
    }));
  }

  toggleUploadDialog() {
    this.setState({ openUploadDialog: !this.state.openUploadDialog })
  }

  viewFailedEnteries(guid) {
    hashHistory.push(`admin/bulk-payments/payment_enteries/${guid}`)
  }

  handlePageChange = (page, pageSize) => {
    this.getBatches(page, pageSize)
  };

  render() {

    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <BoxInc
              expanded
              title="Bulk Payments"
							hasPermision={hasAuthourity('BULK_ONBOARD_CUSTOMERS')}
              actionButtons={[
                <ButtonInc
                  variant="contained"
                  color="primary"
                  size="large"
                  hasPermision={hasAuthourity('BULK_ONBOARD_CUSTOMERS')}
                  onClick={(event) => {
                    event.stopPropagation()
                    this.getBatches()
                  }}
                >
                  refresh
                </ButtonInc>, 
                <ButtonInc
                  variant="contained"
                  color="primary"
                  size="large"
                  hasPermision={hasAuthourity('BULK_ONBOARD_CUSTOMERS')}
                  onClick={(event) => {
                    event.stopPropagation()
                    this.toggleUploadDialog()
                  }}
                >
                  Upload Payment Schedule
                </ButtonInc>
  
                ]}

            >
              <Batches
                batches={this.props.bulkPaymentsData.batches}
                viewFailedEnteries={this.viewFailedEnteries.bind(this)}
                loading={this.props.bulkPaymentsData.getBatchesLoader}
                onPageChange={this.handlePageChange}
              />

              <Upload
                close={this.toggleUploadDialog.bind(this)}
                open={this.state.openUploadDialog}
                saveBulkPayments={(doc) => this.props.dispatch(actions.saveBulkPaymentsRequest(doc))}
                errror = {this.props.bulkPaymentsData.saveBulkPaymentsError}
              />

            </BoxInc>
          </Grid>
        </Grid>
      </>
    )
  }

}
export default connect((state) => ({
  customerData: state.currentCustomer,
  bulkPaymentsData: state.bulkPayments,
  globalData: state.global,
}))(BulkPayments);