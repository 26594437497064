import React, { forwardRef } from "react";
import PersonalAccidentForm from "./PersonalAccidentForm";
import DeathForm from "./DeathForm";
import HospitalForm from "./HospitalForm";
import PropertyForm from "./PropertyForm";
import IncomeLossForm from "./IncomeLossForm";
import MedicalExpenseForm from "./MedicalExpenseForm";
import BoxInc from "../../../../shared-ui/BoxInc/Index";
import FallbackForm from "./FallbackForm";
import Alert from '@mui/material/Alert';

const Form = forwardRef(({ 
  claim, 
  metaData,
  conditions,
  hospitals,
  rejectionReason,
  refetchClaim 
}, ref) => {

  // Helper to determine if claim type uses hospital form
  const isHospitalBasedClaim = (type) => {
    return ['Hospital', 'Inpatient', 'Maternity', 'Outpatient'].includes(type);
  };

  const renderForm = () => {
    // First check if it's a hospital-based claim
    if (isHospitalBasedClaim(claim.type)) {
      return <HospitalForm 
        claim={claim}
        metaData={metaData}
        conditions={conditions}
        hospitals={hospitals}
        rejectionReason={rejectionReason}
        refetchClaim={refetchClaim}
      />;
    }

    // Then handle other claim types
    switch (claim.type) {
      case "Personal_Accident":
        return <PersonalAccidentForm 
          claim={claim}
          metaData={metaData}
          conditions={conditions}
          hospitals={hospitals}
          rejectionReason={rejectionReason}
          refetchClaim={refetchClaim}
        />;

      case "Property":
        return <PropertyForm 
          claim={claim}
          metaData={metaData}
          conditions={conditions}
          hospitals={hospitals}
          rejectionReason={rejectionReason}
          refetchClaim={refetchClaim}
        />;

      case "Death":
        return <DeathForm 
          claim={claim}
          metaData={metaData}
          conditions={conditions}
          hospitals={hospitals}
          rejectionReason={rejectionReason}
          refetchClaim={refetchClaim}
        />;

      case "Income_Loss":
        return <IncomeLossForm 
          claim={claim}
          metaData={metaData}
          conditions={conditions}
          hospitals={hospitals}
          rejectionReason={rejectionReason}
          refetchClaim={refetchClaim}
        />;

      case "Medical_Expense":
        return <MedicalExpenseForm 
          claim={claim}
          metaData={metaData}
          conditions={conditions}
          hospitals={hospitals}
          rejectionReason={rejectionReason}
          refetchClaim={refetchClaim}
        />;

      default:
        console.warn(`Unknown claim type: ${claim.type}, using fallback form`);
        return <FallbackForm 
          claim={claim}
          metaData={metaData}
          conditions={conditions}
          hospitals={hospitals}
          rejectionReason={rejectionReason}
          refetchClaim={refetchClaim}
        />;
    }
  };

  const getFormTitle = () => {
    // Convert claim type from underscore format to space format
    return claim.type.replace(/_/g, ' ') + " Form";
  };

  return (
    <BoxInc
      title={getFormTitle()}
      expanded
      style={{ marginTop: "40px" }}
      actionButtons={[]}
    >
      <div>
        <div style={{ padding: "1% 0", width: "95%", margin: "auto" }}>
          <Alert severity="error">System Feedback: {claim.feedback.replaceAll('null','')}</Alert>
        </div>
        {renderForm()}
      </div>
    </BoxInc>
  );
});

export default Form;
