import React from 'react'
import Grid from "@material-ui/core/Grid";
import { useStyle } from "./Style";
import { getPartner, getPartnerGuid } from '../../lib/access.es6';

const ViewLoanDetailsInc = (props) => {
  const classes = useStyle();


  const currencyCode = getPartner().country.currency_code
  const loanDetails = props.LoanDetails ? props.LoanDetails.additional_details : null;

  const parnterGuid = getPartnerGuid();



  if (props.LoanDetails && loanDetails) {
    return (
      <>
   
        {
          (['eunifingh'].includes(parnterGuid)) ? ( <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <div className={classes.policieswrapper}>
                <table>
                  <thead>
                    <tr>
                      <th>Loan Amount</th>
                      <th>Loan Duration </th>
                      <th>Loan End Date</th>
                      <th>Loan Start Date</th>
                      <th>Weekly Installment Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{currencyCode + " " + loanDetails.loan_amount.toLocaleString() || "N/A"}</td>
                      <td>{loanDetails.loan_duration || "N/A"}</td>
                      <td>{loanDetails.loan_end_date || "N/A"}</td>
                      <td>{loanDetails.loan_start_date || "N/A"}</td>
                      <td>{currencyCode + " " + loanDetails.weekly_installment_amount.toLocaleString() || "N/A"}</td>
                    </tr>
                  </tbody>
                </table>
                <br />
              </div>
            </Grid>
          </Grid>)   : (     <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <div className={classes.policieswrapper}>
              <table>
                <thead>
                  <tr>
                    <th>Channel</th>
                    <th>Loan Type</th>
                    <th>Client Rate</th>
                    <th>Loan Amount</th>
                    <th>Loan Duration</th>
                    <th>Loan Interest Rate</th>
                    <th>Loan Maturity Date</th>
                    <th>Loan Disbursement Date</th>
                    <th>Real People Commission</th>
                    <th>Beneficiary National ID</th>
                    <th>Monthly Installment Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{loanDetails.channel || "N/A"}</td>
                    <td>{loanDetails.loan_type || "N/A"}</td>
                    <td>{loanDetails.client_rate || "N/A"}</td>
                    <td>{currencyCode + " " + loanDetails.loan_amount.toLocaleString() || "N/A"}</td>
                    <td>{loanDetails.loan_duration || "N/A"}</td>
                    <td>{loanDetails.loan_interest_rate || "N/A"}</td>
                    <td>{loanDetails.loan_maturity_date || "N/A"}</td>
                    <td>{loanDetails.loan_disbursement_date || "N/A"}</td>
                    <td>{loanDetails.real_people_commission || "N/A"}</td>
                    <td>{loanDetails.beneficiary_national_id || "N/A"}</td>
                    <td>{loanDetails.monthly_installment_amount || "N/A"}</td>
                  </tr>
                </tbody>
              </table>
              <br />
            </div>
          </Grid>
        </Grid>)
        }
      </>
    );
  } else {
    return (
      <div className={classes.policieswrapper}>No Loan Details Found</div>
    );
  }

}

export default ViewLoanDetailsInc