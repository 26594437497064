import React from 'react'
import { SelectProductType } from './SelectProductType';
import CreateQuote from './CreateQuote/Createquote';
export const CreateQuoteSwitch = (props) => {
  const [productType, setProductType] = React.useState('')
  const [productDetails, setProductDetails] = React.useState([])
  const [plans, setPlans] =  React.useState([])
  const [productCode, setProductCode] = React.useState('')



  


  if (!productType) {
    return <SelectProductType
      setProductType={setProductType}
      productType={productType}
      productDetails={productDetails}
      setProductDetails={setProductDetails}
      products={props.products}
      setPlans={setPlans}
      plans={plans}
      setProductCode={setProductCode}
    />

  } else {

    return <CreateQuote {...props} productType={productType} setProductType={setProductType} productDetails={productDetails} setProductDetails={setProductDetails} createQuoteError={props.createQuoteError}
    
    setPlans={setPlans}
    plans={plans}
    productCode={productCode}
    />

  }

}

