import React from 'react'
import Grid from "@material-ui/core/Grid";
import { useStyle } from "./Style";

import ButtonInc from '../../../../../../shared-ui/ButtonInc';
import { AddCircleRounded } from '@mui/icons-material';
//import { membersTotalPremiums } from '../../../helpers';

export default function ViewMembers(props) {

  const classes = useStyle();

  // const nuclear_members = props.dependants.filter(member => ['Principal', 'Spouse', 'Child'].includes(member.relationship))
  // const parent_members = props.dependants.filter(member => ['Parent', 'Parent_In_Law'].includes(member.relationship))
  // const extended_members = props.dependants.filter(member => !['Parent', 'Parent_In_Law','Principal', 'Spouse', 'Child'].includes(member.relationship))

  //const spouse_member = props.dependants.filter(member => member.relationship === 'Spouse')[0]
  //console.log('parent_members: ', parent_members);

  //const nuclearTotalPremiums = membersTotalPremiums(nuclear_members)
  //const parentTotalPremiums = membersTotalPremiums(parent_members)
  //console.log('parentTotalPremiums: ', parentTotalPremiums);

  //const extendedTotalPremiums = membersTotalPremiums(extended_members)

  console.log('view members: ', props.dependants);


  return (
    <div>
      {
        props.dependants.length > 0 &&
        <section className={classes.dependantdetailstable}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <h6>
                {props.productCode === "chp_care_benefit" ? 'Member Details' : `members - (${props.selectedPlan.name})`}

              </h6>
            </Grid>
            <Grid item xs={3} style={{ textAlign: 'left', marginTop: '2px' }} className={classes.addMember}>
              <ul >
                <li>Add More Members</li>
                <li style={{
                  marginLeft: "5px",
                  marginTop: "-4px",
                }}>     <AddCircleRounded
                    style={{ cursor: "pointer", margin: 0 }}
                    onClick={() => props.addAnotherMember()}
                  /></li>
              </ul>

            </Grid>
            <Grid item xs={6} style={{ textAlign: 'right', marginTop: '2px' }}>
              {/* {
                props.productCode === "chp_care_benefit" ? (<></>) : (<>
                  <h6 style={{ textAlign: 'right' }} >  Members Premiums: {props.currencyCode}  { props.monthlyPremium}</h6>
                </>)
              } */}

            </Grid>

            <Grid item xs={12} sm={12} md={12}>

              <div className={classes.dependantWrapper}>

                <table>
                  <tr>
                    <th>Dependant Name</th>
                    <th>Relationship</th>
                    <th>Additional Benefits </th>
                    <th>Action</th>
                  </tr>
                  {props.dependants.map((d, index) => {
                    return (
                      <tr key={index}>
                        <td>{d.first_name + " " + d.last_name}</td>
                        <td>{d.relationship}</td>
                        <td>
                          <ul>
                            <div style={{ display: 'flex' }}>
                              <div style={{ width: '80%' }}>
                                {d.benefits.map((b, i) => {
                                  return (
                                    <li key={i}>
                                      {(b.type + " ").toLowerCase()} --
                                      {
                                        b.type === "PHYSICAL CHECKUP" ? (<>

                                          <strong>{b.benefit_period_cardinality} / {b.benefit_period_granuality} </strong>

                                        </>) : b.type === "TELE CONSULT" ? (<>
                                          <strong>{b.benefit_period_cardinality} / {b.benefit_period_granuality} </strong>

                                        </>) : b.type === "HOSPITAL ADMISSION"  ? (<>
                                         <strong>{ props.currencyCode +
                                              " " +  (b.cover_amount_in_cents /  b.cover_usage_count) / (100).toLocaleString() } / per night</strong> 
                                        
                                        </>)  :  (<>

                                          <strong>
                                            {props.currencyCode +
                                              " " +
                                              (b.cover_amount_in_cents / 100).toLocaleString()
                                            }
                                          </strong>
                                        </>)
                                      }

                                    </li>
                                  );
                                })}
                              </div>

                            </div>

                          </ul>

                        </td>
                        <th><ButtonInc style={{ cursor: "pointer" }} onClick={() => { props.removeDependant(d.uuid) }} >remove member</ButtonInc></th>

                      </tr>
                    );
                  })}
                </table>



              </div>
            </Grid>
          </Grid>
        </section>

      }

      {props.dependants.length > 0 &&
        <div className={classes.createquote}>
          <ButtonInc
            variant="contained"
            color="primary"
            size="large"
            onClick={() => props.handleCreateQuote()}
          >
            Create Quote
          </ButtonInc>
        </div>
      }


    </div>
  )
}
