import React, { useState } from 'react';
import { Paper, Typography } from '@mui/material';
import TableInc from '../../../../../shared-ui/TableInc';
import { updateExtendedConfig } from '../../../../../lib/requests.es6';

const PackagesTable = ({ membership_configs, productGuid, refreshProduct }) => {
  const getInitialPackages = () => {
    if (membership_configs && membership_configs.content && membership_configs.content.packages) {
      return membership_configs.content.packages;
    }
    return [];
  };

  const [packages, setPackages] = useState(getInitialPackages());

  const handleRowUpdate = async (newData, oldData) => {
    const updatedPackages = packages.map(pkg => 
      pkg.package_id === oldData.package_id ? newData : pkg
    );

    const payload = {
      product_guid: productGuid,
      ...membership_configs,
      content: {
        ...membership_configs.content,
        packages: updatedPackages
      }
    };

    try {
      await updateExtendedConfig(payload);
      setPackages(updatedPackages);
      refreshProduct(productGuid);
      return true;
    } catch (error) {
      console.error('Error updating package:', error);
      return false;
    }
  };

  const handleRowAdd = async (newData) => {
    const newPackage = {
      ...newData,
      package_id: Math.random().toString(36).substr(2, 9),
      currency: 'UGX',
      premium_frequency: 'Annually'
    };

    const newPackages = [...packages, newPackage];

    const payload = {
      product_guid: productGuid,
      ...membership_configs,
      content: {
        ...membership_configs.content,
        packages: newPackages
      }
    };

    try {
      await updateExtendedConfig(payload);
      setPackages(newPackages);
      refreshProduct(productGuid);
      return true;
    } catch (error) {
      console.error('Error adding package:', error);
      return false;
    }
  };

  const handleRowDelete = async (oldData) => {
    const newPackages = packages.filter(pkg => pkg.package_id !== oldData.package_id);

    const payload = {
      product_guid: productGuid,
      ...membership_configs,
      content: {
        ...membership_configs.content,
        packages: newPackages
      }
    };

    try {
      await updateExtendedConfig(payload);
      setPackages(newPackages);
      refreshProduct(productGuid);
      return true;
    } catch (error) {
      console.error('Error deleting package:', error);
      return false;
    }
  };

  return (
    <Paper sx={{ p: 3 }}>
      <Typography variant="h6" sx={{ mb: 2 }}>
        Packages Configuration
      </Typography>

      <TableInc
        title=""
        columns={[
          { 
            title: 'Package Name', 
            field: 'package_name',
            validate: rowData => rowData.package_name ? '' : 'Package name is required'
          },
          { 
            title: 'Premium Amount', 
            field: 'premium_amount',
            type: 'numeric',
            validate: rowData => rowData.premium_amount ? '' : 'Premium amount is required',
            render: rowData => {
              if (rowData && rowData.currency && rowData.premium_amount) {
                return `${rowData.currency} ${rowData.premium_amount}/-`;
              }
              return '';
            }
          },
          { 
            title: 'Frequency', 
            field: 'premium_frequency',
            editable: 'never',
            defaultValue: 'Annually'
          }
        ]}
        data={packages}
        options={{
          search: true,
          paging: true,
          pageSize: 5,
          actionsColumnIndex: -1
        }}
        editable={{
          onRowAdd: handleRowAdd,
          onRowUpdate: handleRowUpdate,
          onRowDelete: handleRowDelete
        }}
      />
    </Paper>
  );
};

export default PackagesTable; 