export const ClaimStatusEnum =  {
  Pending : 'Pending',
  All_Docs_Pending : 'All_Docs_Pending',
  Docs_Partially_Submitted : 'Docs_Partially_Submitted',
  Awaiting_Confirmation : 'Awaiting_Confirmation',
  Failed_Confirmation : 'Failed_Confirmation',
  Discarded : 'Discarded',
  Decision_Pending : 'Decision_Pending',
  Approved : 'Approved',
  Approved_Paid : 'Approved_Paid',
  Rejected : 'Rejected',
}

export const ClaimActionEnum = {
  complete_docs:  'complete_docs',
  confirm:  'confirm',
  re_confirm:  're_confirm',
  fail_confirm:  'fail_confirm',
  discard:  'discard',
  approve:  'approve',
  approve_paid:  'approve_paid',
  reject:  'reject',
}


export const ClaimTypeEnum = {
  hospital:  'Hospital',
  death:  'Death',
  funeral:  'Funeral',
  deviceDamage:  'Device_Damage',
  permanentDisability:  'Permanent_Disability',
  deviceTheft:  'Device_Theft',
  TemporaryDisability: 'Temporary_Disability',
  LastExpense: 'Last_Expense',
  Property: 'Property',
  Critical_Illness: 'Critical_Illness'
}

export const loanTypesEnum = {
  businessLoans: 'real_people_ke_business_loans',
  MobileShortLoans: 'real_people_ke_mobile_short_loans',
  StaffLoans:  'real_people_ke_staff_loans'
}