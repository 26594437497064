import React from 'react';
import PackagesTable from './components/PackagesTable';
import BenefitsPostbankTable from './components/BenefitsTable';
// import NuclearMembersRelationship from './components/NuclearMembersRelationship';

const OldMutualPostnetBank = ({ product, refreshProduct }) => {
  return (
    <>
      <PackagesTable
        membership_configs={product.membership_configs ? product.membership_configs : {}}
        productGuid={product.guid}
        refreshProduct={refreshProduct}
      />
      <br />
       <BenefitsPostbankTable
        membership_configs={product.membership_configs}
        productGuid={product.guid}
        refreshProduct={refreshProduct}
      />
      {/*
      <br />
      <NuclearMembersRelationship
        membership_configs={product.membership_configs}
        productGuid={product.guid}
        refreshProduct={refreshProduct}
      /> */}
    </>
  );
};

export default OldMutualPostnetBank; 