import React, {useState, useEffect} from 'react'
import TableInc from '../../../../../../shared-ui/TableInc';
import { updateExtendedConfig } from '../../../../../../lib/requests.es6';

const PremiumRates = (props) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (props.membership_configs && props.membership_configs.content) {
      const {  premium_rate } = props.membership_configs.content;

      const combinedData = [{
      
        premium_rate: premium_rate
      }];

      setData(combinedData);
    }
  }, [props.membership_configs]);

  const updateMembershipConfigs = async (newData) => {
    const adjustedPremiumRate = Number(newData.premium_rate) ;

    const payload = {
      "product_guid": props.productGuid,
      ...props.membership_configs,
      "content": {
        ...props.membership_configs.content,
        premium_rate: adjustedPremiumRate
      }
    }
    console.log("payload", payload)
   await updateExtendedConfig(payload)

   window.location.reload(true);
  };
  
  return (
    <TableInc
    title="Premium Rate"
    columns={[

      { title: 'Premium Rate ', field: 'premium_rate' }
    ]}
    data={data}
    options={{
      addRowPosition: 'first',
      showTitle: true,
      actionsColumnIndex: -1,
      pageSize: 1, // Since we have a single row
    }}
    editable={{
      onRowAdd: newData =>
        new Promise((resolve, reject) => {
          console.log("newData", newData)
          setData([newData]); 
          updateMembershipConfigs(newData); 
          resolve();
          resolve();
        }),

      onRowUpdate: (newData, oldData) =>
        new Promise((resolve, reject) => {
          setData([newData]);  
          updateMembershipConfigs(newData);  
          resolve();
        }),
      onRowDelete: oldData =>
        new Promise((resolve, reject) => {
          setData([oldData]);  
          updateMembershipConfigs(oldData);  
          resolve();
        }),
    }}
  />
  )
}

export default PremiumRates