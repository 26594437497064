import React from 'react'
import { useStyle } from "./Style";
import { Grid } from '@mui/material';
import { formatDateLocale } from '../../lib/utils.es6';
import { getPartnerGuid } from '../../lib/access.es6';

const CustomerProfileInc = (props) => {
  const classes = useStyle();
  return (
    <>
      <section style={{ marginTop: "40px" }}>
        <Grid container spacing={2} className={classes.customerdatawrapper}>
          <Grid item xs={12} md={2} sm={2}>
            <h2>{props.labels.firstName} </h2>
            <p>{props.data.first_name || "N/A"}  </p>
          </Grid>
          <Grid item xs={12} md={2} sm={2}>
            <h2>{props.labels.fullName}   </h2>
            <p>{props.data.full_name || "N/A"}  </p>
          </Grid>
          <Grid item xs={12} md={2} sm={2}>
            <h2>{props.labels.surname} </h2>
            <p>{props.data.surname || "N/A"}  </p>
          </Grid>
          <Grid item xs={12} md={2} sm={2}>
            <h2>{props.labels.dateOfBirth}  </h2>
            <p>{formatDateLocale(props.data.date_of_birth) || "N/A"}  </p>
          </Grid>
          <Grid item xs={12} md={2} sm={2}>
            <h2>{props.labels.gender} </h2>
            <p>{props.data.gender || "N/A"}  </p>
          </Grid>
        </Grid>
        <Grid container spacing={2} className={classes.customerdatawrapper}>
          <Grid item xs={12} md={2} sm={2}>
            <h2>{props.labels.nationalId} </h2>
            <p>{props.data.national_id || "N/A"}  </p>
          </Grid>
          <Grid item xs={12} md={2} sm={2}>
            {
              getPartnerGuid() === 'ngsugguid' ? (<>
                <h2>{"clientUid"} </h2>
                <p>{props.data.msisdn || "N/A"}  </p>
              </>) : (<>

                <h2>{props.labels.phoneNumber} </h2>
                <p>{props.data.msisdn || "N/A"}  </p>
              </>)
            }

          </Grid>
          <Grid item xs={12} md={2} sm={2}>
            <h2>{props.labels.email} </h2>
            <p>{props.data.email || "N/A"}  </p>
          </Grid>
          <Grid item xs={12} md={2} sm={2}>
            <h2>{props.labels.address} </h2>
            <p>{props.data.address || "N/A"}  </p>
          </Grid>
          <Grid item xs={12} md={2} sm={2}>
            <h2> {props.labels.lang}  </h2>
            <p>{props.data.language || "N/A"}  </p>
          </Grid>
        </Grid>
        <Grid container spacing={2} className={classes.customerdatawrapper}>
          <Grid item xs={12} md={2} sm={2}>
            <h2> {props.labels.isAgent}  </h2>
            <p>{props.data.agent ? "Yes" : "No"}  </p>
          </Grid>
          <Grid item xs={12} md={2} sm={2}>
            <h2>{props.labels.createdAt} </h2>
            <p>{formatDateLocale(props.data.created_at) || "N/A"}  </p>
          </Grid>
          <Grid item xs={12} md={2} sm={2}>
            <h2>{props.labels.registrationChannel}</h2>
            <p>{props.data.registration_channel || "N/A"}  </p>
          </Grid>
          <Grid item xs={12} md={2} sm={2}>
            <h2> {"Passport Number"}  </h2>
            <p>{props.data.passport || "N/A"}  </p>
          </Grid>
          <Grid item xs={12} md={2} sm={2}>
            <h2>{"Postal Address"} </h2>
            <p>{props.data.postal_address || "N/A"}  </p>
          </Grid>
        </Grid>
      </section>
    </>
  )
}

export default CustomerProfileInc