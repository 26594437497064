import React, { useState } from 'react';
import { 
  Grid, 
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem 
} from '@mui/material';
import ButtonInc from '../../../../../shared-ui/ButtonInc';
import AlertDialog from '../../../../../components/AlertDialog/index.es6';
import WarningDialog from './WarningDialog';
import { shouldShowWarning } from '../../../lib/helpers';
import { BUTTON_STYLES } from '../../../lib/constants';

const DecisionPending = ({ claim, onStatusChange, labels, rejectionReasons }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [pendingStatus, setPendingStatus] = useState(null);
  const [showRejectionDialog, setShowRejectionDialog] = useState(false);
  const [rejectionReason, setRejectionReason] = useState('');
  const [rejectionComment, setRejectionComment] = useState('');

  const handleStatusChange = async (targetStatus) => {
    if (targetStatus === 'Rejected_Pending') {
      setShowRejectionDialog(true);
      return;
    }

    if (shouldShowWarning(claim, targetStatus)) {
      setPendingStatus(targetStatus);
      setShowWarning(true);
      return;
    }

    await processStatusChange(targetStatus);
  };

  const handleRejectionSubmit = async () => {
    if (!rejectionReason) {
      setErrorMessage('Please select a rejection reason');
      setShowError(true);
      return;
    }

    setShowRejectionDialog(false);
    
    const selectedReason = rejectionReasons.find(r => r.guid === rejectionReason);
    
    const updatedClaim = {
      ...claim,
      rejection_reason: selectedReason.reason,
      comments: rejectionComment || ''
    };
    
    await processStatusChange('Rejected_Pending', updatedClaim);
  };

  const processStatusChange = async (targetStatus, additionalData = {}) => {
    setIsSubmitting(true);
    try {
      await onStatusChange(targetStatus, additionalData);
      setShowSuccess(true);
    } catch (error) {
      const message = error.response && error.response.data ? 
        error.response.data.message || error.response.data.error : 
        'Failed to update status';
      setErrorMessage(message);
      setShowError(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleWarningConfirm = () => {
    setShowWarning(false);
    processStatusChange(pendingStatus);
  };

  return (
    <>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} sm={6} style={{ textAlign: 'right' }}>
          <ButtonInc
            onClick={() => handleStatusChange('Approved_Pending')}
            disabled={isSubmitting 
              // || !hasAuthourity('APPROVE_CLAIM')
            }
            variant="contained"
            style={BUTTON_STYLES.approve}
          >
            {labels.approveClaimBtn}
          </ButtonInc>
        </Grid>
        <Grid item xs={12} sm={6} style={{ textAlign: 'left' }}>
          <ButtonInc
            onClick={() => handleStatusChange('Rejected_Pending')}
            disabled={isSubmitting 
              // || !hasAuthourity('REJECT_CLAIM')
            }
            variant="contained"
            style={BUTTON_STYLES.reject}
          >
            {labels.rejectClaimBtn}
          </ButtonInc>
        </Grid>
      </Grid>

      <Dialog
        open={showRejectionDialog}
        onClose={() => setShowRejectionDialog(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          {labels.claimRejectionReason}
        </DialogTitle>
        <DialogContent>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel>{labels.rejectionReason}</InputLabel>
            <Select
              value={rejectionReason}
              onChange={(e) => setRejectionReason(e.target.value)}
              label={labels.rejectionReason}
              required
            >
              <MenuItem value="">Please Select</MenuItem>
              {rejectionReasons && rejectionReasons.map((reason) => (
                <MenuItem key={reason.guid} value={reason.guid}>
                  {reason.reason}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            fullWidth
            multiline
            rows={4}
            margin="normal"
            label={labels.claimComment}
            value={rejectionComment}
            onChange={(e) => setRejectionComment(e.target.value)}
            name="commentsDialog"
          />
        </DialogContent>
        <DialogActions>
          <ButtonInc
            onClick={() => setShowRejectionDialog(false)}
            btnType="secondary"
          >
            {labels.cancel}
          </ButtonInc>
          <ButtonInc
            onClick={handleRejectionSubmit}
            variant="contained"
            color="error"
          >
            {labels.rejectClaimBtn}
          </ButtonInc>
        </DialogActions>
      </Dialog>

      <AlertDialog
        custom
        show={isSubmitting}
        showConfirm={false}
        showCancel={false}
        showTitle={false}
      >
        <CircularProgress />
      </AlertDialog>

      <WarningDialog
        show={showWarning}
        onConfirm={ () => setShowWarning(false)}
        onCancel={handleWarningConfirm}
        title="Warning"
        message={claim.customer_is_high_risk ? labels.highRiskCustomer : labels.approvedClaimWarning}
        cancelText={labels.proceed}
        confirmText={labels.cancel}
      />

      <AlertDialog
        success
        show={showSuccess}
        onConfirm={() => setShowSuccess(false)}
        confirmBtnText="OK"
        title="Success"
      >
        Status updated successfully
      </AlertDialog>

      <AlertDialog
        error
        show={showError}
        onConfirm={() => setShowError(false)}
        confirmBtnText="OK"
        title="Error"
      >
        {errorMessage}
      </AlertDialog>
    </>
  );
};

export default DecisionPending; 