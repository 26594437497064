import React from "react";
import { connect } from "react-redux";
import ClaimHeader from './Components/ClaimHeader/ClaimHeader'
import ClaimDetails from './Components/ClaimDetails/ClaimDetails'
import ClaimDocuments from './Components/ClaimDocuments/ClaimDocuments'
import ClaimExpenses from './Components/ClaimExpenses/ClaimExpenses'
import ClaimActionButtons from './Components/ClaimActionButtons'
import * as actions from "./Redux/actions"
import BoxInc from "../../../shared-ui/BoxInc/Index";
import ButtonInc from '../../../shared-ui/ButtonInc';
import AddClaimNotes from "./Components/ClaimDetails/AddClaimNotes";
import AddClaimDocuments from "./Components/ClaimDocuments/AddClaimDocuments";
import AddClaimExpense from "./Components/ClaimExpenses/AddClaimExpense";
import DeleteClaimExpense from "./Components/ClaimExpenses/DeleteClaimExpense";
import EditClaimDetails from "./Components/ClaimExpenses/EditClaimDetails";
import DeleteClaimDocument from "./Components/ClaimDocuments/DeleteClaimDocument";
import AddClaimFacilityNotes from "./Components/ClaimDetails/AddClaimFacilityNotes";
import find from 'lodash/find';
import { hasAuthourity } from "../../../lib/access.es6";
import { ClaimTypeEnum } from "../lib/enum";
import { hashHistory } from 'react-router';
import DownloadDocument from "../../../components/DownloadDocument/DownloadDocument";
import * as documentActions from '../../../containers/DocumentGeneration/actions.es6';
import LocalizedStrings from 'react-localization';
import { localisedText } from "../../../lib/localisation.es6";



class ClaimsProcessing extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      labels: new LocalizedStrings(localisedText),
      openClaimNotesDialog: false,
      openClaimFacilityNotesDialog: false,
      openClaimDocumentsDialog: false,
      openClaimExpenseDialog: false,
      openEditClaimExpenseDialog: false,
      description: "",
      expanded: false,
      missingDocs: false,
      claimToDownload: null,
    };
  }

  componentWillMount() {
    let claim_guid = this.props.params.claim_guid
    this.props.dispatch(actions.getClaimProcessingDcpRequest({
      guid: claim_guid,
    }));


  }


  toggleClaimNotesDialog() {
    this.setState({ openClaimNotesDialog: !this.state.openClaimNotesDialog })
  }

  toggleClaimFacilityNotesDialog() {
    this.setState({ openClaimFacilityNotesDialog: !this.state.openClaimFacilityNotesDialog })
  }

  toggleClaimDocumentsDialog() {
    this.setState({ openClaimDocumentsDialog: !this.state.openClaimDocumentsDialog })
  }

  toggleClaimExpenseDialog() {
    this.setState({ openClaimExpenseDialog: !this.state.openClaimExpenseDialog, expanded: true })

  }

  toggleEditClaimExpenseDialog() {
    this.setState({ openEditClaimExpenseDialog: !this.state.openEditClaimExpenseDialog })
  }

  handleClaimExpense(payload) {
    this.props.dispatch(actions.createExpenseRequest(payload))
  }

  setClaimExpenseToDelete(expense) {
    this.props.dispatch(actions.setExpenseToDelete(expense));
  }
  deleteClaimExpense(expense) {
    this.props.dispatch(actions.deleteExpenseRequest({
      claim_guid: this.props.claimProcessingv2Data.claimsProcessingDcp.guid,
      expense_guid: expense.guid
    }));
  }
  resetExpenseToDelete(expense) {
    this.props.dispatch(actions.resetExpenseToDelete(expense))
  }

  setClaimExpenseToEdit(expense) {
    console.log("set claim to edit", expense)
    this.props.dispatch(actions.setExpenseToEdit(expense));
  }

  editClaimExpense(payload) {
    this.props.dispatch(actions.expenseEditRequest(
      payload
    ));
  }
  resetExpenseToEdit(expense) {
    this.props.dispatch(actions.resetExpenseEdit(expense))
  }
  handleClaimNotes(payload) {
    this.props.dispatch(actions.claimNotesRequest(
      payload));
  }

  handleClaimFacilityNotes(payload) {
    this.props.dispatch(actions.claimFacilityNotesRequest(
      payload));
  }

  deleteClaimDocument(document) {
    this.props.dispatch(actions.deleteclaimDocumentsRequest({
      claim_guid: this.props.claimProcessingv2Data.claimsProcessingDcp.guid,
      claim_doc_guid: document.guid
    }));
  }
  setClaimDocumentToDelete(document) {
    console.log("set claimdocument to delete", document)
    this.props.dispatch(actions.setDocumentsToDelete(document));
  }

  resetDocumentToDelete(document) {
    this.props.dispatch(actions.resetDocumentToDelete(document))
  }
  getPreauthDetails(guid) {
    this.props.dispatch(actions.getPreauthDetailsRequest(guid))
  }

  isDeathClaim(claim) {
    const hasDeathBenefit = !!find(claim.benefits, { type: "LAST EXPENSE" })
    return hasDeathBenefit
  }

  isFuneralClaim(claim) {
    const hasFuneralBenefit = !!find(claim.benefits, { type: "LAST EXPENSE" })
    return hasFuneralBenefit
  }
  isDeviceDamageClaim(claim) {
    const hasDeviceDamageBenefit = !!find(claim.benefits, { type: "Device_Damage" })
    return hasDeviceDamageBenefit
  }

  isParmanentDisabilityClaim(claim) {
    const hasParmanentDisabilityBenefit = !!find(claim.benefits, { type: "Permanent_Disability" })
    return hasParmanentDisabilityBenefit
  }

  isDeviceTheftClaim(claim) {
    const hasDeviceTheftBenefit = !!find(claim.benefits, { type: "Device_Theft" })
    return hasDeviceTheftBenefit
  }

  isHospitalClaim(claim) {
    const hasHospitalBenefit = !!find(claim.benefits, { type: "HOSPITAL ADMISSION" })
    return hasHospitalBenefit
  }

  isTemporaryDisability(claim) {
    const hasTemporaryDisabilityBenefit = !!find(claim.benefits, { type: "Temporary_Disability" })
    return hasTemporaryDisabilityBenefit
  }

  isLastExpense (claim) {
    const hasLastExpenseBenefit = !!find(claim.benefits, { type: "LAST EXPENSE" })
    return hasLastExpenseBenefit
  }

  isProperty(claim){
    const hasPropertyBenefit = !!find(claim.benefits, { type: "Property" })
    return hasPropertyBenefit
  }

  isCancer(claim){
    const hasCancerBenefit = !!find(claim.benefits, { type: "CANCER CARE" })
    return hasCancerBenefit
  }


  getClaimType(claim) {
    if (!claim) return ""
    if (this.isDeathClaim(claim)) return ClaimTypeEnum.death
    if (this.isHospitalClaim(claim)) return ClaimTypeEnum.hospital
    if (this.isFuneralClaim(claim)) return ClaimTypeEnum.funeral
    if (this.isDeviceDamageClaim(claim)) return ClaimTypeEnum.deviceDamage
    if (this.isParmanentDisabilityClaim(claim)) return ClaimTypeEnum.permanentDisability
    if (this.isDeviceTheftClaim(claim)) return ClaimTypeEnum.deviceTheft
    if (this.isTemporaryDisability(claim)) return ClaimTypeEnum.TemporaryDisability  
    if (this.isLastExpense(claim)) return ClaimTypeEnum.LastExpense  
    if (this.isProperty(claim)) return ClaimTypeEnum.Property  
    if(this.isCancer(claim)) return ClaimTypeEnum.Cancer  
    
    return ""
  }
  viewCustomerProfile() {
    const customer_guid = this.props.claimProcessingv2Data.claimsProcessingDcp.customer_guid
    if (customer_guid) {
      hashHistory.push('admin/customer_status/' + customer_guid);
    }
  }

  downloadClaimSummary (){
    this.setState({
      claimToDownload: this.props.claimProcessingv2Data.claimsProcessingDcp
    })
  }
  render() {
    const type = this.getClaimType(this.props.claimProcessingv2Data.claimsProcessingDcp);
    const benefits = this.props.claimProcessingv2Data.claimsProcessingDcp ? this.props.claimProcessingv2Data.claimsProcessingDcp.benefits : []
    const hasDeathBenefit = !!find(benefits, { type: "LAST EXPENSE" })
    const hasHospitalBenefit = !!find(benefits, { type: "HOSPITAL ADMISSION" })
    const hasFuneralBenefit = !!find(benefits, { type: "LAST EXPENSE" })
    const hasCancerBenefit = !!find(benefits, { type: "CANCER CARE" }) 
    const hasDeviceDamageBenefit = !!find(benefits, { type: "Device_Damage" })
    const hasDisabilityBenefit = !!find(benefits, { type: "Permanent_Disability" })
    const hasDeviceTheftBenefit = !!find(benefits, { type: "Device_Theft" })
    const hasTemporaryDisabilityBenefit = !!find(benefits, { type: "Temporary_Disability" })
    const hasLastExpenseBenefit =  !!find(benefits, { type: "LAST EXPENSE" })
    const hasPropertyBenefit = !!find(benefits, { type: "Property" })

    const deathBenefit = find(benefits, { type: "LAST EXPENSE" })
    const funeralBenefit = find(benefits, { type: "LAST EXPENSE" })
    const cancerBenefit = find(benefits, { type: "CANCER CARE" }) 
    const deviceDamageBenefit = find(benefits, { type: "Device_Damage" })
    const disabilityBenefit = find(benefits, { type: "Permanent_Disability" })
    const deviceTheftBenefit = find(benefits, { type: "Device_Theft" })
    const TemporaryDisabilityBenefit = find(benefits, { type: "Temporary_Disability" })


    if (!hasAuthourity('VIEW_CLAIM')) {
      return <p>You are unauthorized to view claim.</p>
    }

    if (this.props.claimProcessingv2Data.get_claimsProcessingDcp_loader) {
      return <>
        <p>loading....</p>
      </>
    }
    if (!this.props.claimProcessingv2Data.claimsProcessingDcp) {
      return <>
        <p>nothing found </p>
      </>
    }
    const templateDocuments = this.props.documentGenerationData.templateDocuments;

    return (
      <>
        <ClaimHeader
          claimData={this.props.claimProcessingv2Data.claimsProcessingDcp}

        />
        <BoxInc
          title="Claims Details"
          expanded
          style={{ marginTop: "40px" }}
          actionButtons={[<ButtonInc
            variant="contained"
            color="primary"
            size="large"
            onClick={this.viewCustomerProfile.bind(this)}
          >
            View Customer Profile
          </ButtonInc>]}
        >

          <ClaimDetails
            claim={this.props.claimProcessingv2Data.claimsProcessingDcp}
            toggleClaimNotesDialog={this.toggleClaimNotesDialog.bind(this)}
            openClaimNotesDialog={this.state.openClaimNotesDialog}
            isDeathClaim={this.isDeathClaim.bind(this)}
            isHospitalClaim={this.isHospitalClaim.bind(this)}
            isFuneralClaim={this.isFuneralClaim.bind(this)}
            isDeviceDamageClaim={this.isDeviceDamageClaim.bind(this)}
            isParmanentDisabilityClaim={this.isParmanentDisabilityClaim.bind(this)}
            isDeviceTheftClaim={this.isDeviceTheftClaim.bind(this)}
            isTemporaryDisability={this.isTemporaryDisability.bind(this)}
            isLastExpense={this.isLastExpense.bind(this)}
            isProperty={this.isProperty.bind(this)}
            isCancer={this.isCancer.bind(this)}
            
          />
          <AddClaimFacilityNotes
            toggleClaimFacilityNotesDialog={this.toggleClaimFacilityNotesDialog.bind(this)}
            openClaimFacilityNotesDialog={this.state.openClaimFacilityNotesDialog}

            handleClaimFacilityNotes={this.handleClaimFacilityNotes.bind(this)}
            claimguid={this.props.claimProcessingv2Data.claimsProcessingDcp.guid}

            showCreateFacilityNoteProgressAlert={this.props.claimProcessingv2Data.ClaimFacilityNotesLoader}
            showCreateFacilityNotesSuccessAlert={this.props.claimProcessingv2Data.ClaimFacilityNotesSuccess}
            showCreateFacilityNotesErrorAlert={!!this.props.claimProcessingv2Data.ClaimFacilityNotesError}
            CreateFacilityNotesError={this.props.claimProcessingv2Data.ClaimFacilityNotesError}
            resetCreateFacilityNotesSuccessAlert={() => { this.props.dispatch(actions.resetFacilityClaimNotesSuccess()) }}
            resetCreateFacilityNotesErrorAlert={() => { this.props.dispatch(actions.resetCreateExpenseError()) }}
          />
          <AddClaimNotes

            toggleClaimNotesDialog={this.toggleClaimNotesDialog.bind(this)}
            openClaimNotesDialog={this.state.openClaimNotesDialog}

            handleClaimNotes={this.handleClaimNotes.bind(this)}
            claimguid={this.props.claimProcessingv2Data.claimsProcessingDcp.guid}

            showCreateNoteProgressAlert={this.props.claimProcessingv2Data.ClaimNotesLoader}
            showCreateNotesSuccessAlert={this.props.claimProcessingv2Data.ClaimNotesSuccess}
            showCreateNotesErrorAlert={!!this.props.claimProcessingv2Data.ClaimNotesError}
            CreateNotesError={this.props.claimProcessingv2Data.ClaimNotesError}
            resetCreateNotesSuccessAlert={() => { this.props.dispatch(actions.resetClaimActionSuccess()) }}
            resetCreateNotesErrorAlert={() => { this.props.dispatch(actions.resetFacilityClaimNotesError()) }}
          />


        </BoxInc>
        <BoxInc
          title="Claims Documents "
          style={{ marginTop: "30px" }}

        >
          <AddClaimDocuments
            description={this.state.description}
            toggleClaimDocumentsDialog={this.toggleClaimDocumentsDialog.bind(this)}
            openClaimDocumentsDialog={this.state.openClaimDocumentsDialog}
            claim_guid={this.props.claimProcessingv2Data.claimsProcessingDcp.guid}
            saveClaimDocument={(doc) => this.props.dispatch(actions.saveClaimDcpDocumentRequest(doc))}
            error={this.props.claimProcessingv2Data.saveClaimDcpDocumentError}

          />
          <ClaimDocuments
            setDescription={(description) => this.setState({ description })}
            claimDocuments={this.props.claimProcessingv2Data.claimsProcessingDcp.claim_documents}
            setClaimDocumentToDelete={this.setClaimDocumentToDelete.bind(this)}
            toggleMissingDocs={() => this.setState({ missingDocs: true })}
            missingDocs={this.state.missingDocs}
            openUploadDialog={this.toggleClaimDocumentsDialog.bind(this)}
            type={type}
          />
          <DeleteClaimDocument
            DocumentToDelete={this.props.claimProcessingv2Data.documentsToDelete}
            deleteClaimDocument={this.deleteClaimDocument.bind(this)}


            resetDocumentToDelete={this.resetDocumentToDelete.bind(this)}
            showDeleteDocumentProgressAlert={this.props.claimProcessingv2Data.hardDeleteExpenseLoader}
            showDeleteDocumentSuccessAlert={this.props.claimProcessingv2Data.hardDeleteDocumentSuccess}
            showDeleteDocumentErrorAlert={!!this.props.claimProcessingv2Data.deleteClaimDocumentError}
            deleteDocumentError={this.props.claimProcessingv2Data.deleteClaimDocumentError}

            resetDeleteDocumentSuccessAlert={() => {
              this.props.dispatch(actions.resetHardDeleteDocumentSuccess())
            }}
            resetDeleteDocumentErrorAlert={() => { this.props.dispatch(actions.resetHardDeleteDocumentError()) }}

          />
        </BoxInc>


        <BoxInc
          title={type === ClaimTypeEnum.death ? "Claim Benefits " : " Claim Invoices "}
          expanded={this.state.expanded}
          style={{ marginTop: "30px" }}
          actionButtons={[<ButtonInc
            variant="contained"
            color="primary"
            size="large"
            onClick={this.toggleClaimExpenseDialog.bind(this)}
            hasPermision={hasAuthourity('UPDATE_CLAIM')}
          >
            {
              type === ClaimTypeEnum.death ? "Benefit Amount" : " Add  invoice details"
            }

          </ButtonInc>]}
        >
          <ClaimExpenses
            claimExpense={this.props.claimProcessingv2Data.claimsProcessingDcp}
            setClaimExpenseToDelete={this.setClaimExpenseToDelete.bind(this)}
            setClaimExpenseToEdit={this.setClaimExpenseToEdit.bind(this)}

            toggleEditClaimExpenseDialog={this.toggleEditClaimExpenseDialog.bind(this)}
            openEditClaimExpenseDialog={this.state.openEditClaimExpenseDialog}
            type={type}
            hasDeathBenefit={hasDeathBenefit}
            hasHospitalBenefit={hasHospitalBenefit}
            hasFuneralBenefit={hasFuneralBenefit}
            hasDeviceDamageBenefit={hasDeviceDamageBenefit}
            hasDisabilityBenefit={hasDisabilityBenefit}
            hasDeviceTheftBenefit={hasDeviceTheftBenefit}
            hasTemporaryDisabilityBenefit={hasTemporaryDisabilityBenefit}
            hasPropertyBenefit={hasPropertyBenefit}
            hasCancerBenefit={hasCancerBenefit}

            deathBenefit={deathBenefit}
            funeralBenefit={funeralBenefit}
            deviceDamageBenefit={deviceDamageBenefit}
            disabilityBenefit={disabilityBenefit}
            deviceTheftBenefit={deviceTheftBenefit}
            TemporaryDisabilityBenefit={TemporaryDisabilityBenefit}
            cancerBenefit={cancerBenefit}
            downloadClaimSummary={this.downloadClaimSummary.bind(this)}
          />

          <AddClaimExpense
            toggleClaimExpenseDialog={this.toggleClaimExpenseDialog.bind(this)}
            openClaimExpenseDialog={this.state.openClaimExpenseDialog}
            claim={this.props.claimProcessingv2Data.claimsProcessingDcp}
            handleClaimExpense={this.handleClaimExpense.bind(this)}

            showCreateExpenseProgressAlert={this.props.claimProcessingv2Data.createdClaimExpenseLoader}
            showCreateExpenseSuccessAlert={this.props.claimProcessingv2Data.createdClaimExpenseSuccess}
            showCreateExpenseErrorAlert={!!this.props.claimProcessingv2Data.createdClaimExpenseError}
            CreateExpenseError={this.props.claimProcessingv2Data.createdClaimExpenseError}
            resetCreateExpenseSuccessAlert={() => { this.props.dispatch(actions.resetCreateExpenseSuccess()) }}
            resetCreateExpenseErrorAlert={() => { this.props.dispatch(actions.resetCreateExpenseError()) }}
            type={type}

            hasDeathBenefit={hasDeathBenefit}
            hasHospitalBenefit={hasHospitalBenefit}
            hasFuneralBenefit={hasFuneralBenefit}
            hasDeviceDamageBenefit={hasDeviceDamageBenefit}
            hasDisabilityBenefit={hasDisabilityBenefit}
            hasDeviceTheftBenefit={hasDeviceTheftBenefit}
            hasTemporaryDisabilityBenefit={hasTemporaryDisabilityBenefit}
            hasLastExpenseBenefit={hasLastExpenseBenefit}
            hasPropertyBenefit={hasPropertyBenefit}
            hasCancerBenefit={hasCancerBenefit}
          />
          <DeleteClaimExpense
            expenseToDelete={this.props.claimProcessingv2Data.expenseToDelete}
            deleteClaimExpense={this.deleteClaimExpense.bind(this)}

            resetExpenseToDelete={this.resetExpenseToDelete.bind(this)}
            showDeleteExpenseProgressAlert={this.props.claimProcessingv2Data.hardDeleteExpenseLoader}
            showDeleteExpenseSuccessAlert={this.props.claimProcessingv2Data.hardDeleteExpenseSuccess}
            showDeleteExpenseErrorAlert={!!this.props.claimProcessingv2Data.deleteExpenseErrors}
            deleteExpenseError={this.props.claimProcessingv2Data.deleteExpenseErrors}

            resetDeleteExpenseSuccessAlert={() => {
              this.props.dispatch(actions.resetHardDeleteExpenseSuccess())
            }}
            resetDeleteExpenseErrorAlert={() => { this.props.dispatch(actions.resetHardDeleteExpenseError()) }}
          />

          <EditClaimDetails
            toggleEditClaimExpenseDialog={this.toggleEditClaimExpenseDialog.bind(this)}
            openEditClaimExpenseDialog={this.state.openEditClaimExpenseDialog}
            expenseToEdit={this.props.claimProcessingv2Data.ExpenseToEdit}
            editClaimExpense={this.editClaimExpense.bind(this)}
            resetExpenseToEdit={this.resetExpenseToEdit.bind(this)}

            showEditExpenseProgressAlert={this.props.claimProcessingv2Data.loader}
            showEditExpenseSuccessAlert={this.props.claimProcessingv2Data.editExpenseSuccess}
            showEditExpenseErrorAlert={!!this.props.claimProcessingv2Data.editExpenseErrors}
            editExpenseError={this.props.claimProcessingv2Data.editExpenseErrors}
            resetEditExpenseSuccessAlert={() => {
              this.props.dispatch(actions.resetExpenseEditSuccess())
            }}
            resetEditExpenseErrorAlert={() => { this.props.dispatch(actions.resetExpenseEditError()) }}
            claimguid={this.props.claimProcessingv2Data.claimsProcessingDcp.guid}
          />

        </BoxInc>


        <DownloadDocument
          labels={this.state.labels}
          identifier="Claims"
          entityDocument={
            this.props.documentGenerationData.entityDocument
          }
          entity={this.state.claimToDownload}
          resetEntity={() => this.setState({ claimToDownload: null })}
          templates={templateDocuments}
          getTemplates={() => this.props.dispatch(documentActions.getTemplateDocumentsRequest('Claim'))}
          getTemplatesLoader={this.props.documentGenerationData.getTemplateDocumentloader}
          isEmptyTemplatesResponse={this.props.documentGenerationData.isEmptyTemplates}
          downloadDocument={guid => {
            this.props.dispatch(
              documentActions.downloadDocumentRequest({
                entity_guid: this.state.claimToDownload.guid,
                template_guid: guid,
              })
            );
          }}
          resetGetTemplateDocuments={() => {
            this.props.dispatch(documentActions.resetGetTemplateDocuments());
          }}
          resetDownloadDocument={() => {
            this.props.dispatch(documentActions.resetDocumentEntity());
          }}
          showDownloadDocumentProgressAlert={
            this.props.documentGenerationData.downloadDocumentLoader
          }
          showDownloadDocumentSuccessAlert={
            this.props.documentGenerationData.downloadDocumentSuccess
          }
          showDownloadDocumentErrorAlert={
            !!this.props.documentGenerationData.downloadDocumentError
          }
          downloadDocumentError={
            this.props.documentGenerationData.downloadDocumentError
          }
          resetDownloadDocumentSuccessAlert={() => {
            this.props.dispatch(
              documentActions.resetDownloadDocumentSuccess()
            );
          }}
          resetDownloadDocumentErrorAlert={() => {
            this.props.dispatch(
              documentActions.resetDownloadDocumentError()
            );
          }}

          goToTemplatesPage={() => {
            hashHistory.push('admin/view_doc_templates');
          }}
        />

        {

          hasAuthourity('UPDATE_CLAIM') && <ClaimActionButtons
            claim={this.props.claimProcessingv2Data.claimsProcessingDcp}
            submitClaimAction={(claimActionPayload) => this.props.dispatch(actions.claimActionRequest(claimActionPayload))}
            loading={this.props.claimProcessingv2Data.ClaimActionLoader}
            error={this.props.claimProcessingv2Data.ClaimActionError}
            missingDocs={this.state.missingDocs}
          />

        }


      </>
    )
  }

}
export default connect((state) => ({
  customerData: state.currentCustomer,
  documentGenerationData: state.documentGeneration,
  claimProcessingv2Data: state.claimProcessingv2Data,
  claimDashboardData: state.claimDashboardData,
  globalData: state.global,
}))(ClaimsProcessing);