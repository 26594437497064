import React from 'react';
import ButtonInc from '../../../../../../shared-ui/ButtonInc';
import { COMPONENT_TYPES } from '../../../helpers';


export default function NavButtons({ currentComponent, setCurrentComponent }) {





  const handleNext = () => {
    if (currentComponent === COMPONENT_TYPES.VIEW_MEMBERS) {
   
      setCurrentComponent(COMPONENT_TYPES.VEHICLE_DETAILS);
    }
    if (currentComponent === COMPONENT_TYPES.VEHICLE_DETAILS) {
      setCurrentComponent(COMPONENT_TYPES.DECLARATIONS);

    }

    if(currentComponent === COMPONENT_TYPES.DECLARATIONS){
      setCurrentComponent(COMPONENT_TYPES.SELECT_PAYMENT_PLAN);
    }
  };


  const handleBack = () => {
    if (currentComponent === COMPONENT_TYPES.VEHICLE_DETAILS) {
      setCurrentComponent(COMPONENT_TYPES.VIEW_MEMBERS);
    }
    if (currentComponent === COMPONENT_TYPES.VIEW_MEMBERS) {
      setCurrentComponent(COMPONENT_TYPES.SELECT_PRODUCT_TYPE);
    }
  };

  const disableNextBtn = () => {
    // if (currentComponent === COMPONENT_TYPES.SELECT_COVER_TYPE && !coverType) return true
    // if (currentComponent === COMPONENT_TYPES.SELECT_PLAN && !selectedPlan) return true
    return false
  }


  return (
    // <div style={{ minHeight: '15vh', position: 'relative' }}>

    //   <div style={{ position: 'absolute', bottom: '10px', right: '10px', display: 'flex' }}>
    //     {(currentComponent === COMPONENT_TYPES.VIEW_MEMBERS) &&
    //       <ButtonInc variant="contained" color="primary" onClick={handleBack} style={{ marginRight: '10px' }}>Back</ButtonInc>
    //     }
    //     {(currentComponent === COMPONENT_TYPES.VIEW_MEMBERS || currentComponent === COMPONENT_TYPES.VEHICLE_DETAILS || currentComponent === COMPONENT_TYPES.DECLARATIONS) &&
    //       <ButtonInc variant="contained" color="primary" onClick={handleNext} disabled={disableNextBtn()}>Next</ButtonInc>}
    //   </div>
    // </div>

    <div style={{ minHeight: '15vh', position: 'relative' }}>
  <div style={{ position: 'absolute', bottom: '10px', right: '10px', display: 'flex' }}>
    {(currentComponent === COMPONENT_TYPES.VIEW_MEMBERS || currentComponent === COMPONENT_TYPES.VEHICLE_DETAILS || currentComponent === COMPONENT_TYPES.DECLARATIONS) &&
      <ButtonInc variant="contained" color="primary" onClick={handleBack} style={{ marginRight: '10px' }}>Back</ButtonInc>
    }
    {(currentComponent === COMPONENT_TYPES.VIEW_MEMBERS || currentComponent === COMPONENT_TYPES.VEHICLE_DETAILS || currentComponent === COMPONENT_TYPES.DECLARATIONS) &&
      <ButtonInc variant="contained" color="primary" onClick={handleNext} disabled={disableNextBtn()}>
        Next
      </ButtonInc>
    }
  </div>
</div>
  );
}
