import React from 'react'
import LivesCovered from './Components/LivesCovered'

const MalipowwsConfigs = (props) => {
  return (
    <>
      <LivesCovered
        membership_configs={props.membership_configs}
        productGuid={props.productGuid}
        lives_covered={props.lives_covered}
        extendedConfig={props.extendedConfig}
      />

    </>
  )
}

export default MalipowwsConfigs