
const PRIMARY_THEME_COLOR_SHADE_1 = '#032a37';
const SECONDARY_THEME_COLOR_SHADE_1 = '#fba92c';
const SECONDARY_THEME_COLOR_SHADE_2 = '#008A93';

export const QUOTE_COLORS = {
  PRIMARY: PRIMARY_THEME_COLOR_SHADE_1,
  SECONDARY: SECONDARY_THEME_COLOR_SHADE_1,
  TEXT: {
    PRIMARY: SECONDARY_THEME_COLOR_SHADE_1,
    SECONDARY: SECONDARY_THEME_COLOR_SHADE_2,
    LIGHT: '#ffffff'
  },
  SHADOW: {
    HOVER: 'rgba(3, 42, 55, 0.15)',
    CARD: 'rgba(3, 42, 55, 0.15)'
  },
  BORDER: {
    TRANSPARENT: 'transparent'
  }
};

export const QUOTE_STEPS = {
  SELECT_COVER: 'Select Cover',
  ADD_MEMBERS: 'Add Members',
  QUOTE_DETAILS: 'Quote Details',
  BENEFICIARY_DETAILS: 'Beneficiary Details',
  TERMS_AND_CONDITIONS: 'Terms & Conditions',
  BANKING_DETAILS: 'Banking Details',
  UPLOAD_DOCUMENTS: 'Upload Documents'
}; 