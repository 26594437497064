import React, { useState, useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid';
import { TextValidator, SelectValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Grid from "@material-ui/core/Grid";
import { useStyle } from "./Style";
import MenuItem from '@mui/material/MenuItem';
import Button from "@mui/material/Button";
import {  InputAdornment } from '@mui/material';
import { getLocalNumberLength, getPartner, getPartnerGuid } from '../../../../../../lib/access.es6';
import AlertDialog from '../../../../../../components/AlertDialog/index.es6';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';

import { RELATIONSHIP_POOL } from '../../../../../../containers/NewProductList/PoductConstants';

const Adddependant = (props) => {
  const classes = useStyle();
  const [firstname, setFirstname] = useState('');
  const [middlename, setMiddleName] = useState('');
  const [lastname, setLastname] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [phonenumber, setPhoneNumber] = useState('');
  const [nationalid, setNationalId] = useState('');
  const [passport, setPassport] = useState('');
  const [gender, setGender] = useState('');
  const [residentialAddress, setresidentialAddress] = useState('');
  const [relationship, setRelationship] = useState('');
  const [isCitizenCheck, setIsCitizenCheck] = useState(false)


  const [memberAdded, setMemberAdded] = useState(false)




  useEffect(() => {

    ValidatorForm.addValidationRule('isValidPhoneNumber', value => {
      return value.length === getLocalNumberLength();
    });

    return function cleanup() {
      ValidatorForm.removeValidationRule('isValidPhoneNumber');
    };
  }, [])




  




  useEffect(() => {

    const startingIndex = internationalCallingCode.length
    const endingIndex = props.principal.msisdn.length
    const phone = props.principal.msisdn.substring(startingIndex, endingIndex)

    if (props.autoFillPrincipal) {
      setFirstname(props.principal.first_name);
      setMiddleName('');
      setLastname(props.principal.surname);
      setDateOfBirth(props.principal.date_of_birth);
      setPhoneNumber(phone);
      setNationalId(props.principal.national_id);
      setGender(props.principal.gender);
      setresidentialAddress(props.principal.address || '');
       setRelationship('Principal');
      props.toggleAutoFillPrincipal()
    }
  }, [props.autoFillPrincipal]);






  const handleIsCitizenChange = (event) => {
    setIsCitizenCheck(event.target.checked);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === 'firstname') {
      setFirstname(value)
    }
    if (name === 'middlename') {
      setMiddleName(value)
    }
    if (name === 'lastname') {
      setLastname(value)
    }
    if (name === 'dateOfBirth') {
      setDateOfBirth(value)
    }
    if (name === 'phonenumber') {
      setPhoneNumber(value)
    }
    if (name === 'nationalid') {
      setNationalId(value)
    }
    if (name === 'gender') {
      setGender(value)
    }
    if (name === 'residentialAddress') {
      setresidentialAddress(value)
    }
    if (name === 'relationships') {
      setRelationship(value)
      console.log(value)
    }
    if (name === 'passport') {
      setPassport(value)
    }

  };
  const reset = () => {
    setFirstname('');
    setMiddleName('');
    setLastname('');
    setDateOfBirth('');
    setPhoneNumber('');
    setNationalId('');
    setGender('');
    setresidentialAddress('');
    setRelationship('');
  }




  const addDependant = () => {


    const internationalCallingCode = getPartner().country.international_calling_code


    const chpMemberBenefit = [
      {
        type: "PHYSICAL CHECKUP",
        cover_amount_in_cents: 0,
        waiting_period_in_days: 30,
        cover_usage_count: 1,
        benefit_period_cardinality: 1,
        benefit_period_granuality: "year",
        premium_amount: 0,
        is_initialized: true,
        additional_details: {
          premium_amount: 0,
        }

      },

      {
        type: "TELE CONSULT",
        cover_amount_in_cents: 1,
        waiting_period_in_days: 30,
        cover_usage_count: 0,
        benefit_period_cardinality: 3,
        benefit_period_granuality: "month",
        premium_amount: 0,
        is_initialized: true,
        additional_details: {
          premium_amount: 0,
        }

      },
      {
        type: "HOSPITAL ADMISSION",
        cover_amount_in_cents: 30000 * 100,
        waiting_period_in_days: 30,
        cover_usage_count: 30,
        benefit_period_cardinality: 1,
        benefit_period_granuality: "year",
        premium_amount: 0,
        is_initialized: true,
        additional_details: {
          premium_amount: 0,
        }

      },
      {
        type: "CANCER CARE",
        cover_amount_in_cents: 20000000,
        waiting_period_in_days: 30,
        cover_usage_count: 0,
        benefit_period_cardinality: 1,
        benefit_period_granuality: "year",
        premium_amount: 0,
        is_initialized: true,
        additional_details: {
          premium_amount: 0,
        }

      },
      {
        type: "LAST EXPENSE",
        cover_amount_in_cents: 5000000,
        waiting_period_in_days: 30,
        cover_usage_count: 0,
        benefit_period_cardinality: 1,
        benefit_period_granuality: "year",
        premium_amount: 0,
        is_initialized: true,
        additional_details: {
          premium_amount: 0,
        }

      },
    
    ] 

    const houseHoldMemberBenefit =  [
      {
        type: "EDUCATION",
        cover_amount_in_cents: 0,
        waiting_period_in_days: 30,
        cover_usage_count: 0,
        benefit_period_cardinality: 1,
        benefit_period_granuality: "year",
        premium_amount: 0,
        is_initialized: true,
        additional_details: {
          premium_amount: 0,
        }

      },

      {
        type: "TELE CONSULT",
        cover_amount_in_cents: 0,
        waiting_period_in_days: 30,
        cover_usage_count: 0,
        benefit_period_cardinality: 1,
        benefit_period_granuality: "year",
        premium_amount: 0,
        is_initialized: true,
        additional_details: {
          premium_amount: 0,
        }

      },
      {
        type: "HOSPITAL ADMISSION",
        cover_amount_in_cents: 30000,
        waiting_period_in_days: 30,
        cover_usage_count: 30,
        benefit_period_cardinality: 1,
        benefit_period_granuality: "year",
        premium_amount: 0,
        is_initialized: true,
        additional_details: {
          premium_amount: 0,
        }

      },
      {
        type: "HEALTH CHECK",
        cover_amount_in_cents: 0,
        waiting_period_in_days: 30,
        cover_usage_count: 0,
        benefit_period_cardinality: 1,
        benefit_period_granuality: "year",
        premium_amount: 0,
        is_initialized: true,
        additional_details: {
          premium_amount: 0,
        }

      },
    
    ] 

      // Assign premium based on relationship
  let dependantPremium = 0;
  switch (relationship) {
    case "Principal":
      dependantPremium = 130;
      break;
    case "Spouse":
      dependantPremium = 150;
      break;
    case "Child":
      dependantPremium = 100;
      break;
    default:
      dependantPremium = 0;
  }

    const allbenefits = props.productCode === "chp_care_benefit" ? chpMemberBenefit : houseHoldMemberBenefit

    const totalPremium = props.monthlyPremium

    const member_uuid = uuidv4();
    const payload = {
      "first_name": firstname,
      "middle_name": middlename,
      "last_name": lastname,
      "msisdn": internationalCallingCode + phonenumber,
      "date_of_birth": dateOfBirth,
      "gender": gender,
      "national_id": nationalid || "N/A",
      "passport": passport || "N/A",
      "relationship": relationship,
      "residential_address": residentialAddress,
      "premium_amount_in_cents":
        props.productCode === "chp_care_benefit"
          ? dependantPremium * 100 // Use the ref value
          : totalPremium * 100,
      "allbenefits": allbenefits,
      "benefits": [...allbenefits],
      uuid: member_uuid,
      additional_details: {
        uuid: member_uuid,
      }

    }
    console.log('payload: ', payload);
    const tempDependants = props.dependants;
    tempDependants.push(payload);
    //const replicated = replicatePrincipalBenefits(tempDependants)
    props.setDependants(tempDependants);
    props.createDependant(payload);
    setMemberAdded(true)
    reset();

  }


  const handleDependantCreatedSuccessfully = () => {
    setMemberAdded(false)
  }

  //country calling code
  const internationalCallingCode = getPartner().country.international_calling_code


  //const isExtended = isExtendedMember(props.currentRelationShip)

  return (
    <>


      <AlertDialog
        success
        show={memberAdded}
        size="sm"
        title={'member added'}
        style={{ marginTop: '0', top: '30vh' }}
        onConfirm={() => { handleDependantCreatedSuccessfully() }}
        onCancel={() => { handleDependantCreatedSuccessfully() }}
        confirmBtnText={'Ok'}
        showCancel={false}
        showConfirm={false}
        cancelBtnText={'cancel'}
        confirmBtnCssClass
      >
        <div >
          <Button variant="contained" onClick={() => {
            handleDependantCreatedSuccessfully()
            props.viewMembers()
          }} style={{ background: 'green', }}>view members</Button>
        </div>
      </AlertDialog>

      <AlertDialog
        info
        show={props.depAlert}
        size="sm"
        title={'member  removed'}
        style={{ marginTop: '0', top: '30vh' }}
        onConfirm={() => { props.setDepAlert(false) }}
        confirmBtnText={'ok'}
        showCancel={false}
        cancelBtnText={'cancel'}
        confirmBtnCssClass
      >
      </AlertDialog>

      {/* <AlertDialog
        show={props.showAddDependantErrorAlert}
        danger
        title={'Error creating member'}
        onConfirm={() => handleDependantCreateError()}
        confirmBtnText={'Try again'}
        confirmBtnCssClass
        showCancel={false}
        style={{ marginTop: '0', top: '30vh' }}
      >
        {'error'}
      </AlertDialog> */}

      <ValidatorForm onSubmit={() => addDependant()}   >
        <section className={classes.quoteinputwrapper}>

          <Grid container spacing={2} >
            <Grid item xs={12} sm={12} md={12}>
              <div className={classes.detailstitle}>
                {
                  props.currentRelationShip === 'Principal'
                    ? <h3>Lets add Main member first</h3>
                    : <h3>Add a {props.currentRelationShip} member</h3>

                }
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <div className={classes.checkconditionCitizenship}>
                <FormGroup aria-label="position" row>
                  <FormControlLabel
                    checked={isCitizenCheck}
                    onChange={handleIsCitizenChange}
                    control={<Checkbox size="small" />}
                    label="Is Non Citizen?"
                    labelPlacement="start"
                  />
                </FormGroup>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={2} >
            <Grid item xs={12} sm={4} md={4}>
              <TextValidator
                id="First-Name"
                fullWidth
                label="First Name"
                name='firstname'
                value={firstname}
                onChange={handleChange}
                className={classes.quoteinputs}
                variant="outlined"
                validators={['required']}
                errorMessages={['Please enter first name']}
                style={{ width: "100%" }}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <TextValidator
                id="Middle-Name"
                fullWidth
                label="Middle Name"
                name='middlename'
                value={middlename}
                onChange={handleChange}
                className={classes.quoteinputs}
                variant="outlined"
                style={{ width: "100%" }}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <TextValidator
                id="Last-Name"
                fullWidth
                label="Last Name"
                name='lastname'
                value={lastname}
                onChange={handleChange}
                className={classes.quoteinputs}
                variant="outlined"
                validators={['required']}
                errorMessages={['Please enter Last name']}
                style={{ width: "100%" }}
              />
            </Grid>

            <Grid item xs={12} sm={4} md={4}>
              {
                isCitizenCheck ? (<>

                  <TextValidator
                    name='passport'
                    id="passport"
                    label="Passport No"
                    value={passport}
                    onChange={handleChange}
                    fullWidth
                    variant="outlined"
                    validators={['required']}
                    errorMessages={['Please enter a valid passport number']}
                    style={{ width: "100%" }}
                  />
                </>) : (<>

                  <TextValidator
                    id="National Id"
                    fullWidth
                    type='number'
                    label={getPartnerGuid() === 'aspinplus' ? 'ID Number' : "National ID"}
                    name='nationalid'
                    value={nationalid}
                    onChange={handleChange}
                    className={classes.quoteinputs} 
                    variant="outlined"
                                   style={{ width: "100%" }}
                  />
                </>)
              }

            </Grid>

            <Grid item xs={12} sm={4} md={4}>

              <SelectValidator
                labelId="select-gender"
                id="select-gender"
                value={gender}
                name='gender'
                type="text"
                label="Gender"
                onChange={handleChange}
                variant="outlined"
                validators={['required']}
                errorMessages={['Please select gender']}
                style={{ width: "100%" }}
                sx={{
                  fontFamily: "Open Sans",
                  fontStyle: "normal",
                  fontWeight: 300,
                  fontSize: "12px",
                  lineHeight: "16px",
                  letterSpacing: "0.005em",
                  color: "#000000",
                }}
              >
                <MenuItem sx={{
                  fontFamily: "Open Sans",
                  fontStyle: "normal",
                  fontWeight: 300,
                  fontSize: "12px",
                  lineHeight: "16px",
                  letterSpacing: "0.005em",
                  color: "#000000",
                }} value="Male">Male</MenuItem>
                <MenuItem sx={{
                  fontFamily: "Open Sans",
                  fontStyle: "normal",
                  fontWeight: 300,
                  fontSize: "12px",
                  lineHeight: "16px",
                  letterSpacing: "0.005em",
                  color: "#000000",
                }} value="Female">Female</MenuItem>

              </SelectValidator>
            </Grid>

            <Grid item xs={12} sm={4} md={4}>
              <TextValidator
                id="Date-of-birth"
                label="Date of birth"
                name='dateOfBirth'
                value={dateOfBirth}
                onChange={handleChange}
                fullWidth
                type='date'
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                validators={["required", ]}
                errorMessages={["Date of birth is required"]}
                style={{ width: "100%" }}
              />


            </Grid>

            <Grid item xs={12} sm={4} md={4}>
              <TextValidator
                id="Phone"
                fullWidth
                label="Phone Number"
                name='phonenumber'
                value={phonenumber}
                onChange={handleChange}
                type="number"
                className={classes.quoteinputs}
                InputProps={{
                  startAdornment: <InputAdornment
                    sx={{
                      paddingTop: "32px",
                    }}
                    position="start"
                  >{internationalCallingCode ? internationalCallingCode : ''}</InputAdornment>,
                  style: {
                    background: "#F7F7F7"
                  }
                }}
                variant="outlined"
                validators={phonenumber ? ["isValidPhoneNumber"] : []}
                errorMessages={[`Please enter a valid ${getLocalNumberLength()} digit phone number`]}
                style={{ width: "100%" }}

              />
            </Grid>

            <Grid item xs={12} sm={4} md={4}>
            <SelectValidator
                labelId="select-relationships"
                id="select-relationships"
                value={relationship}
                name='relationships'
                type="text"
                label="Relationship"
                onChange={handleChange}
                variant="outlined"
                validators={['required']}
                errorMessages={['Please select relationship']}
                style={{ width: "100%" }}
                InputLabelProps={{
                  shrink: true,
                }}
              >
                     {Object.entries(RELATIONSHIP_POOL).map(([key, value]) => (
                    <MenuItem
                      key={key}
                      value={value}
                      sx={{
                        fontFamily: "Open Sans",
                        fontStyle: "normal",
                        fontWeight: 300,
                        fontSize: "12px",
                        lineHeight: "16px",
                        letterSpacing: "0.005em",
                        color: "#000000",
                      }}
                    >
                      {value}
                    </MenuItem>
                  ))}
                {
                  

                  // ( gender === 'Male' 
                  //   ? motsheloRelationships.filter(relationship => relationship.gender !== 'Female')
                  //   : gender === 'Female' ? motsheloRelationships.filter(relationship => relationship.gender !== 'Male')
                  //   : motsheloRelationships).map((clanrelationship, index) => (
                  //   <MenuItem
                  //     key={index}
                  //     sx={{
                  //       fontFamily: "Open Sans",
                  //       fontStyle: "normal",
                  //       fontWeight: 300,
                  //       fontSize: "12px",
                  //       lineHeight: "16px",
                  //       letterSpacing: "0.005em",
                  //       color: "#000000",
                  //     }} value={clanrelationship.value}>{clanrelationship.value}</MenuItem>
                  // ))
                }

              </SelectValidator>
         
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <TextValidator
                id="residential-address"
                fullWidth
                label="Residential Address"
                name='residentialAddress'
                value={residentialAddress}
                onChange={handleChange}
                className={classes.quoteinputs}
                variant="outlined"
                style={{ width: "100%" }}
              />
            </Grid>

            {/* {isExtended && <Grid item xs={12}>
              <SelectValidator
                value={props.selectedExtendedBenefit}
                label='Extended Options'
                id="extended-options"
                name='extendedOptions'
                onChange={handleOptionChange} // Use the custom handler
                className="form-control"
                type="text"
                variant="outlined"
                validators={['required']} // Add other validators as needed
                errorMessages={['Please select an option']}
                fullWidth
                style={{ width: '100%' }}
              >
                <MenuItem value="">{'Please select an benefit'}</MenuItem>
                {(props.selectedExtendedBenefit ? [props.selectedExtendedBenefit] : options).map((option) => (
                  <MenuItem key={option.benefit_amount} value={option}>
                    {`Benefit : ${props.currencyCode} ${option.benefit_amount}, Premium : ${props.currencyCode} ${option.premium_amount}`}
                  </MenuItem>
                ))}
              </SelectValidator>
            </Grid>}
            {isExtended && (!dateOfBirth && !props.selectedExtendedBenefit) && <p style={{ color: 'red', margin: '20px' }}>please select date first for benefits to appear</p>} */}

          </Grid>
        </section>


        <section className={classes.benefitswrapper}>



          <hr />


          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <div className={classes.quotesbtns}>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  type='submit'
                //onClick={addDependant}
                >
                  Add Member
                </Button>

                <Button variant="contained" onClick={() => {
                  props.viewMembers()
                }} style={{ background: 'green', }}>Cancel</Button>

              </div>
            </Grid>
          </Grid>
        </section>
      </ValidatorForm >
    </>
  )
}

export default Adddependant