import React from 'react'
import { getPartnerGuid, isFeatureFlagEnabled } from '../lib/access.es6';
import { PARTNERS_GUID_ENUM } from './PartnersEnum';

import { default as ClaimsDashboardDefault } from '../containers/ClaimsDashboard/index.es6';
import { default as ClaimsDashboardKTDA } from "../partner-apps/ktda/ClaimDashboard";
import { default as ClaimsDashboardAccessBank } from '../partner-apps/AccessBank/ClaimDashboard'
import { default as ClaimDashboardBboxx } from '../partner-apps/BboxxKenya/ClaimDashboard'
import { default as ClaimDashboardNGSGroup } from '../partner-apps/NgsUganda/ClaimDashboard/Components/DeathClaims/index'
import { default as ClaimDashboardHe } from '../partner-apps/HealthEntrepreneurs/ClaimDashboard/index'

import { default as CustomerStatusKTDA } from '../containers/PolicyV2CustomerStatus/CustomerStatusv2';
import { default as CustomerStatusDefault } from '../containers/CustomerScreen/index.es6';

import { default as CustomerStatusKonyana } from '../partner-apps/Konyana/CustomerStatus/index'
import { default as CustomerStatusAccessBank } from '../partner-apps/AccessBank/CustomerStatus/index'
import { default as CustomerStatusOrange } from '../partner-apps/Orange/CustomerStatus/index'
import { default as BboxxKenyaCustomerStatus } from '../partner-apps/BboxxKenya/CustomerStatus/index'
import { default as CustomerStatusNGS } from '../partner-apps/NgsUganda/CustomerStatus/index'
import { default as CustomerStatusRealPeople } from '../partner-apps/RealPeople/CustomerStatus/index'
import { default as CustomerStatusIncMotor } from '../partner-apps/InclMotor/CustomerStatus/index'
import { default as CustomerStatusMalipoWws } from '../partner-apps/MalipoWws/CustomerStatus/index'
import { default as CustomerStatusOldMutualUganda } from '../partner-apps/OldMutualUganda-Postbank/CustomerStatus/index'

import { default as PolicyAdminKonyana } from '../partner-apps/Konyana/PolicyAdmin/index'
import { default as PolicyAdminAccessBank } from '../partner-apps/AccessBank/PolicyAdmin/index'
import { default as PolicyAdminIncMotor } from '../partner-apps/InclMotor/PolicyAdmin/index'
import { default as PolicyHolderOldmutualUgandaPostbank } from '../partner-apps/OldMutualUganda-Postbank/PolicyAdmin/index'
import { default as PolicyAdminHealthEnterpreneuers } from '../partner-apps/HealthEntrepreneurs/PolicyAdmin/index'

import { default as BulkPolicyOnboardingKTDA } from '../partner-apps/ktda/BulkPolicyOnboarding/index'
import { default as FailedEnteriesKTDA } from '../partner-apps/ktda/FailedEnteries/index'

import { default as BulkPaymentsKTDA } from '../partner-apps/ktda/BulkPayments/index'
import { default as FailedPaymentsEnteriesKTDA } from '../partner-apps/ktda/FailedPaymentsEnteries/index'

import PageNotFound from '../containers/PageNotFound/index.es6';
import Policyv2 from '../containers/Policyv2';

import { default as ClaimsProcessingKTDA } from '../partner-apps/ktda/ClaimsProcessing/index'
import { default as ClaimsProcessingKonyana } from '../partner-apps/Konyana/ClaimsProcessing/index'
import { default as ClaimsProcessingAccessBank } from '../partner-apps/AccessBank/ClaimsProcessing/index'
import { default as ClaimsProcessingBboxx } from '../partner-apps/BboxxKenya/ClaimsProcessing/index'
import { default as ClaimProcessingNGSGroup } from '../partner-apps/NgsUganda/ClaimsProcessing/index'
import { default as ClaimProcessingRealPeople } from '../partner-apps/RealPeople/ClaimsProcessing/index'
import { default as ClaimProcessingEunifin } from '../partner-apps/EunifinGhana/ClaimsProcessing/index'
import { default as ClaimProcessingHe } from '../partner-apps/HealthEntrepreneurs/ClaimsProcessing/index'

import { default as ClaimInitiationKTDA } from '../partner-apps/ktda/ClaimInitiation/index'
import { default as ClaimInitiationKONYANA } from '../partner-apps/Konyana/ClaimInitiation/index'
import { default as ClaimInitiationAccessBank } from '../partner-apps/AccessBank/ClaimInitiation/index'
import { default as ClaimInitiationBboxx } from '../partner-apps/BboxxKenya/ClaimInitiation/index'
import { default as ClaimInitiationRealPeople } from '../partner-apps/RealPeople/ClaimInitiation/index'
import { default as ClaimInitiationEunifin } from '../partner-apps/EunifinGhana/ClaimInitiation/index'
import { default as ClaimInitiationHe } from '../partner-apps/HealthEntrepreneurs/ClaimInitiation/index'

import { default as OrangeHomePage } from '../partner-apps/Orange/HomePageCta/index'
import { default as KTDAHomePage } from '../partner-apps/ktda/HomePageCta/index'
import { default as KonyanaHomePage } from '../partner-apps/Konyana/HomePageCta/index'
import { default as AccessBankHomePage } from '../partner-apps/AccessBank/HomePageCta/index'
import { default as BboxxHomePage } from '../partner-apps/BboxxKenya/HomePageCta/index'
import SimpleHomePage from '../containers/CustomerScreen/components/HomePageCta/index'
import { default as NGSHomepage } from '../partner-apps/NgsUganda/HomePageCta/index'
import { default as RealPeopleHomepage } from '../partner-apps/RealPeople/HomePageCta/index'
import { default as InclMotorHomepage } from '../partner-apps/InclMotor/HomePageCta/index'
import { default as MalipoWWSHomepage } from '../partner-apps/MalipoWws/HomePageCta/index'
import { default as OldMutualUgandaPostbank } from '../partner-apps/OldMutualUganda-Postbank/HomePageCta/index'

import { default as AccessBankPolicyProcessing } from '../partner-apps/AccessBank/PoliciesProcessing/index'
import { default as BboxxPolicyProcessing } from '../partner-apps/BboxxKenya/PoliciesProcessing/index'
import { default as NGSGroupPolicyProcessing } from '../partner-apps/NgsUganda/PoliciesProcessing/index'
import { default as RealPeoplePolicyProcessing } from '../partner-apps/RealPeople/PoliciesProcessing/index'
import { default as InclMotorPolicyProcessing } from '../partner-apps/InclMotor/PoliciesProcessing/index'
import { default as MalipoWwsPolicyProcessing } from '../partner-apps/MalipoWws/PoliciesProcessing/index'

import { default as KnowledgeBaseAccessBank } from '../partner-apps/AccessBank/Knowledgebase/index'
import { default as ViewGuideAccessBank } from '../partner-apps/AccessBank/Knowledgebase/components/ViewGuide'


import { default as ElasticSearch } from '../containers/ElasticSearch/index'

import { default as RealPeopleDeathClaims } from '../partner-apps/RealPeople/ClaimDashboard/Components/DeathClaims/index'
import { default as RealPeopleDisabilityClaims } from '../partner-apps/RealPeople/ClaimDashboard/Components/DisabilityClaims/index'
import { default as RealPeopleHospitalClaims } from '../partner-apps/RealPeople/ClaimDashboard/Components/HospitalClaims/index'
import { default as RealPeopleLastExpenseClaims } from '../partner-apps/RealPeople/ClaimDashboard/Components/LastExpenseClaims/index'
import { default as RealPeoplePropertyClaims } from '../partner-apps/RealPeople/ClaimDashboard/Components/PropertyClaims/index'
import { default as HospitalClaimDashboardKtda } from '../partner-apps/ktda/ClaimDashboard/Components/HospitalClaims/index'
import { default as DeathClaimsDashboardKtda } from '../partner-apps/ktda/ClaimDashboard/Components/DeathClaims/index'
import { default as DeathClaimsDashboardAccessbank } from '../partner-apps/AccessBank/ClaimDashboard/index'
import { default as ReimbursementClaimsDashboardKtda } from '../partner-apps/ktda/ClaimDashboard/Components/ ReimbursementClaims/index'
import { default as BboxxHospitalClaimsDashboard } from '../partner-apps/BboxxKenya/ClaimDashboard/Components/BboxxHospitalClaims/index'
import { default as BboxxDeathClaimsDashbaord } from '../partner-apps/BboxxKenya/ClaimDashboard/Components/BboxxDeathClaims/index'
import { default as BboxxDeviceDamageClaimsDashboard } from '../partner-apps/BboxxKenya/ClaimDashboard/Components/BboxxDeviceDamageClaims/index'
import { default as BboxxDisabilityClaimsDashboard } from '../partner-apps/BboxxKenya/ClaimDashboard/Components/BboxxDisabilityClaims/index'
import { default as BboxxDeviceTheftClaimsDashboard } from '../partner-apps/BboxxKenya/ClaimDashboard/Components/BboxxDeviceTheftClaims/index'
import { default as PremierCreditDeathClaimsDashboard } from '../partner-apps/PremierCreditZambia/ClaimDashboard/Components/PremierCreditDeathClaims/index'
import { default as PremierCreditFuneralClaimsDashboard } from '../partner-apps/PremierCreditZambia/ClaimDashboard/Components/PremierCreditFuneralClaims/index'
import { default as PremierCreditDisabilityClaimsDashboard } from '../partner-apps/PremierCreditZambia/ClaimDashboard/Components/PremierCreditDisabilityClaims/index'
import { default as HeHospitalClaimsDashboard } from '../partner-apps/HealthEntrepreneurs/ClaimDashboard/Components/HospitalClaims/index'
import { default as HeLastExpenseDasboared } from '../partner-apps/HealthEntrepreneurs/ClaimDashboard/Components/DeathClaims/index'
import { default as HeCancerCareClaims } from '../partner-apps/HealthEntrepreneurs/ClaimDashboard/Components/CancerClaims/index'

import { default as EunifinCriticalIllnessClaims } from '../partner-apps/EunifinGhana/ClaimDashboard/Components/CriticalIllnessClaims/index'
import ClaimsProcessingRefactored from '../containers/ClaimsProcessingRefactored';

const partnerGuid = getPartnerGuid();


export const HomePage = (props) => {
  switch (partnerGuid) {
    case PARTNERS_GUID_ENUM.ORANGE:
      return <OrangeHomePage {...props} />
    case PARTNERS_GUID_ENUM.KTDA:
      return <KTDAHomePage {...props} />
    case PARTNERS_GUID_ENUM.ATLEHANG:
    case PARTNERS_GUID_ENUM.KONYANA:
    case PARTNERS_GUID_ENUM.MANANKIFIN:
    case PARTNERS_GUID_ENUM.FAITH_FUNERALS:
      return <KonyanaHomePage {...props} />
    case PARTNERS_GUID_ENUM.ASPIN_PLUS:
    case PARTNERS_GUID_ENUM.ACCESS_BANK:
    case PARTNERS_GUID_ENUM.INSOLCOMPLEX:
      return <AccessBankHomePage {...props} />
    case PARTNERS_GUID_ENUM.BBOX_KENYA:
    case PARTNERS_GUID_ENUM.BBOX_RWANDA:
    case PARTNERS_GUID_ENUM.PREMIER_CREDIT_ZAMBIA:
      return <BboxxHomePage {...props} />
    case PARTNERS_GUID_ENUM.NGS_UGANDA:
      return <NGSHomepage {...props} />
    case PARTNERS_GUID_ENUM.REAL_PEOPLE:
    case PARTNERS_GUID_ENUM.SHIPRAZOR_SOUTH_AFRICA:
    case PARTNERS_GUID_ENUM.SHIPRAZOR_KENYA:
    case PARTNERS_GUID_ENUM.EUNIFIN_GHANA:
      return <RealPeopleHomepage {...props} />
    case PARTNERS_GUID_ENUM.INCL_MOTOR:
      return <InclMotorHomepage {...props} />

    case PARTNERS_GUID_ENUM.MALIPOWWS:
    case PARTNERS_GUID_ENUM.HEALTH_ENTREPRENEURS:
      return <MalipoWWSHomepage {...props} />

    case PARTNERS_GUID_ENUM.OLDMUTUAL_UGANDA_POSTBANK:
      return <OldMutualUgandaPostbank {...props} />


    default:
      return <SimpleHomePage {...props} />
  }
};



export const SimpleClaimProcessing = (props) => {
  return <ClaimsProcessingRefactored {...props} />
};

export const CustomerStatusX = (props) => {
  switch (partnerGuid) {
    case PARTNERS_GUID_ENUM.KTDA:
      return <CustomerStatusKTDA {...props} />

    case PARTNERS_GUID_ENUM.ATLEHANG:
    case PARTNERS_GUID_ENUM.KONYANA:
    case PARTNERS_GUID_ENUM.MANANKIFIN:
    case PARTNERS_GUID_ENUM.FAITH_FUNERALS:
      return <CustomerStatusKonyana {...props} />

    case PARTNERS_GUID_ENUM.ACCESS_BANK:
    case PARTNERS_GUID_ENUM.INSOLCOMPLEX:
    case PARTNERS_GUID_ENUM.ASPIN_PLUS:
      return <CustomerStatusAccessBank {...props} />

    case PARTNERS_GUID_ENUM.BBOX_KENYA:
    case PARTNERS_GUID_ENUM.BBOX_RWANDA:
    case PARTNERS_GUID_ENUM.PREMIER_CREDIT_ZAMBIA:
      return <BboxxKenyaCustomerStatus {...props} />

    case PARTNERS_GUID_ENUM.ORANGE:
      const FLAG_NAME = 'Orange Customer Status';

      const component = isFeatureFlagEnabled(FLAG_NAME)
        ? <CustomerStatusOrange {...props} />
        : <CustomerStatusDefault {...props} />
      return component

    case PARTNERS_GUID_ENUM.NGS_UGANDA:
      return <CustomerStatusNGS {...props} />

    case PARTNERS_GUID_ENUM.REAL_PEOPLE:
    case PARTNERS_GUID_ENUM.SHIPRAZOR_SOUTH_AFRICA:
    case PARTNERS_GUID_ENUM.SHIPRAZOR_KENYA:
    case PARTNERS_GUID_ENUM.EUNIFIN_GHANA:
      return <CustomerStatusRealPeople {...props} />

    case PARTNERS_GUID_ENUM.INCL_MOTOR:
      return <CustomerStatusIncMotor {...props} />

    case PARTNERS_GUID_ENUM.MALIPOWWS:
    case PARTNERS_GUID_ENUM.HEALTH_ENTREPRENEURS:
      return <CustomerStatusMalipoWws {...props} />

    case PARTNERS_GUID_ENUM.OLDMUTUAL_UGANDA_POSTBANK:
      return <CustomerStatusOldMutualUganda {...props} />




    default:
      return <CustomerStatusDefault {...props} />
  }
};

export const PolicyAdmin = (props) => {
  switch (partnerGuid) {
    case PARTNERS_GUID_ENUM.ATLEHANG:
    case PARTNERS_GUID_ENUM.MANANKIFIN:
    case PARTNERS_GUID_ENUM.KONYANA:
    case PARTNERS_GUID_ENUM.FAITH_FUNERALS:
      return <PolicyAdminKonyana  {...props} />
    case PARTNERS_GUID_ENUM.ACCESS_BANK:
    case PARTNERS_GUID_ENUM.ASPIN_PLUS:
    case PARTNERS_GUID_ENUM.BBOX_KENYA:
    case PARTNERS_GUID_ENUM.BBOX_RWANDA:
    case PARTNERS_GUID_ENUM.INSOLCOMPLEX:
    case PARTNERS_GUID_ENUM.PREMIER_CREDIT_ZAMBIA:
      return <PolicyAdminAccessBank   {...props} />
    case PARTNERS_GUID_ENUM.KTDA:
      return <Policyv2 />

    case PARTNERS_GUID_ENUM.INCL_MOTOR:
      return <PolicyAdminIncMotor {...props} />

    case PARTNERS_GUID_ENUM.OLDMUTUAL_UGANDA_POSTBANK:
      return <PolicyHolderOldmutualUgandaPostbank {...props} />

    case PARTNERS_GUID_ENUM.HEALTH_ENTREPRENEURS:
      return <PolicyAdminHealthEnterpreneuers {...props} />



    default:
      return <CustomerStatusDefault {...props} />
  }
};

export const PolicyProcessing = (props) => {
  switch (partnerGuid) {

    case PARTNERS_GUID_ENUM.ACCESS_BANK:
    case PARTNERS_GUID_ENUM.ASPIN_PLUS:
    case PARTNERS_GUID_ENUM.INSOLCOMPLEX:
    case PARTNERS_GUID_ENUM.OLDMUTUAL_UGANDA_POSTBANK:
      return <AccessBankPolicyProcessing {...props} />
    case PARTNERS_GUID_ENUM.BBOX_KENYA:
    case PARTNERS_GUID_ENUM.BBOX_RWANDA:
    case PARTNERS_GUID_ENUM.PREMIER_CREDIT_ZAMBIA:
      return <BboxxPolicyProcessing {...props} />

    case PARTNERS_GUID_ENUM.NGS_UGANDA:
    case PARTNERS_GUID_ENUM.SHIPRAZOR_SOUTH_AFRICA:
    case PARTNERS_GUID_ENUM.SHIPRAZOR_KENYA:
      return <NGSGroupPolicyProcessing {...props} />

    case PARTNERS_GUID_ENUM.REAL_PEOPLE:
    case PARTNERS_GUID_ENUM.EUNIFIN_GHANA:
      return <RealPeoplePolicyProcessing {...props} />

    case PARTNERS_GUID_ENUM.KTDA:
      return <Policyv2 />

    case PARTNERS_GUID_ENUM.INCL_MOTOR:
      return <InclMotorPolicyProcessing {...props} />

    case PARTNERS_GUID_ENUM.MALIPOWWS:
    case PARTNERS_GUID_ENUM.HEALTH_ENTREPRENEURS:
      return <MalipoWwsPolicyProcessing {...props} />


    default:
      return <CustomerStatusDefault {...props} />
  }
};

export const ClaimsDashboard = (props) => {
  switch (partnerGuid) {
    case PARTNERS_GUID_ENUM.KTDA:
      return <ClaimsDashboardKTDA />
    case PARTNERS_GUID_ENUM.ACCESS_BANK:
    case PARTNERS_GUID_ENUM.ASPIN_PLUS:
    case PARTNERS_GUID_ENUM.INSOLCOMPLEX:
      return <ClaimsDashboardAccessBank />
    case PARTNERS_GUID_ENUM.BBOX_KENYA:
    case PARTNERS_GUID_ENUM.BBOX_RWANDA:
    case PARTNERS_GUID_ENUM.PREMIER_CREDIT_ZAMBIA:
      return <ClaimDashboardBboxx />

    case PARTNERS_GUID_ENUM.NGS_UGANDA:
    case PARTNERS_GUID_ENUM.SHIPRAZOR_SOUTH_AFRICA:
    case PARTNERS_GUID_ENUM.SHIPRAZOR_KENYA:
      return <ClaimDashboardNGSGroup  {...props} />

    case PARTNERS_GUID_ENUM.HEALTH_ENTREPRENEURS:
      return <ClaimDashboardHe  {...props} />


    default:
      return <ClaimsDashboardDefault />
  }
};

export const ClaimInitiation = (props) => {
  switch (partnerGuid) {
    case PARTNERS_GUID_ENUM.ATLEHANG:
    case PARTNERS_GUID_ENUM.MANANKIFIN:
    case PARTNERS_GUID_ENUM.KONYANA:
    case PARTNERS_GUID_ENUM.FAITH_FUNERALS:
      return <ClaimInitiationKONYANA {...props} />
    case PARTNERS_GUID_ENUM.ACCESS_BANK:
    case PARTNERS_GUID_ENUM.INSOLCOMPLEX:
    case PARTNERS_GUID_ENUM.OLDMUTUAL_UGANDA_POSTBANK:
      return <ClaimInitiationAccessBank {...props} />
    case PARTNERS_GUID_ENUM.BBOX_KENYA:
    case PARTNERS_GUID_ENUM.BBOX_RWANDA:
    case PARTNERS_GUID_ENUM.PREMIER_CREDIT_ZAMBIA:
      return <ClaimInitiationBboxx {...props} />

    case PARTNERS_GUID_ENUM.REAL_PEOPLE:
    case PARTNERS_GUID_ENUM.SHIPRAZOR_SOUTH_AFRICA:
    case PARTNERS_GUID_ENUM.SHIPRAZOR_KENYA:
      return <ClaimInitiationRealPeople {...props} />

    case PARTNERS_GUID_ENUM.KTDA:
    case PARTNERS_GUID_ENUM.ASPIN_PLUS:
      return <ClaimInitiationKTDA {...props} />

    case PARTNERS_GUID_ENUM.EUNIFIN_GHANA:
      return <ClaimInitiationEunifin {...props} />

    case PARTNERS_GUID_ENUM.HEALTH_ENTREPRENEURS:
      return <ClaimInitiationHe {...props} />


    default:
      return <ClaimInitiationKTDA {...props} />
  }
};

export const ClaimProcessing = (props) => {
  switch (partnerGuid) {
    case PARTNERS_GUID_ENUM.ATLEHANG:
    case PARTNERS_GUID_ENUM.MANANKIFIN:
    case PARTNERS_GUID_ENUM.KONYANA:
    case PARTNERS_GUID_ENUM.FAITH_FUNERALS:
      return <ClaimsProcessingKonyana {...props} />
    case PARTNERS_GUID_ENUM.ACCESS_BANK:
    case PARTNERS_GUID_ENUM.ASPIN_PLUS:
    case PARTNERS_GUID_ENUM.INSOLCOMPLEX:
    case PARTNERS_GUID_ENUM.OLDMUTUAL_UGANDA_POSTBANK:
      return <ClaimsProcessingAccessBank {...props} />
    case PARTNERS_GUID_ENUM.BBOX_KENYA:
    case PARTNERS_GUID_ENUM.BBOX_RWANDA:
    case PARTNERS_GUID_ENUM.PREMIER_CREDIT_ZAMBIA:
      return <ClaimsProcessingBboxx {...props} />
    case PARTNERS_GUID_ENUM.KTDA:
      return <ClaimsProcessingKTDA {...props} />

    case PARTNERS_GUID_ENUM.NGS_UGANDA:
      return <ClaimProcessingNGSGroup {...props} />


    case PARTNERS_GUID_ENUM.REAL_PEOPLE:
    case PARTNERS_GUID_ENUM.SHIPRAZOR_SOUTH_AFRICA:
    case PARTNERS_GUID_ENUM.SHIPRAZOR_KENYA:
      return <ClaimProcessingRealPeople {...props} />

    case PARTNERS_GUID_ENUM.EUNIFIN_GHANA:
      return <ClaimProcessingEunifin {...props} />

    case PARTNERS_GUID_ENUM.HEALTH_ENTREPRENEURS:
      return <ClaimProcessingHe {...props} />


    default:
      return <ClaimsProcessingKTDA {...props} />
  }
};




export const BulkPolicyOnboarding = () => {
  switch (partnerGuid) {
    case PARTNERS_GUID_ENUM.KTDA:
    case PARTNERS_GUID_ENUM.ACCESS_BANK:
    case PARTNERS_GUID_ENUM.ASPIN_PLUS:
    case PARTNERS_GUID_ENUM.INSOLCOMPLEX:
    case PARTNERS_GUID_ENUM.BBOX_KENYA:
    case PARTNERS_GUID_ENUM.BBOX_RWANDA:
    case PARTNERS_GUID_ENUM.KONYANA:
    case PARTNERS_GUID_ENUM.MANANKIFIN:
    case PARTNERS_GUID_ENUM.FAITH_FUNERALS:
    case PARTNERS_GUID_ENUM.PREMIER_CREDIT_ZAMBIA:
    case PARTNERS_GUID_ENUM.REAL_PEOPLE:
    case PARTNERS_GUID_ENUM.SHIPRAZOR_SOUTH_AFRICA:
    case PARTNERS_GUID_ENUM.SHIPRAZOR_KENYA:
    case PARTNERS_GUID_ENUM.EUNIFIN_GHANA:
      return <BulkPolicyOnboardingKTDA />
    default:
      return <PageNotFound />
  }
};

export const FailedEnteries = (props) => {
  switch (partnerGuid) {
    case PARTNERS_GUID_ENUM.KTDA:
    case PARTNERS_GUID_ENUM.BBOX_KENYA:
    case PARTNERS_GUID_ENUM.BBOX_RWANDA:
    case PARTNERS_GUID_ENUM.KONYANA:
    case PARTNERS_GUID_ENUM.MANANKIFIN:
    case PARTNERS_GUID_ENUM.FAITH_FUNERALS:
    case PARTNERS_GUID_ENUM.PREMIER_CREDIT_ZAMBIA:
    case PARTNERS_GUID_ENUM.ACCESS_BANK:
    case PARTNERS_GUID_ENUM.ASPIN_PLUS:
    case PARTNERS_GUID_ENUM.SHIPRAZOR_SOUTH_AFRICA:
    case PARTNERS_GUID_ENUM.SHIPRAZOR_KENYA:
    case PARTNERS_GUID_ENUM.EUNIFIN_GHANA:
      return <FailedEnteriesKTDA  {...props} />
    default:
      return <FailedEnteriesKTDA  {...props} />

  }
};

// payments
export const BulkPayments = () => {
  switch (partnerGuid) {
    case PARTNERS_GUID_ENUM.KTDA:
    case PARTNERS_GUID_ENUM.INSOLCOMPLEX:
    case PARTNERS_GUID_ENUM.ASPIN_PLUS:
    case PARTNERS_GUID_ENUM.REAL_PEOPLE:
    case PARTNERS_GUID_ENUM.SHIPRAZOR_SOUTH_AFRICA:
    case PARTNERS_GUID_ENUM.SHIPRAZOR_KENYA:
    case PARTNERS_GUID_ENUM.EUNIFIN_GHANA:
      return <BulkPaymentsKTDA />
    default:
      return <BulkPaymentsKTDA />

  }
};

export const FailedPaymentsEnteries = (props) => {
  switch (partnerGuid) {
    case PARTNERS_GUID_ENUM.KTDA:
    case PARTNERS_GUID_ENUM.INSOLCOMPLEX:
    case PARTNERS_GUID_ENUM.ASPIN_PLUS:
    case PARTNERS_GUID_ENUM.REAL_PEOPLE:
    case PARTNERS_GUID_ENUM.SHIPRAZOR_SOUTH_AFRICA:
    case PARTNERS_GUID_ENUM.SHIPRAZOR_KENYA:
    case PARTNERS_GUID_ENUM.EUNIFIN_GHANA:
      return <FailedPaymentsEnteriesKTDA  {...props} />
    default:
      return <FailedPaymentsEnteriesKTDA  {...props} />

  }
};

export const KnowledgeBase = (props) => {
  switch (partnerGuid) {
    case PARTNERS_GUID_ENUM.ACCESS_BANK:
    case PARTNERS_GUID_ENUM.ASPIN_PLUS:
    case PARTNERS_GUID_ENUM.REAL_PEOPLE:
    case PARTNERS_GUID_ENUM.SHIPRAZOR_SOUTH_AFRICA:
    case PARTNERS_GUID_ENUM.SHIPRAZOR_KENYA:
    case PARTNERS_GUID_ENUM.EUNIFIN_GHANA:
      return <KnowledgeBaseAccessBank  {...props} />
    default:
      return <KnowledgeBaseAccessBank  {...props} />
  }
};

export const ViewGuide = (props) => {
  switch (partnerGuid) {
    case PARTNERS_GUID_ENUM.ACCESS_BANK:
    case PARTNERS_GUID_ENUM.ASPIN_PLUS:
    case PARTNERS_GUID_ENUM.REAL_PEOPLE:
    case PARTNERS_GUID_ENUM.SHIPRAZOR_SOUTH_AFRICA:
    case PARTNERS_GUID_ENUM.SHIPRAZOR_KENYA:
    case PARTNERS_GUID_ENUM.EUNIFIN_GHANA:
      return <ViewGuideAccessBank  {...props} />
    default:
      return <ViewGuideAccessBank  {...props} />
  }
};

export const elasticSearch = (props) => {
  switch (partnerGuid) {
    case PARTNERS_GUID_ENUM.ACCESS_BANK:
    case PARTNERS_GUID_ENUM.NGS_UGANDA:
    case PARTNERS_GUID_ENUM.REAL_PEOPLE:
      return <ElasticSearch  {...props} />
    default:
      return <ElasticSearch  {...props} />
  }
};


export const Death_Claims = (props) => {
  switch (partnerGuid) {
    case PARTNERS_GUID_ENUM.REAL_PEOPLE:
    case PARTNERS_GUID_ENUM.EUNIFIN_GHANA:
      return <RealPeopleDeathClaims  {...props} />
    case PARTNERS_GUID_ENUM.KTDA:
      return <DeathClaimsDashboardKtda  {...props} />

    case PARTNERS_GUID_ENUM.ACCESS_BANK:
    case PARTNERS_GUID_ENUM.OLDMUTUAL_UGANDA_POSTBANK:
      return <DeathClaimsDashboardAccessbank  {...props} />

    case PARTNERS_GUID_ENUM.BBOX_RWANDA:
    case PARTNERS_GUID_ENUM.BBOX_KENYA:
      return <BboxxDeathClaimsDashbaord  {...props} />

    case PARTNERS_GUID_ENUM.PREMIER_CREDIT_ZAMBIA:
      return <PremierCreditDeathClaimsDashboard  {...props} />

      case PARTNERS_GUID_ENUM.HEALTH_ENTREPRENEURS:
        return <HeLastExpenseDasboared  {...props} />

      

    default:
      return <PageNotFound  {...props} />

  }

}

export const Disability_Claims = (props) => {
  switch (partnerGuid) {
    case PARTNERS_GUID_ENUM.REAL_PEOPLE:
    case PARTNERS_GUID_ENUM.EUNIFIN_GHANA:
      return <RealPeopleDisabilityClaims  {...props} />
    case PARTNERS_GUID_ENUM.BBOX_RWANDA:
    case PARTNERS_GUID_ENUM.BBOX_KENYA:
      return <BboxxDisabilityClaimsDashboard  {...props} />

    case PARTNERS_GUID_ENUM.PREMIER_CREDIT_ZAMBIA:
      return <PremierCreditDisabilityClaimsDashboard  {...props} />

    default:
      return <PageNotFound  {...props} />
  }
}

export const Hospital_claims = (props) => {
  switch (partnerGuid) {
    case PARTNERS_GUID_ENUM.REAL_PEOPLE:
    case PARTNERS_GUID_ENUM.EUNIFIN_GHANA:
      return <RealPeopleHospitalClaims  {...props} />
    case PARTNERS_GUID_ENUM.KTDA:
      return <HospitalClaimDashboardKtda  {...props} />

    case PARTNERS_GUID_ENUM.BBOX_RWANDA:
    case PARTNERS_GUID_ENUM.BBOX_KENYA:
      return <BboxxHospitalClaimsDashboard  {...props} />

      case PARTNERS_GUID_ENUM.HEALTH_ENTREPRENEURS:
        return <HeHospitalClaimsDashboard  {...props} />
      


    default:
      return <PageNotFound  {...props} />
  }
}


export const Last_Expense_claims = (props) => {
  switch (partnerGuid) {
    case PARTNERS_GUID_ENUM.REAL_PEOPLE:
      return <RealPeopleLastExpenseClaims  {...props} />
    default:
      return <PageNotFound  {...props} />
  }
}

export const property_claims = (props) => {
  switch (partnerGuid) {
    case PARTNERS_GUID_ENUM.REAL_PEOPLE:
      return <RealPeoplePropertyClaims  {...props} />
    default:
      return <PageNotFound  {...props} />
  }
}

export const Reimbursement_claims = (props) => {
  switch (partnerGuid) {
    case PARTNERS_GUID_ENUM.KTDA:
      return <ReimbursementClaimsDashboardKtda  {...props} />
    default:
      return <PageNotFound  {...props} />
  }
}

export const Device_Damage_Claims = (props) => {
  switch (partnerGuid) {

    case PARTNERS_GUID_ENUM.BBOX_RWANDA:
    case PARTNERS_GUID_ENUM.BBOX_KENYA:

      return <BboxxDeviceDamageClaimsDashboard  {...props} />
    default:
      return <PageNotFound  {...props} />
  }

}


export const Device_theft_Claims = (props) => {
  switch (partnerGuid) {

    case PARTNERS_GUID_ENUM.BBOX_RWANDA:
    case PARTNERS_GUID_ENUM.BBOX_KENYA:

      return <BboxxDeviceTheftClaimsDashboard  {...props} />
    default:
      return <PageNotFound  {...props} />
  }

}


export const Funeral_Claims = (props) => {
  switch (partnerGuid) {
    case PARTNERS_GUID_ENUM.PREMIER_CREDIT_ZAMBIA:
      return <PremierCreditFuneralClaimsDashboard  {...props} />
    default:
      return <PageNotFound  {...props} />
  }
}

export const Critical_Illness_Claims = (props) => {
  switch (partnerGuid) {
    case PARTNERS_GUID_ENUM.EUNIFIN_GHANA:
      return <EunifinCriticalIllnessClaims  {...props} />
    default:
      return <PageNotFound  {...props} />
  }
}

export const Cancer_Care_Claims = (props) => {
  switch (partnerGuid) {
    case PARTNERS_GUID_ENUM.HEALTH_ENTREPRENEURS:
      return <HeCancerCareClaims  {...props} />
    default:
      return <PageNotFound  {...props} />
  }
}