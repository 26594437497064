import React from 'react'
import PremiumRatesTable from './Components/PremiumRatesTable/PremiumRatesTable'
import LeviesTables from './Components/LeviesTables/LeviesTables'
import DutiesTable from './Components/DutiesTable/DutiesTable'
import AdditionalBenefitsTable from './Components/AdditionalBenefitsTable/AdditionalBenefitsTable'
import PaymentPlans from './Components/PaymentPlans/PaymentPlans'
import PremiumRatesThirdParty from './Components/PremiumRatesThirdPartyTable/PremiumRatesThirdParty'
const IncMotorConfigs = (props) => {
  return (
    <>


      {
        props.productCode === "Third_Party" ? (<>

          <PremiumRatesThirdParty
            membership_configs={props.membership_configs}
            productGuid={props.productGuid}
          />

        </>) : (<>

          <PremiumRatesTable
            membership_configs={props.membership_configs}
            productGuid={props.productGuid}
          />

        </>)
      }

      <br />
      <LeviesTables
        membership_configs={props.membership_configs}
        productGuid={props.productGuid}
      />
      <br />
      <DutiesTable
        membership_configs={props.membership_configs}
        productGuid={props.productGuid}
      />
      <br />
      <AdditionalBenefitsTable
        membership_configs={props.membership_configs}
        productGuid={props.productGuid}
      />
      <br />
      <PaymentPlans
        membership_configs={props.membership_configs}
        productGuid={props.productGuid}
      />

    </>
  )
}

export default IncMotorConfigs