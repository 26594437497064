import React from 'react';
import { 
  Box, 
  TextField, 
  MenuItem, 
  FormControl,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  Grid,
  Button,
  Tooltip,
  Typography
} from '@mui/material';
import { makeStyles } from "@material-ui/styles";
import ButtonInc from '../../../../../../shared-ui/ButtonInc';
import { AutoFixHigh } from '@mui/icons-material';

const useStyles = makeStyles(() => ({
  formContainer: {
    padding: '1.5rem',
  },
  field: {
    marginBottom: '1rem',
  },
  error: {
    color: 'red',
  },
  buttonContainer: {
    display: 'flex',
    gap: '1rem',
    marginTop: '2rem',
    justifyContent: 'flex-end',
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '24px',
    padding: '0 1.5rem',
    borderBottom: '1px solid #e0e0e0',
    paddingBottom: '16px'
  },
  title: {
    fontSize: '1.25rem',
    fontWeight: 500,
    color: '#333'
  },
  autoFillButton: {
    backgroundColor: '#f5f5f5',
    '&:hover': {
      backgroundColor: '#e0e0e0'
    },
    textTransform: 'none',
    padding: '6px 16px',
    borderRadius: '4px',
    border: '1px solid #ddd',
    boxShadow: 'none',
    '& .MuiButton-startIcon': {
      color: '#666'
    }
  }
}));

const MemberForm = ({ 
  memberType, 
  validationSchema, 
  initialValues = {}, 
  onSubmit, 
  onCancel,
  customer,
  memberCount = {}
}) => {
  const classes = useStyles();
  const [formData, setFormData] = React.useState(() => {
    // Set initial form data with default values from validation schema
    const defaults = {};
    Object.entries(validationSchema.fields).forEach(([fieldName, config]) => {
      if (config.defaultValue) {
        defaults[fieldName] = config.defaultValue;
      }
    });
    return { ...defaults, ...initialValues };
  });
  const [errors, setErrors] = React.useState({});
  const isMainMember = memberType === 'main_member' || memberType === 'principal';

  const calculateAge = (birthDate) => {
    const today = new Date();
    const birth = new Date(birthDate);
    let age = today.getFullYear() - birth.getFullYear();
    const monthDiff = today.getMonth() - birth.getMonth();
    
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birth.getDate())) {
      age--;
    }
    
    return age;
  };

  const validateField = (name, value) => {
    const fieldSchema = validationSchema.fields[name];
    if (!fieldSchema) return null;

    if (fieldSchema.required && !value) {
      return fieldSchema.error_messages.required;
    }

    // Special validation for identification number based on type
    if (name === 'identification_number' && value) {
      const idType = formData.identification_type;
      const validationRules = fieldSchema.validation_rules;
      
      if (idType && validationRules[idType]) {
        const { pattern, error } = validationRules[idType];
        if (!new RegExp(pattern).test(value)) {
          return error;
        }
      }
    } else if (fieldSchema.pattern && !new RegExp(fieldSchema.pattern).test(value)) {
      return fieldSchema.error_messages.pattern;
    }

    if (fieldSchema.min_length && value.length < fieldSchema.min_length) {
      return fieldSchema.error_messages.min_length;
    }

    if (fieldSchema.max_length && value.length > fieldSchema.max_length) {
      return fieldSchema.error_messages.max_length;
    }

    return null;
  };

  const validateAge = (dateOfBirth) => {
    if (!dateOfBirth) return null;
    
    const age = calculateAge(dateOfBirth);
    const { min, max, max_student } = validationSchema.age_limits;

    // For children, check if they're a student
    if (memberType === 'children') {
      const maxAllowedAge = formData.is_student ? max_student : max;
      if (age > maxAllowedAge) {
        return formData.is_student 
          ? `Child must be under ${max_student} years old when in full-time education`
          : `Child must be under ${max} years old`;
      }
    } else if (age < min || age > max) {
      return validationSchema.age_limits.error_message;
    }

    return null;
  };

  const handleChange = (event) => {
    const { name, value, checked } = event.target;
    const newValue = event.target.type === 'checkbox' ? checked : value;
    
    setFormData(prev => ({
      ...prev,
      [name]: newValue
    }));

    // Clear identification number error when identification type changes
    if (name === 'identification_type') {
      setErrors(prev => ({
        ...prev,
        identification_number: null
      }));
    }

    // Revalidate age when is_student changes
    if (name === 'is_student') {
      const ageError = validateAge(formData.date_of_birth);
      setErrors(prev => ({
        ...prev,
        date_of_birth: ageError
      }));
    }

    const error = name === 'date_of_birth' 
      ? validateAge(newValue) 
      : validateField(name, newValue);

    setErrors(prev => ({
      ...prev,
      [name]: error
    }));
  };

  const handleAutoFill = () => {
    if (!customer) return;

    // Get main member field configuration from validationSchema
    const mainMemberFields = validationSchema.fields;

    // Map customer data to match main member field names
    const mappedCustomerData = {
      // Only map fields that exist in the validationSchema
      title: customer.title || '',
      first_name: customer.first_name || '',
      last_name: customer.surname || '', // Map surname to last_name
      date_of_birth: customer.date_of_birth || '', // Dates match
      gender: customer.gender || '',
      email: customer.email || '',
      address: customer.address || '',
      postal_address: customer.postal_address || '',
      contact_number: customer.contact_number || customer.msisdn || '',
      phone_number: customer.msisdn || '',

      // Handle identification fields
      identification_type: 'national_id', // Default to national_id since it's available
      identification_number: customer.national_id || ''
    };

    // Clear any existing errors
    setErrors({});
    
    // Update form data with mapped customer details
    setFormData(prevData => ({
      ...prevData,
      ...mappedCustomerData
    }));

    // Validate the new data
    Object.keys(mappedCustomerData).forEach(fieldName => {
      const error = validateField(fieldName, mappedCustomerData[fieldName]);
      if (error) {
        setErrors(prev => ({
          ...prev,
          [fieldName]: error
        }));
      }
    });

    // Validate age separately
    const ageError = validateAge(mappedCustomerData.date_of_birth);
    if (ageError) {
      setErrors(prev => ({
        ...prev,
        date_of_birth: ageError
      }));
    }
  };

  const renderField = (fieldName) => {
    const fieldConfig = validationSchema.fields[fieldName];
    if (!fieldConfig) return null;

    const commonProps = {
      name: fieldName,
      value: formData[fieldName] || '',
      onChange: handleChange,
      error: !!errors[fieldName],
      helperText: errors[fieldName],
      fullWidth: true,
      className: classes.field,
      label: fieldConfig.placeholder,
      required: fieldConfig.required,
    };

    switch (fieldConfig.type) {
      case 'textField':
        return (
          <TextField
            {...commonProps}
            type={fieldConfig.input_type}
          />
        );

      case 'dropdown':
        return (
          <TextField
            {...commonProps}
            select
          >
            {fieldConfig.options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        );

      case 'datePicker':
        return (
          <TextField
            {...commonProps}
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
          />
        );

      case 'checkbox':
        return (
          <FormControl error={!!errors[fieldName]} className={classes.field}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData[fieldName] || false}
                  onChange={handleChange}
                  name={fieldName}
                />
              }
              label={fieldConfig.label}
            />
            {errors[fieldName] && (
              <FormHelperText>{errors[fieldName]}</FormHelperText>
            )}
          </FormControl>
        );

      default:
        return null;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    // Validate all fields
    const newErrors = {};
    validationSchema.field_order.forEach(fieldName => {
      if (fieldName === 'student_details' && !formData.is_student) return;
      
      const error = validateField(fieldName, formData[fieldName]);
      if (error) newErrors[fieldName] = error;
    });

    const ageError = validateAge(formData.date_of_birth);
    if (ageError) newErrors.date_of_birth = ageError;

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    // Calculate premium based on member type and count
    let premium = 0;
    let premium_in_cents = 0;

    if (memberType === 'main_member' || memberType === 'principal') {
      // Principal member pays full premium
      premium = Number(initialValues.premium_amount || 0);
      premium_in_cents = premium * 100;
    } else {
      // For children, check if it's the 5th or more
      if (memberType === 'children' && memberCount.children >= 4) {
        premium = 1000000;
        premium_in_cents = premium * 100;
      }
      // Other members (spouse, parents) or first 4 children have 0 premium
    }

    onSubmit({
      ...formData,
      premium_amount: premium,
      premium_amount_in_cents: premium_in_cents
    });
  };

  return (
    <Box>
      <Box className={classes.headerContainer}>
        <Typography className={classes.title}>
          {isMainMember ? 'Principal Member Details' : 'Member Details'}
        </Typography>
        
        {isMainMember && customer && (
          <Tooltip title="Auto-fill with existing principal details">
            <Button
              startIcon={<AutoFixHigh fontSize="small" />}
              size="small"
              variant="outlined"
              className={classes.autoFillButton}
              disableElevation
              onClick={handleAutoFill}
            >
              Auto-fill Details
            </Button>
          </Tooltip>
        )}
      </Box>

      <form onSubmit={handleSubmit} className={classes.formContainer}>
        <Grid container spacing={2}>
          {validationSchema.field_order.map((fieldName) => {
            if (fieldName === 'student_details' && !formData.is_student) return null;
            
            if (fieldName === 'student_details') {
              return (
                <Grid item xs={12} key={fieldName}>
                  {Object.keys(validationSchema.fields[fieldName].fields).map(subFieldName => (
                    <Grid item xs={12} key={subFieldName}>
                      {renderField(`${fieldName}.${subFieldName}`)}
                    </Grid>
                  ))}
                </Grid>
              );
            }

            return (
              <Grid item xs={12} sm={6} key={fieldName}>
                {renderField(fieldName)}
              </Grid>
            );
          })}
        </Grid>

        <Box className={classes.buttonContainer}>
          <ButtonInc btnType="secondary" onClick={onCancel}>
            Cancel
          </ButtonInc>
          <ButtonInc type="submit">
            Save Member
          </ButtonInc>
        </Box>
      </form>
    </Box>
  );
};

export default MemberForm; 