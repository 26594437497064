import React from 'react'
import moment from 'moment';
import { useStyle } from "./Style";
import Grid from "@material-ui/core/Grid";
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';

const DeathClaimDetails = (props) => {
  const classes = useStyle();

  const [dateOfDeath, setDateOfDeath] = React.useState('')
  const [causeOfDeath, setCauseOfDeath] = React.useState('')


  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "cause_of_death") {
      handleSubmit(value)
    }

  }


  const handleSubmit = (value) => {

    const tempClaimInitiation = props.claimInitiationPayload;
    tempClaimInitiation["additional_details"] = {
      "age": getAge(),
    };
    props.setClaimInitiationPayload(tempClaimInitiation);
  }

  const getAge = () => {
    const dob = props.member.date_of_birth
    const dateOfDeath = props.eventDate
    const ageAtDeath = moment(dateOfDeath).diff(moment(dob), 'years');
    return ageAtDeath;
  };

  React.useEffect(() => {
    props.setAdditionalState("monthly_installment_amount", props.eventData.additional_details.monthly_installment_amount)
    props.setAdditionalState("beneficiary_national_id", props.eventData.additional_details.beneficiary_national_id)
    props.setAdditionalState("loan_amount", props.eventData.additional_details.loan_amount)
    props.setAdditionalState("product_code", props.eventData.product_code)
    props.setAdditionalState("policy_start_date", props.eventData.start_date)
  }, [props.member.date_of_birth]);


  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <div className={classes.headingclaim}>
            <h4>Deceased details</h4>
          </div>
        </Grid>
      </Grid>
      <ValidatorForm onSubmit={() => { }}>
        <Grid container spacing={2} className={classes.selectpolicyWrapper}>
          <Grid item xs={12} md={4} sm={4}>
            <TextValidator
              id="name"
              style={{ width: "100%" }}
              label="first_name"
              name='first_name'
              value={props.member.first_name}
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12} md={4} sm={4}>
            <TextValidator
              id="middle_name"
              style={{ width: "100%" }}
              label="middle_name"
              name='middle_name'
              value={props.member.middle_name}
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12} md={4} sm={4}>
            <TextValidator
              id="last_name"
              style={{ width: "100%" }}
              label="last_name"
              name='last_name'
              value={props.member.last_name}
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12} md={4} sm={4}>
            <TextValidator
              id="relationship"
              style={{ width: "100%" }}
              label="relationship"
              name='relationship'
              value={props.member.relationship}
              onChange={handleChange}
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12} md={4} sm={4}>
            <TextValidator
              id="gender"
              style={{ width: "100%" }}
              label="gender"
              name='gender'
              value={props.member.gender}
              variant="outlined"
            />
          </Grid>

 

          <Grid item xs={12} md={4} sm={4}>
            <TextValidator
              id="date_of_birth"
              style={{ width: "100%" }}
              label="date_of_birth"
              name='date_of_birth'
              value={props.member.date_of_birth}
              variant="outlined"
              disabled
           //   type='date'
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>



          <Grid item xs={12} md={4} sm={4}>
            <TextValidator
              id="age"
              style={{ width: "100%" }}
              label="age"
              name='age'
              value={getAge()}
              variant="outlined"
            />
          </Grid>

   

        </Grid>

        <hr />

        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <div className={classes.headingclaim}>
              <h4>Claim Information For Death</h4>
            </div>
          </Grid>
        </Grid>

        <Grid container spacing={2} className={classes.selectpolicyWrapper}>

        <Grid item xs={12} md={6} sm={6}>
            <TextValidator
              id="date_of_death"
              style={{ width: "100%" }}
              label="Date Of Death"
              name='date_of_death'
              value={dateOfDeath}
              variant="outlined"
              type='date'
              onChange={(e) => {
                setDateOfDeath(e.target.value)
                props.setAdditionalState("date_of_death", e.target.value)
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12} md={6} sm={6}>
            <TextValidator
              id="cause_of_death"
              style={{ width: "100%" }}
              label="Cause Of Death"
              name='cause_of_death'
              value={causeOfDeath}
              variant="outlined"
              type='text'
              onChange={(e) => {
                setCauseOfDeath(e.target.value)
                props.setAdditionalState("cause_of_death", e.target.value)
              }}
            />
          </Grid>

  


        </Grid>

      </ValidatorForm>
    </>
  )
}

export default DeathClaimDetails

