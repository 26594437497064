import React, { useEffect, useState } from 'react'
import { updateExtendedConfig } from '../../../../../../lib/requests.es6';
import TableInc from '../../../../../../shared-ui/TableInc';

const Benefits = (props) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (props.membership_configs && props.membership_configs.content) {
      const { benefits } = props.membership_configs.content;

      const mappedData = benefits.map(benefit => ({
        type: benefit.type,
        waiting_period_in_days: benefit.waiting_period_in_days,
        cover_usage_count: benefit.cover_usage_count,
      }));

      setData(mappedData);
    }
  }, [props.membership_configs]);

  const updateMembershipConfigs = async (newData) => {
    const adjustedData = newData.map(benefit => ({
      ...benefit,
      waiting_period_in_days: Number(benefit.waiting_period_in_days),
      cover_usage_count: Number(benefit.cover_usage_count),

    }));

    const payload = {
      "product_guid": props.productGuid,
      ...props.membership_configs,
      "content": {
        ...props.membership_configs.content,
        benefits: adjustedData
      }
    }
    await updateExtendedConfig(payload)
    window.location.reload(true);
  };
  return (
    <TableInc
      title="Benefits"
      columns={[
        { title: 'Type', field: 'type' },
        { title: 'Waiting Period In Days', field: 'waiting_period_in_days' },
        { title: 'Cover Usage Count', field: 'cover_usage_count' },
      ]}
      data={data}
      options={{
        addRowPosition: 'first',
        showTitle: true,
        actionsColumnIndex: -1,
        pageSize: data.length > 0 ? data.length : 5, // Show 5 rows or based on data length
      }}
      editable={{
        onRowAdd: newData =>
          new Promise((resolve, reject) => {
            console.log("New Data Added:", newData);

            const newDataArray = [...data, newData];
            console.log("newDataArray", newDataArray)
            setData(newDataArray);
            updateMembershipConfigs(newDataArray);
            resolve();

          }),
        onRowUpdate: (newData, oldData) =>
          new Promise((resolve, reject) => {
            console.log("Row Updated:", newData);
            const dataUpdate = [...data];
            const index = oldData.tableData.id;
            newData.benefit_amount = newData.benefit_amount * 100;
            dataUpdate[index] = newData;
            setData(dataUpdate);
            updateMembershipConfigs(dataUpdate);
            resolve();
          }),
        onRowDelete: oldData =>
          new Promise((resolve, reject) => {
            console.log("Row Deleted:", oldData);
            const dataDelete = [...data];
            const index = oldData.tableData.id;
            dataDelete.splice(index, 1);
            setData(dataDelete);
            updateMembershipConfigs(dataDelete);
            resolve();
            resolve();
          }),
      }}
    />
  )
}

export default Benefits