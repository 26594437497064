
export const PARTNERS_GUID_ENUM =  {
  KTDA : "kymbritam",
  ATLEHANG: "atlehangguid",
  KONYANA: "konyanaguid",
  MANANKIFIN: "manankifinguid",
  ACCESS_BANK: "accessbank",
  BBOX_KENYA: "bboxxkeguid",
  BBOX_RWANDA: "bboxxrwguid",
  ORANGE: "orangeguid",
  INSOLCOMPLEX: "insolguid",
  FAITH_FUNERALS: 'faithfuneralsguid',
  PREMIER_CREDIT_ZAMBIA: "premcredzamguid",
  AIRTEL_ZAMBIA: "airtelzambia",
  ASPIN_PLUS: "aspinplus",
  NGS_UGANDA: "ngsugguid",
  AIRTEL_MALAWI: "airtelmalawiguid",
  REAL_PEOPLE: "realpeopleguid",
  SHIPRAZOR_SOUTH_AFRICA:"shiprazor_sa_guid",
  SHIPRAZOR_KENYA:"shiprazor_ke_guid",
  INCL_MOTOR: "incl_motor",
  EUNIFIN_GHANA: "eunifingh",
  MALIPOWWS: "malipo_wws",
  HEALTH_ENTREPRENEURS: "he_chp",
  OLDMUTUAL_UGANDA_POSTBANK: "omu_posbank"
}


export const CLAIM_TAG_PARTNERS = [ PARTNERS_GUID_ENUM.KTDA ]

export const PARTNERS_WITH_POLICY_NUMBER_SPECIAL_CHARACTORS = [ PARTNERS_GUID_ENUM.NGS_UGANDA]

export const PARTNERS_WITH_OLD_COMPLEX_UI = [ 
  PARTNERS_GUID_ENUM.KTDA, 
  PARTNERS_GUID_ENUM.MANANKIFIN, 
  PARTNERS_GUID_ENUM.KONYANA, 
  PARTNERS_GUID_ENUM.FAITH_FUNERALS 
]