import React, { useState } from 'react'
import Grid from "@material-ui/core/Grid";
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import SettingsCellOutlinedIcon from '@mui/icons-material/SettingsCellOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useStyle } from "./Style";
import ButtonInc from '../../../../../shared-ui/ButtonInc';
import { getPartner, getPartnerGuid } from '../../../../../lib/access.es6';
import AlertDialog from '../../../../../components/AlertDialog/index.es6';
import { CircularProgress } from 'material-ui';


const PaymentDetails = (props) => {




  if (!props.createdQuote) {
    return
  }
  if(!props.emailTemplateData){
    return
  }
  const classes = useStyle();

  const [paymentMethod, setPaymentMethod] = useState('');
  const [disableBankMethodBtn, setDisableBankMethodBtn] = useState(false)
  const [mpesaNumber, setMpesaNumber] = useState('')
  const [disableMpesaMethodBtn, setdisableMpesaMethodBtn] = useState(false)

  const PaymentMethods = {
    Mpesa: 'Mpesa',
    BankDeposit: 'BankDeposit',
  };
  const handleSelect = (value) => {
    //setProductType(value)
    setPaymentMethod(value)
    console.log("product type", value)
  }

  const handleChangeTermsBankOption = (event) => {
    setDisableBankMethodBtn(event.target.checked)

  }

  const handleSendBankDetailsEmail = () => {
    const payload = {
      "partner_guid": getPartnerGuid(),
     // "email_template_guid": props.emailTemplateData.guid,
      "email_recipients": ["testmotorinc@gmail.com"],
     // "entity_guid": props.emailTemplateData.subject_entity_code,
     // "email_code": props.emailTemplateData.code
    }
    props.sendEmailRequest(payload)
    console.log("payloademail", payload)
  }

  const handleChangeTermsMpesaOption = (event) => {
    setdisableMpesaMethodBtn(event.target.checked)
  }
  const handleMpesaNumber = (event) => {
    const { name, value } = event.target;
    if (name === 'mpesaNumber') {
      setMpesaNumber(value)
    }
  }

  const handleMpesaTransaction = () => {
    const payload = {
      "phone_number": mpesaNumber
    }
    console.log("payload", payload)
  }

  const handlesendEmailSuccessfully = () => {

  }

  const handleSendEmailError = () => {

  }

  const handlenextStep = () => {
    props.handleNext();
  }


  const currencyCode = getPartner().country.currency_code
  const totalPremium = props.createdQuote &&
    props.createdQuote.total_premium_amount_in_cents
    ? (props.createdQuote.total_premium_amount_in_cents / 100).toLocaleString()
    : 0;

  console.log("totalPremium", totalPremium)

  return (
    <>

      <Grid container spacing={2}>
        <Grid xs={12}><h2 style={{
          fontFamily: 'Open Sans',
          fontStyle: "normal",
          fontWeight: 300,
          fontSize: "20px",
          lineHeight: "16px",
          letterSpacing: "0.005em",
          color: "#000000",
          textAlign: "center"
        }}>Please select payment option</h2></Grid>
        <Grid item xs={12} sm={6} md={6}>
          <div className={`${classes.productWrapper} ${PaymentMethods === PaymentMethods.Mpesa ? 'selected' : ''}`}
            onClick={() => handleSelect(PaymentMethods.Mpesa)}>
            <SettingsCellOutlinedIcon
              sx={{
                color: "#fff",
                marginTop: "30px",
              }}
            />
            <div> <h3>{PaymentMethods.Mpesa}</h3></div>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={6} >
          <div className={`${classes.productWrapper} ${PaymentMethods === PaymentMethods.BankDeposit ? 'selected' : ''}`} onClick={() => handleSelect(PaymentMethods.BankDeposit)}>
            <AccountBalanceOutlinedIcon
              sx={{
                color: "#fff",
                marginTop: "30px",
              }}
            />
            <div><h3>{PaymentMethods.BankDeposit}</h3></div>
          </div>
        </Grid>
      </Grid>
      {
        paymentMethod === "Mpesa" && <Grid container spacing={2}>

          <Grid item xs={12} md={2} sm={2}>

          </Grid>
          <Grid item xs={12} md={8} sm={8} >
            <div className={classes.mpesapaymentOptions}>
              <h3>Payment Details </h3>
              <p>Enter a valid number below to complete transaction.</p>
            </div>

            <div className={classes.totalPremium}>
              <p>Total Premium Due</p>
              <h2>{currencyCode} {totalPremium}</h2>
            </div>
            <div className={classes.mpesaForm}>
              <ValidatorForm onSubmit={() => handleMpesaTransaction()}  >


                <TextValidator
                  id="mpesaNumber"
                  fullWidth
                  label="Phone Number"
                  helperText=" eg 07xxxxxxxx."
                  name='mpesaNumber'
                  value={mpesaNumber}
                  type='number'
                  onChange={handleMpesaNumber}
                  className={classes.quoteinputs}
                  variant="outlined"
                  style={{ width: "100%" }}
                  validators={["required"]}
                  errorMessages={[
                    "phone number required",
                  ]}

                />
                <div className={classes.termsCondition}>
                  <FormGroup>
                    <FormControlLabel required control={<Checkbox checked={disableMpesaMethodBtn} onChange={handleChangeTermsMpesaOption} />} label="I have read and understood the terms and conditions" />
                  </FormGroup>
                </div>

                <div className={classes.submitBankBtn}>
                  <ButtonInc
                    variant="contained"
                    color="primary"
                    size="large"
                    type="submit"
                    style={{
                      marginBottom: "20px"
                    }}
                    disabled={!disableMpesaMethodBtn}
                  //onClick={handleCreateQuote}
                  >
                    Submit Details
                  </ButtonInc>
                </div>
              </ValidatorForm >
            </div>



          </Grid>
          <Grid item xs={12} md={2} sm={2}>

          </Grid>
        </Grid>
      }
      {
        paymentMethod === "BankDeposit" && <>

          <Grid container spacing={2}>
            <Grid item xs={12} md={2} sm={2}>

            </Grid>
            <Grid item xs={12} md={8} sm={8} className={classes.bankDeposit}>
              <h3>Payment Details </h3>
              <p>For bank deposit, payment details and instructions will be shared via email and SMS once you submit the application</p>
              <div className={classes.totalPremium}>
                <p>Total Premium Due</p>
                <h2>{currencyCode} {totalPremium}</h2>
              </div>
              <div className={classes.termsCondition}>
                <FormGroup>
                  <FormControlLabel required control={<Checkbox checked={disableBankMethodBtn} onChange={handleChangeTermsBankOption} />} label="I have read and understood the terms and conditions" />
                </FormGroup>
              </div>

              <div className={classes.submitBankBtn}>
                <ButtonInc
                  variant="contained"
                  color="primary"
                  size="large"
                  disabled={!disableBankMethodBtn}
                  onClick={handleSendBankDetailsEmail}
                >
                  Submit Details
                </ButtonInc>
              </div>

            </Grid>
            <Grid item xs={12} md={2} sm={2}>

            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={2} sm={2}></Grid>
            <Grid item xs={12} md={8} sm={8}>

            </Grid>
            <Grid item xs={12} md={2} sm={2}></Grid>
          </Grid>


          <AlertDialog
            custom
            show={props.showSendEmailProgressAlert}
            size="sm"
            style={{ marginTop: '0', top: '30vh' }}
            confirmBtnText={'ok'}
            showCancel={false}
            showConfirm={false}
            cancelBtnText={'cancel'}
            showTitle={false}
            confirmBtnCssClass
          >
            <CircularProgress />
            <h2> Sending Email To Customer...</h2>
          </AlertDialog>

          <AlertDialog
            success
            show={props.showSendEmailSuccessAlert}
            size="sm"
            title={props.sendEmail ? props.sendEmail.message : "" }
            style={{ marginTop: '0', top: '30vh' }}
            onConfirm={() => { handlesendEmailSuccessfully() }}
            confirmBtnText={'ok'}
            showCancel={false}
            showConfirm={false}
            cancelBtnText={'cancel'}
            confirmBtnCssClass
          >
            <div >
              <ButtonInc variant="contained" onClick={() => { handlenextStep() }} style={{ background: 'green', }}>Upload Documents</ButtonInc>
            </div>
          </AlertDialog>

          <AlertDialog
            show={props.showSendEmailErrorAlert}
            danger
            title={'Error sending Email'}
            onConfirm={() => handleSendEmailError()}
            confirmBtnText={'Try again'}
            confirmBtnCssClass
            showCancel={false}
            style={{ marginTop: '0', top: '30vh' }}
          >
            {
              props.sendEmailError ? props.sendEmailError.message : ""
            }
          </AlertDialog>
        </>
      }

    </>

  )
}

export default PaymentDetails