import { find } from 'lodash';

import { extended_members_options } from "./data";

export const getNuclearPremium = (plan, coverType) => {
  if (
    plan &&
    plan.membership_configs &&
    plan.membership_configs.content &&
    plan.membership_configs.content.premium_nuclear
  ) {
    const premiumInfo = plan.membership_configs.content.premium_nuclear.find(
      (item) => item.type === coverType
    );

    if (premiumInfo) {
      return premiumInfo.premium_amount;
    }
  }
  return 0;
};

export const getNuclearBenefit = (plan) => {
  if (
    plan &&
    plan.membership_configs &&
    plan.membership_configs.content &&
    plan.membership_configs.content.benefits_nuclear
  ) {
    const benefits_nuclear = plan.membership_configs.content.benefits_nuclear

    if (benefits_nuclear && benefits_nuclear.length > 0) {
      return benefits_nuclear[0].benefit_amount;
    }
  }
  return 0;
};


export const getAdditionalBenefits = (relationship, plan) => {
  let premium_waivers = [];
  let annuity_benefits = [];
  let add_ons = [];

  if (plan && plan.membership_configs && plan.membership_configs.content) {
    if (plan.membership_configs.content.premium_waivers) {
      premium_waivers = plan.membership_configs.content.premium_waivers.filter(item => item.allowed_relationships.includes(relationship));
    }
    if (plan.membership_configs.content.annuities) {
      annuity_benefits = plan.membership_configs.content.annuities.filter(item => item.allowed_relationships.includes(relationship));
    }

    if (plan.membership_configs.content.add_ons) {
      add_ons = plan.membership_configs.content.add_ons.filter(item => item.allowed_relationships.includes(relationship));
    }

  }

  let all = [];

  if (premium_waivers.length > 0) {
    all = [...all, ...premium_waivers.map(p => {
      return {
        ...p, name: p.months + " Months Premium Waiver", selected: false,
        type: p.months + " Months Premium Waiver",
        cover_amount_in_cents: 0,
        waiting_period_in_days: 30,
        cover_usage_count: 0,
        benefit_period_cardinality: 1,
        benefit_period_granuality: "year"
      }
    })];
  }
  if (annuity_benefits.length > 0) {
    all = [...all, ...annuity_benefits.map(p => {
      return {
        ...p, name: p.months + " Months Annuity",
        selected: false,
        type: p.months + " Months Annuity",
        cover_amount_in_cents: p.benefit_amount,
        waiting_period_in_days: 30,
        cover_usage_count: 0,
        benefit_period_cardinality: 1,
        benefit_period_granuality: "year"

      }
    })];
  }

  if (add_ons.length > 0) {
    all = [...all, ...add_ons.map(p => {
      return {
        ...p,
        type: p.name,
        premium_amount: p.premium_amount_in_cents,

        selected: false,
        cover_amount_in_cents: p.benefit_amount_in_cents,
        waiting_period_in_days: 30,
        cover_usage_count: 0,
        benefit_period_cardinality: 1,
        benefit_period_granuality: "year"
      }
    })];
  }
  return all.map((item) => {
    return {
      ...item, "additional_details": {
        "uuid": item.uuid,
        premium_amount: item.premium_amount,
      }
    }
  });
}



export const getNuclearRelationships = (plan) => {

  let result = [];
  if (plan && plan.membership_configs && plan.membership_configs.content && plan.membership_configs.content.nuclear) {

    plan.membership_configs.content.nuclear.forEach(item => {
      result.push({ [item.relationship]: item.max_count });
    });

    return result

  }
  return result
}

export const getExtendedRelationships = (plan) => {

  let result = [];
  if (plan && plan.membership_configs && plan.membership_configs.content && plan.membership_configs.content.extended) {

    plan.membership_configs.content.extended.forEach(item => {
      result.push({ [item.relationship]: item.max_count });
    });

    return result

  }
  return result
}

export const getNuclearRelationships2 = (plan) => {
  return [
    { "Uncle": 12 },
    { "Aunt": 12 },
    { "Sister": 12 },
    { "Brother": 12 },
    { "Cousin": 12 },
    { "Nephew": 12 },
    { "Niece": 12 },
    { "Brother_In_Law": 12 },
    { "Sister_In_Law": 12 },
    { "Grandson": 12 },
    { "Granddaughter": 12 },
    { "Grandchild": 12 },
    { "Dependant": 12 }
  ]

}


export function getAgeRangeObject(dateOfBirth) {
  const currentYear = new Date().getFullYear();

  try {
    const age = currentYear - new Date(dateOfBirth).getFullYear();
    for (const option of extended_members_options) {
      if (age >= option.min_age && age <= option.max_age) {
        return option;
      }
    }

  } catch (error) {
    return [];
  }
}


export const isExtendedMember = (relationship) => {
  const extended_members_relationships = getNuclearRelationships2()

  for (const item of extended_members_relationships) {
    if (item.hasOwnProperty(relationship)) {
      return true;
    }
  }
  return false;
}

export const group_life_options = [
  {
    "name": "Group Life Assurance",
    "type": "Group Life Assurance",
    "waiting_period_in_days": 30,
    "benefit_period_cardinality": 1,
    "benefit_period_granuality": "year",
    "cover_amount_in_cents": 5000000,
    "cover_usage_count": 0,
    "premium_amount": 4000,
    "allowed_relationships": [
      "Member Only",
      "Member & Family",
    ],
    "uuid": "gla1_principal",
    "additional_details": {
      premium_amount: 4000,
      "uuid": "gla1_principal",
    }
  },
  {
    "name": "Group Life Assurance",
    "type": "Group Life Assurance",
    "waiting_period_in_days": 30,
    "benefit_period_cardinality": 1,
    "benefit_period_granuality": "year",
    "cover_amount_in_cents": 10000000,
    "cover_usage_count": 0,
    "premium_amount": 7900,
    "allowed_relationships": [
      "Member Only",
      "Member & Family",
    ],
    "uuid": "gla2_principal",
    "additional_details": {
      premium_amount: 7900,
      "uuid": "gla2_principal",
    }
  },
  {
    "name": "Group Life Assurance",
    "type": "Group Life Assurance",
    "waiting_period_in_days": 30,
    "benefit_period_cardinality": 1,
    "benefit_period_granuality": "year",
    "cover_amount_in_cents": 15000000,
    "cover_usage_count": 0,
    "premium_amount": 11800,
    "allowed_relationships": [
      "Member Only",
      "Member & Family",
    ],
    "uuid": "gla3_principal",
    "additional_details": {
      premium_amount: 11800,
      "uuid": "gla3_principal",
    }
  },
]


export const COMPONENT_TYPES = {
  SELECT_PRODUCT_TYPE: 'SELECT_PRODUCT_TYPE',
  SELECT_COVER_TYPE: 'SELECT_COVER_TYPE',
  SELECT_PLAN: 'SELECT_PLAN',
  ADD_MEMBERS: 'ADD_MEMBERS',
  ADD_DEPENDANT: 'ADD_DEPENDANT',
  VIEW_MEMBERS: 'VIEW_MEMBERS',
  ADD_ADDITIONAL_BENEFITS: 'ADD_ADDITIONAL_BENEFITS',
};



export function calculateTotalPremiumInCents(allbenefits) {
  if (!allbenefits || allbenefits.length < 1) return 0
  return allbenefits.reduce((total, benefit) => total + benefit.premium_amount, 0);
}

export function calculateMemberTotalPremiumInCents(member) {
  // return member.premium_amount_in_cents
  if (!member || !member.benefits || member.benefits < 1) return 0
  return calculateTotalPremiumInCents(member.benefits)


}

export const membersTotalPremiums = (members) => {
  let sum = 0;
  for (let member of members) {
    sum = sum + calculateMemberTotalPremiumInCents(member)
  }
  return sum / 100
}

export const replicatePrincipalBenefits = (dependants) => {
  const principal = find(dependants, { relationship: 'Principal' })

  if (!principal) return dependants

  const selectedBenefits = principal.benefits.filter(benefit => benefit.selected)
  const benefits = selectedBenefits.filter(benefit => !benefit.uuid.includes('gla')).map(b => { return { ...b, premium_amount: 0 } })


  const tempDependants = dependants

  for (let i = 0; i < tempDependants.length; i++) {
    if (tempDependants[i].relationship === 'Spouse') {
      const spouseBenefits = tempDependants[i].benefits.filter(benefit => benefit.is_initialized || (benefit.uuid && benefit.uuid.includes('gla')))
      tempDependants[i].benefits = [...spouseBenefits, ...benefits]
    }
    if (tempDependants[i].relationship === 'Child') {
      const childBenefits = tempDependants[i].benefits.filter(benefit => benefit.is_initialized)
      tempDependants[i].benefits = [...childBenefits, ...benefits]
    }
  }

  return tempDependants

}

export const familyClanCoverPremiumRates = [
  {
    cover_amount: 7500,
    option: '7,500',
    premiums: [
      {
        "min_total_members": 0,
        "max_total_members": 10,
        "premium_amount": 95.67
      },
      {
        "min_total_members": 11,
        "max_total_members": 15,
        "premium_amount": 139.4
      },
      {
        "min_total_members": 16,
        "max_total_members": 20,
        "premium_amount": 183.14
      },
      {
        "min_total_members": 21,
        "max_total_members": 25,
        "premium_amount": 226.87
      },
      {
        "min_total_members": 26,
        "max_total_members": 30,
        "premium_amount": 226.87
      }
    ]
 
  },
  {
    cover_amount: 10000,
    option: '10,000',
    premiums: [
      {
        "min_total_members": 0,
        "max_total_members": 10,
        "premium_amount": 121.38
      },
      {
        "min_total_members": 11,
        "max_total_members": 15,
        "premium_amount": 178.05
      },
      {
        "min_total_members": 16,
        "max_total_members": 20,
        "premium_amount": 234.72
      },
      {
        "min_total_members": 21,
        "max_total_members": 25,
        "premium_amount": 291.39
      },
      {
        "min_total_members": 26,
        "max_total_members": 30,
        "premium_amount": 348.06
      }
    ]
 
  },
  {
    cover_amount: 15000,
    option: '15,000',
    premiums: [
      {
        "min_total_members": 0,
        "max_total_members": 10,
        "premium_amount": 172.68
      },
      {
        "min_total_members": 11,
        "max_total_members": 15,
        "premium_amount":  225.08
      },
      {
        "min_total_members": 16,
        "max_total_members": 20,
        "premium_amount": 337.49
      },
      {
        "min_total_members": 21,
        "max_total_members": 25,
        "premium_amount": 419.89
      },
      {
        "min_total_members": 26,
        "max_total_members": 30,
        "premium_amount": 502.3
      }
    ]
 
  },
  {
    cover_amount: 20000,
    option: '20,000',
    premiums: [
      {
        "min_total_members": 0,
        "max_total_members": 10,
        "premium_amount": 223.92
      },
      {
        "min_total_members": 11,
        "max_total_members": 15,
        "premium_amount":  332.06
      },
      {
        "min_total_members": 16,
        "max_total_members": 20,
        "premium_amount": 440.2
      },
      {
        "min_total_members": 21,
        "max_total_members": 25,
        "premium_amount": 548.34
      },
      {
        "min_total_members": 26,
        "max_total_members": 30,
        "premium_amount": 656.48
      }
    ]
 
  },
];

export const motsheloCoverPremiumsRates = [
  {
    cover_amount: 7500,
    option: 'Option 1',
    premiums: [
      {
        "min_total_members": 0,
        "max_total_members": 10,
        "premium_amount": 187
      },
      {
        "min_total_members": 11,
        "max_total_members": 15,
        "premium_amount": 238
      },
      {
        "min_total_members": 16,
        "max_total_members": 20,
        "premium_amount": 263
      },
      {
        "min_total_members": 21,
        "max_total_members": 25,
        "premium_amount": 314
      },
      {
        "min_total_members": 26,
        "max_total_members": 30,
        "premium_amount": 360
      }
    ]
  },
  {
    cover_amount: 10000,
    option: 'Option 2',
    premiums: [
      {
        "min_total_members": 0,
        "max_total_members": 10,
        "premium_amount": 232
      },
      {
        "min_total_members": 11,
        "max_total_members": 15,
        "premium_amount": 295
      },
      {
        "min_total_members": 16,
        "max_total_members": 20,
        "premium_amount": 327
      },
      {
        "min_total_members": 21,
        "max_total_members": 25,
        "premium_amount": 390
      },
      {
        "min_total_members": 26,
        "max_total_members": 30,
        "premium_amount": 447
      }
    ]
  },
  {
    cover_amount: 15000,
    option: 'Option 3',
    premiums: [
      {
        "min_total_members": 0,
        "max_total_members": 10,
        "premium_amount": 341
      },
      {
        "min_total_members": 11,
        "max_total_members": 15,
        "premium_amount": 436
      },
      {
        "min_total_members": 16,
        "max_total_members": 20,
        "premium_amount": 483
      },
      {
        "min_total_members": 21,
        "max_total_members": 25,
        "premium_amount": 578
      },
      {
        "min_total_members": 26,
        "max_total_members": 30,
        "premium_amount": 663
      }
    ]
  },
  {
    cover_amount: 20000,
    option: 'Option 4',
    premiums: [
      {
        "min_total_members": 0,
        "max_total_members": 10,
        "premium_amount": 452
      },
      {
        "min_total_members": 11,
        "max_total_members": 15,
        "premium_amount": 579
      },
      {
        "min_total_members": 16,
        "max_total_members": 20,
        "premium_amount": 642
      },
      {
        "min_total_members": 21,
        "max_total_members": 25,
        "premium_amount": 768
      },
      {
        "min_total_members": 26,
        "max_total_members": 30,
        "premium_amount": 882
      }
    ]
  },
  
];


export const clanRelationships = [
  { value: 'Principal', gender: 'Neutral' },
  { value: 'Alternate_Member', gender: 'Neutral' },
  { value: 'Child', gender: 'Neutral' },
  { value: 'Parent', gender: 'Neutral' },
  { value: 'Parent_In_Law', gender: 'Neutral' },
  { value: 'Brother', gender: 'Male' },
  { value: 'Nephew', gender: 'Male' },
  { value: 'Uncle', gender: 'Male' },
  { value: 'Grandfather', gender: 'Male' },
  { value: 'Grandson', gender: 'Male' },
  { value: 'Grandmother', gender: 'Female' },
  { value: 'Niece', gender: 'Female' },
  { value: 'Aunt', gender: 'Female' },
  { value: 'Sister', gender: 'Female' },
  { value: 'Granddaughter', gender: 'Female' },
];

export const motsheloRelationships = [
  { value: 'Principal', gender: 'Neutral' },
  { value: 'Child', gender: 'Neutral' },
  { value: 'Parent', gender: 'Neutral' },
  { value: 'Parent_In_Law', gender: 'Neutral' },
  { value: 'Brother', gender: 'Male' },
  { value: 'Nephew', gender: 'Male' },
  { value: 'Uncle', gender: 'Male' },
  { value: 'Grandfather', gender: 'Male' },
  { value: 'Grandson', gender: 'Male' },
  { value: 'Grandmother', gender: 'Female' },
  { value: 'Niece', gender: 'Female' },
  { value: 'Aunt', gender: 'Female' },
  { value: 'Sister', gender: 'Female' },
  { value: 'Granddaughter', gender: 'Female' },
];

export const getClanPremiumRate = (cover_amount, total_members) => {
  let premium = 0;
  familyClanCoverPremiumRates.forEach((option) => {
    if (option.cover_amount === cover_amount) {
      option.premiums.forEach((rate) => {
        if (rate.min_total_members <= total_members && rate.max_total_members >= total_members) {
          premium = rate.premium_amount;
        }
      });
    }
  });
  return premium;
 
}


export const getMotsheloPremiumRate = (cover_amount, total_members) => {
  let premium = 0;
  motsheloCoverPremiumsRates.forEach((option) => {
    if (option.cover_amount === cover_amount) {
      option.premiums.forEach((rate) => {
        if (rate.min_total_members <= total_members && rate.max_total_members >= total_members) {
          premium = rate.premium_amount;
        }
      });
    }
  });
  return premium;
}