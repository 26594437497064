import React from 'react'
import TableInc from '../../../../../../../shared-ui/TableInc';
import { updateExtendedConfig } from '../../../../../../../lib/requests.es6';

const PremiumRatesThirdParty = (props) => {


  let dataContent = [];

  if (props.membership_configs && props.membership_configs.content) {
    dataContent = props.membership_configs.content.premium_rates.map((amount => {
      return {
        amount: amount
      }
    }));
  }

  const data = dataContent || [];


  const convertArrayOfString = (newData) => {
    const results =  newData.map((amount => {
      return Number(amount.amount)
    }) )
    return results
  }

  const updateMembershipConfigs = async (newData) => {

console.log("newData", newData)
    const payload = {
      "product_guid": props.productGuid,
      ...props.membership_configs,
      "content": {
        ...props.membership_configs.content,
        premium_rates: newData
      }
    }
    console.log("payload", payload)
    await updateExtendedConfig(payload)
   window.location.reload(true);
  };

  return (
    <TableInc
    title="Premium  Rates (amount)"
    columns={[
      { title: 'Amount', field: 'amount' },
    ]}
    data={data}
    options={{
      addRowPosition: 'first',
      showTitle: true,
      actionsColumnIndex: -1,
      pageSize: data.length > 0 ? data.length : 5, // Show 5 rows or based on data length
    }}
    editable={{
      onRowAdd: newData =>
        new Promise((resolve, reject) => {

          const newDataArray = [...data, newData];
          const newArray = convertArrayOfString(newDataArray)           
          //setData(newDataArray);
          updateMembershipConfigs(newArray);
          resolve();
        }),
      onRowUpdate: (newData, oldData) =>
        new Promise((resolve, reject) => {
          const dataUpdate = [...data];
          const index = oldData.tableData.id;
          dataUpdate[index] = newData;
          //setData(dataUpdate);
          const newArray = convertArrayOfString(dataUpdate)  
          updateMembershipConfigs(newArray);
          resolve();
        }),
      onRowDelete: oldData =>
        new Promise((resolve, reject) => {
          const dataDelete = [...data];
          const index = oldData.tableData.id;
          dataDelete.splice(index, 1);
         // setData(dataDelete);
         const newArray = convertArrayOfString(dataDelete) 
          updateMembershipConfigs(newArray);
          resolve();
        }),
    }}
  />

  )
}

export default PremiumRatesThirdParty