import { makeStyles } from "@material-ui/styles";

export const useStyle = makeStyles((theme) => ({
  paper: {
    padding: '1rem',
    marginTop: '1rem',
    marginBottom: '1rem',
    '@media (min-width: 600px)': {
      padding: '2rem',
      marginTop: '2rem',
      marginBottom: '2rem',
    }
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '1.5rem',
  },
  backButton: {
    marginRight: '1rem',
  },
  stepTitle: {
    fontWeight: 600,
    color: '#032A37',
  },
  progressBar: {
    height: 8,
    borderRadius: 4,
    backgroundColor: '#e0e0e0',
    '& .MuiLinearProgress-bar': {
      backgroundColor: '#FBA92D',
    }
  },
  progressText: {
    marginTop: '0.5rem',
    textAlign: 'right',
    color: '#666',
    fontSize: '0.875rem',
  },
  contentBox: {
    padding: '1rem 0',
    minHeight: '400px',
    '@media (min-width: 600px)': {
      padding: '2rem 1rem',
    }
  },
  formControl: {
    width: '100%',
    marginBottom: '1rem'
  },
  textField: {
    width: '100%',
    marginBottom: '1rem',
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#FBA92D',
      },
    },
  },
  button: {
    margin: '1rem 0',
    width: '100%',
    '@media (min-width: 600px)': {
      width: 'auto',
      margin: '1rem',
    },
    '&.MuiButton-contained': {
      backgroundColor: '#FBA92D',
      '&:hover': {
        backgroundColor: '#d98f1d',
      },
    },
  },
  dialog: {
    '& .MuiDialog-paper': {
      padding: '1rem',
      margin: '1rem',
      width: '100%',
      maxWidth: '600px',
    },
  },
  buttonGroup: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    marginTop: '1rem',
    '@media (min-width: 600px)': {
      flexDirection: 'row',
      justifyContent: 'flex-end',
    }
  }
})); 