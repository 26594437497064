import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import DashboardCustomizeOutlinedIcon from '@mui/icons-material/DashboardCustomizeOutlined';
import { canViewDashboard, hasAuthourity } from '../../lib/access.es6';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import SlideshowIcon from '@mui/icons-material/Slideshow';

export const NGSGroupMenu = (labels) => {

  return [
    {
      id: 'application',
      title: 'Home',
      icon: HomeOutlinedIcon,
      type: 'group',
      permisions: [],
      children: [
        {
          id: 'admin/home',
          title: labels.home,
          type: 'item',
          url: 'admin/home',
          icon: HomeOutlinedIcon,
          permisions: []
        },
        {
          id: 'admin/dashboards',
          title: labels.dashboard,
          type: 'item',
          url: 'admin/dashboards',
          icon: DashboardCustomizeOutlinedIcon,
          permisions: [canViewDashboard()]
        },
 
        {
          title: 'Bulk Onboard',
          label: 'Bulk Onboard',
          type: 'item',
          url: 'admin/bulk-policy-onboarding',
          id: 'admin/bulk-policy-onboarding',
          style: '20px',
          icon: UploadFileOutlinedIcon,
          permisions: [hasAuthourity('BULK_ONBOARD_CUSTOMERS')]
        },
        {
          title: 'Payments',
          id: 'Payments',
          label: labels.payments,
          type: 'collapse',
          url: 'admin/missing/payments',
          icon: AccountBalanceOutlinedIcon,
          permisions: [hasAuthourity('APPROVE_PAYMENT')],
          children:
            [
              {
                label: labels.bulkPayments,
                title: "Bulk Payment",
                type: 'item',
                id: 'admin/bulk-payments',
                url: 'admin/bulk-payments',
                permisions: [hasAuthourity('PREMIUM_UPDATE')]
              },
              {
                id: 'admin/payments-dashboard',
                title: 'Payments Dashboard',
                type: 'item',
                url: 'admin/payments_dashboard',
                permisions: []
              },
            ]
        },

        {
          id: 'Claims',
          title: 'Claims',
          type: 'item',
          url: 'admin/claims_dashboard',
          icon: InventoryOutlinedIcon,
          permisions: [hasAuthourity('VIEW_CLAIMS')],
        },

        {
          id: 'Reports',
          title: labels.reports,
          type: 'item',
          url: 'admin/reports',
          icon: AssessmentOutlinedIcon,
          permisions: [hasAuthourity('VIEW_BUSINESS_REPORT')],
        },
        {
          title: 'Knowledge Base',
          label: labels.partners,
          icon: SlideshowIcon,
          type: 'item',
          url: 'admin/knowledge_base',
          id: 'admin/knowledge_base',
          permisions: []
        }
      ]
    },
    {
      id: 'application',
      title: 'Configs',
      icon: HomeOutlinedIcon,
      type: 'group',
      permisions: [canViewDashboard()],
      children: [
        {
          title: 'Configurations',
          id: 'Configurations',
          label: labels.Configurations,
          type: 'collapse',
          url: 'configs',
          icon: SettingsOutlinedIcon,
          permisions: [hasAuthourity('INTERNAL_DASHBOARD')],
          children:
            [{
              title: 'Products',
              label: labels.products,
              type: 'item',
              url: 'admin/products',
              id: 'admin/products',
              permisions: [hasAuthourity('VIEW_ALL_PRODUCTS')]
            },
            {
              title: 'SMS Templates',
              label: labels.smsTemplates,
              type: 'item',
              url: 'admin/view_sms_templates',
              id: 'admin/view_sms_templates',
              permisions: [hasAuthourity('VIEW_ALL_SMS_TEMPLATES')]
            },
            {
              title: 'Document Templates',
              label: labels.Templates,
              type: 'item',
              url: 'admin/view_doc_templates',
              id: 'admin/view_doc_templates',
              permisions: [hasAuthourity('VIEW_ALL_TEMPLATES')]
            },
            {
              title: 'Email Templates',
              label: labels.emailTemplates,
              type: 'item',
              url: 'admin/view_email_templates',
              id: 'admin/view_email_templates',
              permisions: [hasAuthourity('VIEW_ALL_TEMPLATES')]
            },
            {
              title: 'Reports Configs',
              label: 'Reports Configs',
              type: 'item',
              url: 'admin/view_reports_configs',
              id: 'admin/view_reports_configs',
              permisions: []
            },
            {
              title: 'Partner',
              label: labels.partners,
              type: 'item',
              url: 'admin/partners/view/partner',
              id: 'admin/partners/view/partner',
              permisions: [hasAuthourity('VIEW_ALL_PARTNERS')]
            }
            ]
        },
        {
          title: labels.InternalConfigurations,
          id: 'Internal Configurations',
          label: labels.InternalConfigurations,
          type: 'collapse',
          url: 'Internal Configurations',
          icon: TuneOutlinedIcon,
          permisions: [hasAuthourity('INTERNAL_DASHBOARD')],
          children:
            [
              {
                title: 'Reports Configs',
                label: 'Reports Configs',
                type: 'item',
                url: 'admin/view_reports_configs',
                id: 'admin/view_reports_configs',
                permisions: []
              },
              {
              title: 'Partners',
              label: labels.partners,
              type: 'item',
              url: 'admin/partners',
              id: 'admin/partners',
              permisions: [hasAuthourity('VIEW_ALL_PARTNERS')]
            },
            {
              title: 'User Administration',
              label: labels.userAdministration,
              type: 'item',
              url: 'admin/users',
              id: 'admin/users',
              permisions: [hasAuthourity('VIEW_ALL_USER')]
            },
            {
              title: 'Country Administration',
              label: labels.countryAdministration,
              type: 'item',
              url: 'admin/country',
              id: 'admin/country',
              permisions: [hasAuthourity('VIEW_COUNTRIES')]
            },
            {
              title: 'Languages',
              label: labels.languages,
              type: 'item',
              url: 'admin/languages',
              id: 'admin/languages',
              permisions: []
            },
            {
              title: 'Feature Flags',
              label: 'Feature Flags',
              type: 'item',
              url: 'admin/feature-flags',
              id: 'admin/feature-flags',
              permisions: []
            },
            ]
        },
      ]
    },


  ]
}

