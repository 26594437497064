import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ProductsHeader from "../ProductsHeader/ProductsHeader";
import PremiumsTable from "../PremiumsTable/PremiumsTable";
import BenefitsTable from "../BenefitsTable/BenefitsTable";
import './ProductsTable.css'
import { PRODUCT_TYPES_FREE } from "../../PoductConstants";
import Alert from '@mui/material/Alert';
import ViewGroupProductConfig from "./GroupProductConfigs/ViewGroupProductConfig";
import UpdateGroupProductConfigs from "./GroupProductConfigs/UpdateGroupProductConfigs";
import AddGroupProductConfigs from "./GroupProductConfigs/AddGroupProductConfigs";
import ViewExtendedFamilyProductConfig from "./ExtendedFamilyProductConfigs/ViewExtendedFamilyProductConfig";
import { getPartner, getPartnerGuid } from "../../../../lib/access.es6";
import RealPeopleConfigs from "../RealPeopleConfigs/RealPeopleConfigs";
import DefaultComplexProductsConfigs from "../DefaultComplexProductsConfigs/DefaultComplexProductsConfigs";
import IncMotorConfigs from "../ProductsTable/IncMotorConfigs/IncMotorConfigs";
import EunifinGhanaConfigs from "../EunifinGhanaConfigs/EunifinGhanaConfigs";
import MalipowwsConfigs from "../malipowwsConfigs/MalipowwsConfigs";
import IframeConfigs from "../IframeConfigs";
import OldMutualPostnetBank from "../OldMutualPostnetBank";
//import OldMutualUgandaPostBankConfigs from "../OldMutualUgandaPostBankConfigs/OldMutualUgandaPostBankConfigs";

export default function ViewProduct(props) {
  if (props.isLoading) {
    return ""
  }
  if (!props.product || (props.productNotFoundError && !props.isLoading)) {
    return <Alert severity="error">Could not find product, Please check your product guid!</Alert>
  }

  const [scrollPos, setScrollPos] = React.useState(0);

  React.useEffect(() => {
    const savedScrollPos = sessionStorage.getItem('scrollPos');
    if (savedScrollPos) {
      window.scrollTo(0, parseInt(savedScrollPos));
    }

    const handleScroll = () => {
      setScrollPos(window.pageYOffset);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  React.useEffect(() => {
    sessionStorage.setItem('scrollPos', scrollPos);
  }, [scrollPos]);




  const [openUpdateGroupProductConfigsDialog, setOpenUpdateGroupProductConfigsDialog] = React.useState(false)
  const [openAddGroupProductConfigsDialog, setOpenAddGroupProductConfigsDialog] = React.useState(false)

  const product = props.product
  const benefits = [...product.fixed_indemnities, ...product.cashback, ...product.variable_indemnities]
  const activePremiums = product.premiums.filter(premium => premium.active)
  const currencyCode = getPartner().country.currency_code

  const complexPartners = ['accessbank', 'bxck', 'aspinplus', 'realpeopleguid', 'incl_motor', 'eunifingh', 'malipo_wws', 'he_chp', 'omu_posbank'];
  const isComplexPartner = complexPartners.includes(getPartnerGuid());

  const partnerGuid = getPartnerGuid();


  const getConfigs = (props) => {
    switch (partnerGuid) {
      case "omu_posbank":
        return <OldMutualPostnetBank 
          product={props.product}
          refreshProduct={props.refreshProduct}
        />

      case "realpeopleguid":
        return (<RealPeopleConfigs
          membership_configs={props.extendedFamilyProductConfigs}
          productGuid={props.product.guid}
        />)

      case "incl_motor":
        return (<IncMotorConfigs
          membership_configs={props.extendedFamilyProductConfigs}
          productGuid={props.product.guid}
          productCode={props.product.code}
        />)


      case "eunifingh":
        return (<EunifinGhanaConfigs
          membership_configs={props.extendedFamilyProductConfigs}
          productGuid={props.product.guid}
        />)

      case "malipo_wws":
      case "he_chp":
        return (<MalipowwsConfigs
          membership_configs={props.extendedFamilyProductConfigs}
          lives_covered={props.extendedFamilyProductConfigs ? props.extendedFamilyProductConfigs.content.lives_covered : []}
          extendedConfig={props.extendedFamilyProductConfigs}
          productGuid={props.product.guid}
        />)

        // case "omu_posbank":
        //   return (<OldMutualUgandaPostBankConfigs
        //     membership_configs={props.extendedFamilyProductConfigs}
        //     productGuid={props.product.guid}
        //     productCode={props.product.code}
        //   />)

      default:
        return (<DefaultComplexProductsConfigs
          currencyCode={currencyCode}
          membership_configs={props.extendedFamilyProductConfigs}
          productGuid={props.product.guid}
          refreshProduct={props.refreshProduct}
          nuclear={props.extendedFamilyProductConfigs ? props.extendedFamilyProductConfigs.content.nuclear : []}
          extendedConfig={props.extendedFamilyProductConfigs}
          extended={props.extendedFamilyProductConfigs ? props.extendedFamilyProductConfigs.content.extended : []}
          premiums={props.extendedFamilyProductConfigs ?
            (props.extendedFamilyProductConfigs.content.premiums ? props.extendedFamilyProductConfigs.content.premiums : [])
            : []}
          addOns={props.extendedFamilyProductConfigs ? props.extendedFamilyProductConfigs.content.add_ons : []}
          members_payout_plan={props.extendedFamilyProductConfigs ? props.extendedFamilyProductConfigs.content.members_payout_plan : []}
          displayEditButton={false}
          displayDeleteButton={false}
          hardDeletePremium={props.setPremiumToDelete}
        />);
    }
  }



  return (
    <div style={{ marginTop: "3%" }}>
      <ProductsHeader
        title={product.name}
        showEditButton={true}
        onEditButtonClick={props.openEditProductDialog}
      />

      <TableContainer component={Paper} style={{ marginTop: "2%" }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className="table-cell-header">Property</TableCell>
              <TableCell align="left" className="table-cell-header">Value</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                <strong>Product Code</strong>
              </TableCell>
              <TableCell align="left">{product.code}</TableCell>
            </TableRow>

            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                <strong>Name</strong>
              </TableCell>
              <TableCell align="left">{product.name}</TableCell>
            </TableRow>

            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              style={{ display: isComplexPartner ? 'none' : 'block' }}
            >
              <TableCell component="th" scope="row">
                <strong>Type</strong>
              </TableCell>
              <TableCell align="left">{product.type}</TableCell>
            </TableRow>

            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              style={{ display: isComplexPartner ? 'none' : 'block' }}
            >
              <TableCell component="th" scope="row">
                <strong>Cover Type</strong>
              </TableCell>
              <TableCell align="left">{product.cover_type}</TableCell>
            </TableRow>


            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                <strong>Waiting period in days</strong>
              </TableCell>
              <TableCell align="left">{product.waiting_period_days}</TableCell>
            </TableRow>


            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                <strong>Lapse period in days</strong>
              </TableCell>
              <TableCell align="left">{product.lapse_period_days}</TableCell>
            </TableRow>


            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              style={{ display: isComplexPartner ? 'none' : 'block' }}
            >
              <TableCell component="th" scope="row">
                <strong>Maximum age</strong>
              </TableCell>
              <TableCell align="left">{product.maximum_age}</TableCell>
            </TableRow>


            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              style={{ display: isComplexPartner ? 'none' : 'block' }}
            >
              <TableCell component="th" scope="row">
                <strong>Minimum age</strong>
              </TableCell>
              <TableCell align="left">{product.minimum_age}</TableCell>
            </TableRow>

            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                <strong>Active</strong>
              </TableCell>
              <TableCell align="left">{product.active ? 'true' : 'false'}</TableCell>
            </TableRow>

            {
              ['', 'Free', 'Free_Agent'].includes(product.type) &&
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                style={{ display: isComplexPartner ? 'none' : 'block' }}
              >
                <TableCell component="th" scope="row">
                  <strong>Loyalty premium in cents</strong>
                </TableCell>
                <TableCell align="left">{product.loyalty_premium_in_cents}</TableCell>
              </TableRow>
            }


            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              style={{ display: isComplexPartner ? 'none' : 'block' }}
            >
              <TableCell component="th" scope="row">
                <strong>Tag</strong>
              </TableCell>
              <TableCell align="left">{product.tag}</TableCell>
            </TableRow>



            {
              PRODUCT_TYPES_FREE.includes(product.type) &&
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                style={{ display: isComplexPartner ? 'none' : 'block' }}
              >
                <TableCell component="th" scope="row">

                  <strong>Eligibility Threshold</strong>
                </TableCell>
                <TableCell align="left">{product.eligibility_threshold} </TableCell>
              </TableRow>
            }

            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                <strong>Grace period</strong>
              </TableCell>
              <TableCell align="left">{product.debit_grace_days}</TableCell>
            </TableRow>

            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                <strong>Cool off period (hour)</strong>
              </TableCell>
              <TableCell align="left">{product.cool_off_period_hours}</TableCell>
            </TableRow>
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                <strong>Notes</strong>
              </TableCell>
              <TableCell align="left">{product.notes}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      {
        !isComplexPartner &&
        <>
          <div style={{ marginTop: '6%', marginBottom: '6%', display: PRODUCT_TYPES_FREE.includes(product.type) ? 'none' : 'block' }}>
            <ProductsHeader title={"Premiums"} showAddButton={true} onAddButtonClick={props.openAddPremiumDialog} />
            <PremiumsTable
              premiums={activePremiums}
              displayEditButton={true}
              displayDeleteButton={true}
              hardDeletePremium={props.setPremiumToDelete}
              setPremiumToEdit={props.setPremiumToEdit}
            />
          </div>

          <div style={{ marginTop: '10%', marginBottom: '16%' }}>

            <ProductsHeader title={"Benefits"} showAddButton={true} onAddButtonClick={props.openAddBenefitDialog} />
            <BenefitsTable
              benefits={benefits}
              displayEditButton={true}
              displayDeleteButton={true}
              setBenefitToDelete={props.setBenefitToDelete}
              hardDeleteBenefit={props.setBenefitToDelete}
              setBenefitToEdit={props.setBenefitToEdit}
            />
          </div>
        </>
      }

      {
        !isComplexPartner &&
        <ProductsHeader title={"Extra Configurations"} showAddButton={false} onAddButtonClick={props.openAddBenefitDialog} />
      }

      <div style={{ marginTop: '10%', marginBottom: '16%' }}>
        {
          !isComplexPartner &&
          <>
            <ViewGroupProductConfig
              partnerConfigGroup={props.groupProductConfig}
              isLoading={props.partnerConfigGroupLoader}
              openUpdateGroupProductConfigsDialog={() => { setOpenUpdateGroupProductConfigsDialog(true) }}
              openAddGroupProductConfigsDialog={() => { setOpenAddGroupProductConfigsDialog(true) }}
            />

            <UpdateGroupProductConfigs
              showUpdateGroupProductConfigsDialog={openUpdateGroupProductConfigsDialog}
              closeUpdateGroupProductConfigsDialog={() => { setOpenUpdateGroupProductConfigsDialog(false) }}
              partnerConfigGroup={props.groupProductConfig}
              showUpdateGroupProductConfigsProgressAlert={props.showUpdateGroupProductConfigsProgressAlert}
              showUpdateGroupProductConfigsSuccessAlert={props.showUpdateGroupProductConfigsSuccessAlert}
              showUpdateGroupProductConfigsErrorAlert={!!props.showUpdateGroupProductConfigsErrorAlert}
              updateGroupProductConfigsError={props.updateGroupProductConfigsError}
              updateGroupProductConfigs={props.updateGroupProductConfigs}

              resetUpdateGroupProductConfigsSuccessAlert={props.resetUpdateGroupProductConfigsSuccessAlert}
              resetUpdateGroupProductConfigsErrorAlert={props.resetUpdateGroupProductConfigsErrorAlert}
            />

            <AddGroupProductConfigs
              showAddGroupProductConfigsDialog={openAddGroupProductConfigsDialog}
              closeAddGroupProductConfigsDialog={() => { setOpenAddGroupProductConfigsDialog(false) }}
              partnerConfigGroup={props.groupProductConfig}
              showAddGroupProductConfigsProgressAlert={props.showAddGroupProductConfigsProgressAlert}
              showAddGroupProductConfigsSuccessAlert={props.showAddGroupProductConfigsSuccessAlert}
              showAddGroupProductConfigsErrorAlert={!!props.showAddGroupProductConfigsErrorAlert}
              addGroupProductConfigsError={props.addGroupProductConfigsError}
              addGroupProductConfigs={props.addGroupProductConfigs}

              resetAddGroupProductConfigsSuccessAlert={props.resetAddGroupProductConfigsSuccessAlert}
              resetAddGroupProductConfigsErrorAlert={props.resetAddGroupProductConfigsErrorAlert}
            />
          </>

        }




        <ViewExtendedFamilyProductConfig
          extendedFamilyProductConfigs={props.extendedFamilyProductConfigs}
          isLoading={props.partnerConfigExtendedFamilyLoader}
        >
          {
            getConfigs(props)
          }





        </ViewExtendedFamilyProductConfig>


        <IframeConfigs
            product={product}
          />





      </div>
    </div>
  );
}
