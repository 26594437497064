import React from 'react';
import ButtonInc from '../../../../../../shared-ui/ButtonInc';
import { COMPONENT_TYPES } from '../../../helpers';


export default function NavButtons({ currentComponent, setCurrentComponent }) {







  const handleNext = () => {
    if (currentComponent === COMPONENT_TYPES.SELECT_COVER_TYPE) {
      setCurrentComponent(COMPONENT_TYPES.SELECT_PLAN);
    }
    if (currentComponent === COMPONENT_TYPES.SELECT_PLAN) {
      setCurrentComponent(COMPONENT_TYPES.ADD_DEPENDANT);
      // if (hasParentsPlan === true) {
      //   setParentsPlan({ plan_cover_type: coverType, ...parentsPlan })
      // } else {
      //   setPlan({ plan_cover_type: coverType, ...selectedPlan })
      // }

    }
  };


  const handleBack = () => {
    if (currentComponent === COMPONENT_TYPES.SELECT_PLAN) {
      setCurrentComponent(COMPONENT_TYPES.SELECT_COVER_TYPE);
    }
    if (currentComponent === COMPONENT_TYPES.SELECT_RELATIONSHIP) {
      setCurrentComponent(COMPONENT_TYPES.SELECT_PLAN);
    }
  };

  const disableNextBtn = () => {
    // if (currentComponent === COMPONENT_TYPES.SELECT_COVER_TYPE && !coverType) return true
    // if (currentComponent === COMPONENT_TYPES.SELECT_PLAN && !selectedPlan) return true
    return false
  }


  return (
    <div style={{ minHeight: '15vh', position: 'relative' }}>

      <div style={{ position: 'absolute', bottom: '10px', right: '10px', display: 'flex' }}>
        {(currentComponent === COMPONENT_TYPES.SELECT_PLAN) &&
          <ButtonInc variant="contained" color="primary" onClick={handleBack} style={{ marginRight: '10px' }}>Back</ButtonInc>
        }
        {(currentComponent === COMPONENT_TYPES.SELECT_COVER_TYPE || currentComponent === COMPONENT_TYPES.SELECT_PLAN) &&
          <ButtonInc variant="contained" color="primary" onClick={handleNext} disabled={disableNextBtn()}>Next</ButtonInc>}
      </div>
    </div>
  );
}
