import React from 'react';
import { 
  Box, 
  Typography,
  Paper,
  Stack
} from '@mui/material';
import { makeStyles } from "@material-ui/styles";
import { QUOTE_COLORS } from '../constants';
import ButtonInc from '../../../../../../shared-ui/ButtonInc';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DescriptionIcon from '@mui/icons-material/Description';
import DownloadIcon from '@mui/icons-material/Download';
import { hashHistory } from 'react-router';

const useStyles = makeStyles(() => ({
  root: {
    padding: '1.5rem',
    maxWidth: '800px',
    margin: '0 auto'
  },
  successIcon: {
    color: '#4CAF50',
    fontSize: '64px',
    marginBottom: '1rem'
  },
  header: {
    textAlign: 'center',
    marginBottom: '2rem'
  },
  title: {
    color: QUOTE_COLORS.PRIMARY,
    fontWeight: 600,
    marginBottom: '0.5rem'
  },
  subtitle: {
    color: QUOTE_COLORS.TEXT.SECONDARY
  },
  paper: {
    padding: '2rem',
    marginBottom: '2rem'
  },
  sectionTitle: {
    color: QUOTE_COLORS.PRIMARY,
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    marginBottom: '1.5rem',
    '& svg': {
      marginRight: '0.5rem',
      color: QUOTE_COLORS.PRIMARY
    }
  },
  detailRow: {
    display: 'flex',
    padding: '0.75rem 1rem',
    backgroundColor: '#f8f9fa',
    borderRadius: '4px',
    marginBottom: '0.5rem',
    '&:hover': {
      backgroundColor: '#f0f1f2'
    }
  },
  label: {
    color: QUOTE_COLORS.TEXT.SECONDARY,
    fontWeight: 500,
    width: '200px',
    flexShrink: 0
  },
  value: {
    color: '#000',
    fontWeight: 400
  },
  divider: {
    margin: '2rem 0'
  },
  actions: {
    display: 'flex',
    justifyContent: 'center',
    gap: '1rem',
    marginTop: '2rem'
  },
  downloadButton: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    backgroundColor: '#f8f9fa',
    padding: '1rem',
    borderRadius: '4px',
    marginTop: '1rem',
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    '&:hover': {
      backgroundColor: '#f0f1f2'
    }
  }
}));

const DetailRow = ({ label, value }) => {
  const classes = useStyles();
  return (
    <Box className={classes.detailRow}>
      <Typography className={classes.label}>{label}</Typography>
      <Typography className={classes.value}>{value}</Typography>
    </Box>
  );
};

const PolicyCreated = ({ 
  policy,
  quote,
  policyHolder,
  email,
  coverAmount,
  monthlyPremium,
  onFinish 
}) => {
  const classes = useStyles();

  const handleDownload = () => {
    // TODO: Implement actual download logic
    console.log('Downloading policy document...');
  };

  const handleViewPolicy = () => {
    hashHistory.push(`admin/policy-processing/${policy.policy_number}`);
  };

  const formatCurrency = (amount) => {
    return `UGX ${amount.toLocaleString()}`;
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.header}>
        <CheckCircleIcon className={classes.successIcon} />
        <Typography variant="h5" className={classes.title}>
          Policy Created Successfully!
        </Typography>
        <Typography className={classes.subtitle}>
          Your policy details have been sent to your email
        </Typography>
      </Box>

      <Paper className={classes.paper}>
        <Stack spacing={3}>
          <Box>
            <Typography variant="h6" className={classes.sectionTitle}>
              <DescriptionIcon />
              Policy Summary
            </Typography>
            <DetailRow label="Policy Number" value={policy.policy_number} />
            <DetailRow label="Status" value={policy.status || 'Active'} />
            <DetailRow label="Yearly Premium" value={formatCurrency(quote.total_premium_amount_in_cents/100)} />
            <DetailRow label="Cover Amount" value={formatCurrency(coverAmount)} />
            <DetailRow label="Policy Holder" value={policyHolder} />
            <DetailRow label="Email" value={email} />
            
            <Box className={classes.downloadButton} onClick={handleDownload}>
              <DownloadIcon color="primary" />
              <Typography>Download Policy Document</Typography>
            </Box>
          </Box>
        </Stack>
      </Paper>

      <Box className={classes.actions}>
        <ButtonInc 
          onClick={handleViewPolicy}
          style={{ minWidth: '200px' }}
        >
          View Policy
        </ButtonInc>
      </Box>
    </Box>
  );
};

export default PolicyCreated; 