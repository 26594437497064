import * as React from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import MenuItem from '@mui/material/MenuItem';
import { TextValidator, SelectValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Dialog from '@mui/material/Dialog';
import { DialogContent } from "@material-ui/core";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { CircularProgress } from "@mui/material";
import AlertDialog from "../../../components/AlertDialog/index.es6";
import { PRIMARY_THEME_COLOR_SHADE_1 } from "../../../lib/constants";

const initialState = {
  name: '',
  code: '',
  country_code: '',
  call_centre_number: '',
  whats_app_number: '',
  default_language: '',
  ussd_shortcode: '',
  service_name: '',
  insurer: '',
}
export default function AddPartner(props) {
  const [
    {
      name,
      code,
      country_code,
      call_centre_number,
      whats_app_number,
      default_language,
      ussd_shortcode,
      service_name,
      insurer
    }, setState
  ] = React.useState(initialState)

  React.useEffect(() => {
    ValidatorForm.addValidationRule('isValidPartnerCode', (value) => {
      return !value.includes(" ")
    });

    return function cleanup() {
      ValidatorForm.removeValidationRule('isValidPartnerCode');
    }
  });


  const onChange = e => {
    const { name, value } = e.target;
    setState(prevState => ({ ...prevState, [name]: value }));
  };


  const reset = () => {
    setState(initialState);
  }


  const submit = () => {
    const payload = {
      name,
      code,
      country_code,
      call_centre_number,
      whats_app_number,
      default_language,
      ussd_shortcode,
      service_name,
      insurer,
      active: true,
    }
    props.addPartner(payload)
  }


  const handlePartnerCreatedSuccessfully = () => {
    props.resetAddPartnerSuccessAlert()
    reset()
    props.closeAddPartnerDialog()
    props.refreshPartners();
  }

  const handlePartnerCreateError = () => {
    props.resetAddPartnerErrorAlert()
  }

  const countries = props.countries || []
  // const languages = props.languages || []

  return (
    <React.Fragment>


      <Dialog
        open={props.openAddPartnerDialog} fullWidth
        maxWidth='lg'
        onClose={props.closeAddPartnerDialog}
        fullScreen
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar style={{ background: PRIMARY_THEME_COLOR_SHADE_1 }}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={props.closeAddPartnerDialog}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Add Partner
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <div style={{ marginTop: "2%" }}>
            <Box sx={{ width: "100%" }}>
              <ValidatorForm onSubmit={() => submit()} >
                <AlertDialog
                  custom
                  show={props.showAddPartnerProgressAlert}
                  size="sm"
                  style={{ marginTop: '0', top: '30vh' }}
                  confirmBtnText={'ok'}
                  showCancel={false}
                  showConfirm={false}
                  cancelBtnText={'cancel'}
                  showTitle={false}
                  confirmBtnCssClass
                >
                  <CircularProgress />
                  <h2>Adding partner</h2>
                </AlertDialog>

                <AlertDialog
                  success
                  show={props.showAddPartnerSuccessAlert}
                  size="sm"
                  title={'Partner added'}
                  style={{ marginTop: '0', top: '30vh' }}
                  onConfirm={() => { handlePartnerCreatedSuccessfully() }}
                  confirmBtnText={'ok'}
                  showCancel={false}
                  showConfirm={false}
                  cancelBtnText={'cancel'}
                  confirmBtnCssClass
                >
                  <div >
                    <Button variant="contained" onClick={() => { handlePartnerCreatedSuccessfully() }} style={{ background: 'green', }}>Ok</Button>
                  </div>
                </AlertDialog>

                <AlertDialog
                  show={props.showAddPartnerErrorAlert}
                  danger
                  title={'Error adding partner'}
                  onConfirm={() => handlePartnerCreateError()}
                  confirmBtnText={'Try again'}
                  confirmBtnCssClass
                  showCancel={false}
                  style={{ marginTop: '0', top: '30vh' }}
                >
                  {props.createPartnerError ? props.createPartnerError.message : ''}
                </AlertDialog>

                <Grid container spacing={2}>

                  <Grid item lg={3} md={6} xs={12}><TextValidator
                    onChange={onChange}
                    label={'partner name'}
                    value={name}
                    name='name'
                    fullWidth
                    variant="outlined"
                    type="text"
                    placeholder='name'
                    validators={['required']}
                    errorMessages={['Please enter partner name']}
                  />
                  </Grid>

                  <Grid item lg={3} md={6} xs={12}><TextValidator
                    onChange={onChange}
                    label='partner code'
                    name='code'
                    value={code}
                    // value={code || replaceSpacesWithUnderscores(name)}
                    fullWidth
                    variant="outlined"
                    type="text"
                    placeholder='code'
                    validators={['required', 'isValidPartnerCode']}
                    errorMessages={['Please enter a partner code', 'should not contain spaces']}
                  /> </Grid>

                  <Grid item lg={3} md={6} xs={12}> <SelectValidator label='Country Code'
                    id="country_code"
                    value={country_code}
                    name='country_code'
                    onChange={onChange}
                    className="form-control"
                    type="text"
                    variant="outlined"
                    validators={['required']}
                    errorMessages={['Please select country']}
                  >
                    <MenuItem value="">{'Please select country'}</MenuItem>
                    {
                      countries.map((country) => {
                        return <MenuItem value={country.code}>{country.name}</MenuItem>
                      })
                    }
                  </SelectValidator> </Grid>
                  <Grid item lg={3} md={6} xs={12}> <SelectValidator
                    value={default_language}
                    label='default language'
                    id="default language"
                    name='default_language'
                    onChange={onChange}
                    className="form-control"
                    fullWidth
                    type="text"
                    variant="outlined"
                    validators={['required']}
                    errorMessages={['Please select default language']}
                  >
                    <MenuItem value="">{'Please select default language'}</MenuItem>
                    {
                      [{code: 'en', name: 'english'}].map((language) => {
                        return <MenuItem value={language.code}>{language.name}</MenuItem>
                      })
                    }
                  </SelectValidator> </Grid>


                  <Grid item lg={3} md={6} xs={12}><TextValidator
                    onChange={onChange}
                    value={call_centre_number}
                    label='call centre number'
                    name='call_centre_number'
                    fullWidth
                    variant="outlined"
                    type="number"
                    placeholder='amount'
                    validators={['required']}
                    errorMessages={['Please enter call centre number']}
                  />
                  </Grid>


                  <Grid item lg={3} md={6} xs={12}><TextValidator
                    onChange={onChange}
                    value={whats_app_number}
                    label='whatsapp number'
                    name='whats_app_number'
                    fullWidth
                    variant="outlined"
                    type="number"
                    placeholder='amount'
                    validators={['required']}
                    errorMessages={['Please enter whatsapp number']}
                  />
                  </Grid>

                  <Grid item lg={3} md={6} xs={12}><TextValidator
                    onChange={onChange}
                    value={ussd_shortcode}
                    label='ussd shortcode'
                    name='ussd_shortcode'
                    fullWidth
                    variant="outlined"
                    type="text"
                    placeholder='amount'
                    validators={['required']}
                    errorMessages={['Please enter ussd shortcode']}
                  />
                  </Grid>

                  <Grid item lg={3} md={6} xs={12}><TextValidator
                    onChange={onChange}
                    value={service_name}
                    label='service name'
                    name='service_name'
                    fullWidth
                    variant="outlined"
                    type="text"
                    placeholder='amount'
                    validators={['required']}
                    errorMessages={['Please enter service name']}
                  />
                  </Grid>



                  <Grid item lg={3} md={6} xs={12}><TextValidator
                    onChange={onChange}
                    value={insurer}
                    label='insurer'
                    name='insurer'
                    fullWidth
                    variant="outlined"
                    type="text"
                    placeholder='insurer'
                    validators={['required']}
                    errorMessages={['Please enter insurer']}
                  /></Grid>



                  <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Button variant="contained" type="submit" style={{ marginRight: '2%' }}> Save </Button>
                    <Button variant="contained" color="secondary" style={{ marginRight: '2%' }}
                      onClick={() => reset()}
                    > Reset</Button>
                  </Grid>

                </Grid>
              </ValidatorForm>

            </Box>
          </div>

        </DialogContent>
      </Dialog>

    </React.Fragment>

  );
}
