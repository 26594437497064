export const COMPONENT_TYPES = {
  SELECT_PRODUCT_TYPE: 'SELECT_PRODUCT_TYPE',
  SELECT_COVER_TYPE: 'SELECT_COVER_TYPE',
  SELECT_PLAN: 'SELECT_PLAN',
  SELECT_PAYMENT_PLAN: 'SELECT_PAYMENT_PLAN',
  ADD_MEMBERS: 'ADD_MEMBERS',
  ADD_DEPENDANT: 'ADD_DEPENDANT',
  VIEW_MEMBERS: 'VIEW_MEMBERS',
  VEHICLE_DETAILS: 'VEHICLE_DETAILS',
  DECLARATIONS: 'DECLARATIONS',
  ADD_ADDITIONAL_BENEFITS: 'ADD_ADDITIONAL_BENEFITS',
};
