import React, { useState } from 'react';
import { 
  Box, 
  Typography, 
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  useTheme,
  useMediaQuery
} from '@mui/material';
import { makeStyles } from "@material-ui/styles";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { QUOTE_COLORS } from '../constants';
import ButtonInc from '../../../../../../shared-ui/ButtonInc';
import MemberForm from '../Components/MemberForm';
import { productData } from '../../../helpers/product';
import MemberTypeSelection from '../Components/MemberTypeSelection';

const useStyles = makeStyles(() => ({
  root: {
    padding: '1rem',
    '@media (min-width: 600px)': {
      padding: '2rem',
    }
  },
  pageTitle: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '24px',
    lineHeight: '32px',
    letterSpacing: '0.0015em',
    color: QUOTE_COLORS.PRIMARY,
    marginBottom: '1rem',
  },
  pageSubtitle: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    color: QUOTE_COLORS.TEXT.SECONDARY,
    marginBottom: '2rem',
  },
  memberTypeLabel: {
    color: QUOTE_COLORS.TEXT.SECONDARY,
    fontSize: '14px',
    marginBottom: '0.5rem',
  },
  memberName: {
    color: QUOTE_COLORS.PRIMARY,
    fontWeight: 500,
  },
  addMemberButton: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    marginTop: '1rem',
  },
  buttonContainer: {
    display: 'flex',
    gap: '1rem',
    marginTop: '2rem',
    justifyContent: 'center',
  },
  membersList: {
    marginTop: '2rem',
  },
  memberCount: {
    color: QUOTE_COLORS.TEXT.SECONDARY,
    fontSize: '12px',
    fontStyle: 'italic',
  },
  dialog: {
    '& .MuiDialog-paper': {
      width: '100%',
      maxWidth: 800,
    },
  },
  dialogTitle: {
    backgroundColor: QUOTE_COLORS.PRIMARY,
    color: QUOTE_COLORS.TEXT.LIGHT,
    padding: '1rem 1.5rem',
  },
  benefitAmount: {
    color: QUOTE_COLORS.SECONDARY,
    fontSize: '12px',
    marginTop: '4px'
  }
}));

const AddMembers = ({ quoteData, updateQuoteData, onNext, onBack, customer  }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  // Initialize members from quoteData
  const [members, setMembers] = useState(() => {
    const existingMembers = [];
    
    // Add main member
    if (quoteData.members.mainMember) {
      existingMembers.push(quoteData.members.mainMember);
    } else {
      existingMembers.push({
        id: 1,
        type: 'main_member',
        isRequired: true
      });
    }

    // Add spouse if exists
    if (quoteData.members.spouse) {
      existingMembers.push(quoteData.members.spouse);
    }

    // Add children if any
    if (quoteData.members.children && quoteData.members.children.length > 0) {
      existingMembers.push(...quoteData.members.children);
    }

    // Add parents if any
    if (quoteData.members.parents && quoteData.members.parents.length > 0) {
      existingMembers.push(...quoteData.members.parents);
    }

    return existingMembers;
  });
  
  // Only show form if there's no main member with details
  const [showForm, setShowForm] = useState(() => {
    const mainMember = quoteData.members.mainMember;
    return !mainMember || !mainMember.first_name;
  });

  const [editingMember, setEditingMember] = useState(null);
  const [selectedMemberType, setSelectedMemberType] = useState('main_member');
  const [showMemberTypeSelection, setShowMemberTypeSelection] = useState(false);

  const memberLimits = {
    spouse: 1,
    children: 4,
    parents: 4
  };
  console.log(customer, 'customer')

  const updateMembersInQuoteData = (updatedMembers) => {
    // Update the members array
    setMembers(updatedMembers);

    // Structure the members for quoteData
    const membersStructure = {
      mainMember: updatedMembers.find(m => m.type === 'main_member') || null,
      spouse: updatedMembers.find(m => m.type === 'spouse') || null,
      children: updatedMembers.filter(m => m.type === 'children'),
      parents: updatedMembers.filter(m => m.type === 'parents')
    };

    // Update quoteData
    updateQuoteData('members', membersStructure);
  };

  const getCurrentMemberCounts = () => {
    return members.reduce((acc, member) => {
      acc[member.type] = (acc[member.type] || 0) + 1;
      return acc;
    }, {});
  };

  const handleEditMember = (member) => {
    setEditingMember(member);
    setSelectedMemberType(member.type);
    setShowForm(true);
  };

  const handleDeleteMember = (memberId) => {
    const updatedMembers = members.filter(member => member.id !== memberId);
    updateMembersInQuoteData(updatedMembers);
  };

  // const handleAddMember = () => {
  //   setEditingMember(null);
  //   setShowForm(true);
  // };

  const getBenefitAmount = (memberType) => {
    const selectedCover = quoteData.selectedCover;
    if (!selectedCover) return 0;

    // Find the benefit for this member type
    const benefit = productData.membership_configs.content.benefits.find(
      b => b.member_type === memberType
    );

    if (!benefit) return 0;

    // Get package number from package_id
    const packageMap = {
      'il55zx2xc': '1',  // Package 1
      'xmar5a32k': '2',  // Package 2
      '5xylg7q8s': '3'   // Package 3
    };

    const packageNumber = packageMap[selectedCover.package_id];
    if (!packageNumber) return 0;

    // Get the amount using the correct package number
    return benefit[`package_${packageNumber}_amount`] || 0;
  };

  const handleFormSubmit = (formData) => {
    let updatedMembers;

    // Add benefit amount to member data
    const memberWithBenefit = {
      ...formData,
      benefit_amount: getBenefitAmount(selectedMemberType)
    };

    if (editingMember) {
      // Update existing member
      updatedMembers = members.map(member => 
        member.id === editingMember.id 
          ? { ...member, ...memberWithBenefit }
          : member
      );
    } else if (selectedMemberType === 'main_member' && members[0].type === 'main_member' && !members[0].first_name) {
      // Update the initial main member instead of adding a new one
      updatedMembers = members.map((member, index) => 
        index === 0 ? { ...member, ...memberWithBenefit } : member
      );
    } else {
      // Add new member
      const newMember = {
        id: Date.now(),
        type: selectedMemberType,
        ...memberWithBenefit
      };
      updatedMembers = [...members, newMember];
    }
    
    updateMembersInQuoteData(updatedMembers);
    setShowForm(false);
    setEditingMember(null);
  };

  const handleFormCancel = () => {
    // If canceling main member form and no main member exists with details
    if (selectedMemberType === 'main_member' && 
        (!members[0] || !members[0].first_name)) {
      // Keep the form open
      return;
    }
    
    setShowForm(false);
    setEditingMember(null);
  };

  const getMemberTypeLabel = (type) => {
    switch(type) {
      case 'main_member': return 'Main Member';
      case 'spouse': return 'Spouse';
      case 'children': return 'Child';
      case 'parents': return 'Parent';
      default: return type;
    }
  };


  const handleAddAnotherMember = () => {
    setShowMemberTypeSelection(true);
  };

  const handleMemberTypeSelect = (memberType) => {
    setSelectedMemberType(memberType);
    setShowMemberTypeSelection(false);
    setShowForm(true);
  };

  return (
    <Box className={classes.root}>
      <Typography className={classes.pageTitle} align="center">
        Add Family Members
      </Typography>
      <Typography className={classes.pageSubtitle} align="center">
        Start with the main member's details, then add your family members
      </Typography>

      <List className={classes.membersList}>
        {members.map((member, index) => (
          member.first_name ? (
            <React.Fragment key={member.id}>
              {index > 0 && <Divider />}
              <ListItem>
                <ListItemText
                  primary={
                    <Typography className={classes.memberName}>
                      {`${member.first_name} ${member.last_name}`}
                    </Typography>
                  }
                  secondary={
                    <>
                      <Typography className={classes.memberTypeLabel}>
                        {getMemberTypeLabel(member.type)}
                      </Typography>
                      {member.benefit_amount > 0 && (
                        <Typography className={classes.benefitAmount}>
                          Cover Amount: UGX {member.benefit_amount.toLocaleString()}
                        </Typography>
                      )}
                    </>
                  }
                />
                <ListItemSecondaryAction>
                  <IconButton 
                    edge="end" 
                    onClick={() => handleEditMember(member)}
                    sx={{ marginRight: 1 }}
                  >
                    <EditIcon />
                  </IconButton>
                  {!member.isRequired && (
                    <IconButton 
                      edge="end" 
                      onClick={() => handleDeleteMember(member.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                </ListItemSecondaryAction>
              </ListItem>
            </React.Fragment>
          ) : null
        ))}
      </List>

      {!showForm && !showMemberTypeSelection && (
        <>
          {/* Show Add Main Member button if no main member exists */}
          {(!members[0] || !members[0].first_name) && (
            <ButtonInc
              btnType="primary"
              onClick={() => {
                setSelectedMemberType('main_member');
                setShowForm(true);
              }}
              className={classes.addMemberButton}
              startIcon={<PersonAddIcon />}
            >
              Add Main Member
            </ButtonInc>
          )}
          
          {/* Only show Add Another Member if main member exists */}
          {members[0] && members[0].first_name && (
            <ButtonInc
              btnType="secondary"
              onClick={handleAddAnotherMember}
              className={classes.addMemberButton}
              startIcon={<PersonAddIcon />}
            >
              Add Another Member
            </ButtonInc>
          )}
        </>
      )}

      <Dialog 
        open={showMemberTypeSelection} 
        onClose={() => setShowMemberTypeSelection(false)}
        className={classes.dialog}
        maxWidth="md"
        fullWidth
      >
        <DialogContent>
          <MemberTypeSelection
            onSelect={handleMemberTypeSelect}
            memberCounts={getCurrentMemberCounts()}
            memberLimits={memberLimits}
          />
        </DialogContent>
      </Dialog>

      <Dialog 
        open={showForm} 
        onClose={handleFormCancel}
        className={classes.dialog}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle className={classes.dialogTitle}>
          {editingMember ? 'Edit Member' : `Add ${getMemberTypeLabel(selectedMemberType)}`}
        </DialogTitle>
        <DialogContent>
          <MemberForm
            memberType={selectedMemberType}
            validationSchema={productData.membership_configs.content.member_validations[selectedMemberType]}
            initialValues={editingMember || {}}
            onSubmit={handleFormSubmit}
            onCancel={handleFormCancel}
            customer={customer}
          />
        </DialogContent>
      </Dialog>

      <Box className={classes.buttonContainer}>
        <ButtonInc
          onClick={onNext}
          disabled={!members.length || members.some(m => !m.first_name)}
          style={{ width: isMobile ? '100%' : 'auto' }}
        >
          Continue to Quote Details
        </ButtonInc>
      </Box>
    </Box>
  );
};

export default AddMembers; 